import React, { FunctionComponent } from 'react';
import classnames from "classnames";
import styles from './BankingTemplate.module.css'
import s from "../../styles/styles.module.css"
import { motion } from 'framer-motion';

type BankingTemplateProps = {
    title: string,
    backColor: string,
    textColor: string,
    text: string,
    image: string,
    subTitle?: string,
    servicesInfo: {
        icon: string,
        description: string,
    }[],
}

const BankingTemplate : FunctionComponent<BankingTemplateProps> = 
    ({ 
        title,
        backColor,
        textColor,
        text,
        image,
        subTitle,
        servicesInfo,
    }) => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
        <div className={classnames(s.wrapper, s.main_block)}>
            <div className={s.container}>
                <div className={styles.banking_wrapper}>
                    <div className={styles.banking_content}>
                        <div className={styles.main_info_block}>
                            <div className={styles.main_info_body}>
                                <div className={classnames(styles.main_info_title, s.title)}>
                                    <div className={classnames(styles.info_main_title, s.main_title)}>
                                        {title}
                                    </div>
                                    <div className={classnames(styles.info_back_title, s.back_title)}>
                                        {title}
                                    </div>
                                </div>
                                <div className={styles.main_info_content}>
                                    <div className={styles.main_info_text} style={{backgroundColor: backColor, color: textColor}}>
                                        <div className={styles.main_info_text_body}>
                                            <span>{text}</span>
                                        </div>
                                    </div>
                                    <div className={styles.main_info_img}>
                                        <div className={styles.main_info_gradient} 
                                            style={{ 
                                                background: 'linear-gradient(0deg, rgba(201, 177, 129, 0.25) 0%, rgba(201, 177, 129, 0.25) 100%), url(' + image + '), lightgray',
                                                backgroundPosition: '0px -21.346px',
                                                backgroundSize: '100% 110.423%',
                                                backgroundRepeat: 'no-repeat'
                                            }} >
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.services_info_block}>
                            <div className={classnames(styles.main_info_title, styles.contact_form_title)}>
                                <div className={classnames(styles.info_main_title, s.main_title)}>
                                    key services
                                </div>
                                <div className={classnames(styles.info_back_title, s.back_title)}>
                                    key services
                                </div>
                            </div>
                            <div className={styles.info_back_subtitle}>
                                {subTitle}
                            </div>
                            <div className={styles.info_services}>
                                <div className={styles.services_info_item_body}>
                                    {servicesInfo.map(item => {
                                        return <div className={styles.services_info_item}>
                                                    <div className={styles.services_info_icon}>
                                                        <img src={item.icon} alt='icon'/>
                                                    </div>
                                                    <div className={styles.services_info_desc}>
                                                        {item.description}
                                                    </div>
                                               </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </motion.div>
    );
};

export default BankingTemplate;
import React, {Dispatch, SetStateAction, useState} from 'react';
import s from './KycBusinessStepTwo.module.css'
import {Field, Formik} from "formik";
import MyInput from "../../../../../../components/ui/MyInput/MyInput";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import PhoneCodesInput from "../../../../../../components/ui/PhoneCodesInput/PhoneCodesInput";
import * as yup from "yup";
import CustomDropdown from "../../../../../../components/ui/CustomDropdown/CustomDropdown";
import legal_account_application from "../../../../../../assets/newImg/personalAccount/legal_account_application.png"
import {ReactComponent as Plus} from "../../../../../../assets/newImg/personalAccount/plus.svg"
import {ReactComponent as Back}  from "../../../../../../assets/newImg/personalAccount/back.svg"
import proofOfAddress from "../../../../../../assets/newImg/personalAccount/proof_of_address.png"
import companyCharter from "../../../../../../assets/newImg/personalAccount/company_charter.png"
import registrationSertificate from "../../../../../../assets/newImg/personalAccount/registration_sertificate.png"
import beneficiaryIdDoc from "../../../../../../assets/newImg/personalAccount/beneficiary_identity.png"
import beneficiaryIdcp from "../../../../../../assets/newImg/personalAccount/beneficiary_idcp.png"
import Poa from "../../../../../../assets/newImg/personalAccount/poa.png"
import license from "../../../../../../assets/newImg/personalAccount/license.png"
import BeneficiaryProofOfAddress from "../../../../../../assets/newImg/personalAccount/proof_of_address.png"
import attachedDoc from "../../../../../../assets/newImg/personalAccount/attached_document.svg"
import delete_document from "../../../../../../assets/newImg/personalAccount/delete_document.svg"
import download_legal from "../../../../../../assets/newImg/personalAccount/download_legal_application.svg"
import classnames from "classnames";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/redux";
import {motion} from 'framer-motion';
import {sendPersonalKycThunk, setTokenState} from "../../../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../../../components/ui/CustomModal/CustomModal";
import {setDisabled} from "../../../../../../store/reducers/UserSlice";
import Icon from "@ant-design/icons";
import TransparentButton from "../../../../../../components/ui/TransparentButton/TransparentButton";
import {countries, phonecodes} from "../../../../../../Constants/StaticData";
import {Checkbox} from "antd";
import {IPhoneNumber} from "../../../../../../types/interfaces";
import SelectWithSearch from "../../../../../../components/ui/SelectWithSearch/SelectWithSearch";

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const validationSchema = yup.object({
    phoneNumber: yup.string()
        .required('Required field'),
    companyName: yup.string().required('Required field').max(90, 'max 90'),
    country: yup.string().required('Required field'),
    currencies: yup.array().min(1,"At least one account is required"),
})

const KycPersonalStepTwo = (props: PropsType) => {

    const token: string = useAppSelector(state => state.userReducer.user.userToken)
    const dispatch = useAppDispatch()
    const [code, setCode] = useState({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    })
    const [documents, setDocuments] = useState<any>(
        {
            accountApplication: '',
            proofOfAddress: '',
            articlesMemorandums: '',
            registrationCertificate: '',
            beneficiaryIdDocument: '',
            beneficiaryIdcp: '',
            beneficiaryProofOfAddress: '',
            poA: '',
            license: '',
        }
    )
    const [docsError, setDocsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again.");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const initPhoneNumbers = useAppSelector(state => state.userReducer.user.phoneList)

    function toBase64(file: any) {

        if (!file) {
            return
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const closeAndRedirect = () => {
        setIsModalOpen(false)
        props.setCurrent(0)
    }

    const getPhoneNumbersArr = () => {
        const phonesArr: string[] | null = []
        initPhoneNumbers?.map((item : IPhoneNumber) => {
            if (item.phoneNumber != null) {
                phonesArr.push(item.phoneNumber)
            }
        })
        return phonesArr
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>

                <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                    <div className={s.flex_modal}>
                        <div className={s.modal_title}>
                            Success!
                        </div>
                        <div className={s.modal_subtitle}>
                            Your details will be sent for review.
                        </div>
                        <div className={s.button_block}>
                            <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal type="error" isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen}>
                    <div className={s.flex_column}>
                        <div className={s.modal_title}>
                            Oops!
                        </div>
                        <div className={s.modal_subtitle}>
                            {errorMessage}
                        </div>
                        <div className={s.button_block}>
                            <MyBtn buttonType="error" title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                        </div>
                    </div>
                </CustomModal>

                <div className={s.title}>
                    KYC for Business Accounts
                </div>

                <Formik
                    initialValues={{
                        companyName: '',
                        country: '',
                        phoneNumber: '',
                        // currencies: [],
                        files: {
                            accountApplication: '',
                            articlesMemorandums: '',
                            proofOfAddress: '',
                            registrationCertificate: '',
                            beneficiaryIdDocument: '',
                            beneficiaryIdcp: '',
                            beneficiaryProofOfAddress: '',
                        }
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {

                        const tempData = {
                            ...values,
                            phoneNumber: `${code.ph} ${values.phoneNumber}`,
                            files: {
                                accountApplication: '',
                                articlesMemorandums: '',
                                proofOfAddress: '',
                                registrationCertificate: '',
                                beneficiaryIdDocument: '',
                                beneficiaryIdcp: '',
                                beneficiaryProofOfAddress: '',
                            }
                        }

                        if (documents.registrationCertificate === '' ||
                            documents.proofOfAddress === '' ||
                            documents.beneficiaryIdcp === '' ||
                            documents.articlesMemorandums === '' ||
                            documents.beneficiaryProofOfAddress === '' ||
                            documents.beneficiaryIdDocument === '' ||
                            documents.accountApplication === ''
                        ) {
                            setDocsError(true)
                            return
                        }

                        tempData.files.accountApplication = await toBase64(documents.accountApplication) as string
                        tempData.files.articlesMemorandums = await toBase64(documents.articlesMemorandums) as string
                        tempData.files.proofOfAddress = await toBase64(documents.proofOfAddress) as string
                        tempData.files.registrationCertificate = await toBase64(documents.registrationCertificate) as string
                        tempData.files.beneficiaryIdDocument = await toBase64(documents.beneficiaryIdDocument) as string
                        tempData.files.beneficiaryIdcp = await toBase64(documents.beneficiaryIdcp) as string
                        tempData.files.beneficiaryProofOfAddress = await toBase64(documents.beneficiaryProofOfAddress) as string

                        if (documents.poA !== '') {
                            // @ts-ignore
                            tempData.files.poA = await toBase64(documents.poA) as string
                        }

                        if (documents.license !== '') {
                            // @ts-ignore
                            tempData.files.license = await toBase64(documents.license) as string
                        }

                        console.log("here")

                        dispatch(sendPersonalKycThunk(token, tempData))
                            .then((res) => {
                                if (res.data === "Created") {
                                    setIsModalOpen(true)
                                } else {
                                    setIsErrorModalOpen(true)
                                }
                            })
                            .catch((error) => {
                                if (error.code === "ERR_NETWORK" && !error.response) {
                                    setErrorMessage("Maximum files size is 17 MB")
                                    setIsErrorModalOpen(true)
                                }

                                if (error.response.data === "Invalid authorization data") {
                                    dispatch(setTokenState(false))
                                    return
                                }
                                if (error.response.data === "Too many phones. Max count is 5") {
                                    setErrorMessage(error.response.data)
                                    setIsErrorModalOpen(true)
                                    return
                                }
                                if (error.response.data === "Phone already exist") {
                                    setErrorMessage("This phone number already exists. Please try another phone number.")
                                    setIsErrorModalOpen(true)
                                    return
                                }
                            })
                            .finally(() => dispatch(setDisabled(false)))
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (

                        <form onSubmit={handleSubmit}>
                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label} data-first-char="*">
                                        Company name
                                    </div>
                                    <MyInput id='companyName'
                                             isError={errors.companyName}
                                             name='companyName'
                                             onChange={handleChange}
                                             value={values.companyName}
                                             touched={touched.companyName}
                                    />
                                    {errors.companyName &&
                                        <div className={s.error_message}>{errors.companyName}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label} data-first-char="*">
                                        Country
                                    </div>
                                    <SelectWithSearch
                                        id="country"
                                        name="country"
                                        isError={errors.country}
                                        items={countries}
                                    />
                                    {errors.country &&
                                        <div className={s.error_message}>{errors.country}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label} data-first-char="*">
                                        Phone number
                                    </div>
                                    <CustomDropdown
                                        items={getPhoneNumbersArr()}
                                        placeholder={"Choose your phone number"}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        isError={errors.phoneNumber}
                                        touched={touched.phoneNumber}
                                    />
                                    {errors.phoneNumber &&
                                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                                </div>
                            </div>

                            {/* <div className={s.row}>
                                <div className={classnames(s.input_block)}>
                                    <div className={classnames(s.input_label, errors.currencies && s.doc_error)} id="checkbox-group">
                                        * Add a new account
                                    </div>
                                    <div role="group" aria-labelledby="checkbox-group" className={s.checkbox_wrapper}>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="CAD" />
                                            <span className={s.checkbox_label}>CAD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="EUR"/>
                                            <span className={s.checkbox_label}>EUR</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="GBP"/>
                                            <span className={s.checkbox_label}>GBP</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="USD"/>
                                            <span className={s.checkbox_label}>USD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="USDT"/>
                                            <span className={s.checkbox_label}>USDT</span>
                                        </label>
                                    </div>
                                    {errors.currencies &&
                                        <div className={s.error_message}>{errors.currencies}</div>}
                                </div>
                            </div> */}


                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={legal_account_application} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.accountApplication === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Legal Account Application
                                        </div>
                                        <div>
                                            <ul className={s.passport_spread_list}>
                                                <li>
                                                    <span className={classnames(s.flex, s.text_color)}>
                                                       <img src={download_legal} alt=""/><a
                                                        href="/Legal_Account_Application_form.pdf" target="_blank"
                                                        className={s.download_link}>Download legal account application</a>
                                                    </span>
                                                </li>

                                                <li>
                                                    <span className={s.text_color}>
                                                         Fill in relevant information.
                                                    </span>
                                                </li>

                                                <li>
                                                    <span className={s.text_color}>
                                                        Upload filled and signed legal account application form.
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            personalAccount={true}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('accountApplication').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"accountApplication"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                        // @ts-ignore
                                                       accountApplication: event.currentTarget.files[0]
                                                   });
                                               }}
                                        />

                                    </div>
                                    <div className={classnames(s.attached_file)}>
                                        {
                                            documents?.accountApplication?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.accountApplication?.name}
                                        {
                                            documents?.accountApplication?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("accountApplication").value = ""
                                                setDocuments({...documents, accountApplication: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={proofOfAddress} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.proofOfAddress === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Company Proof of Address
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Acceptable documents are:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            Bank statement with the date of issue and the name of the person (the document
                                            must be not older than 3 months);
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Utility bill for gas, electricity, water, internet, etc. linked to the property
                                            (the document must not be older than 3 months).
                                        </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            personalAccount={true}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('proofOfAddress').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"proofOfAddress"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       proofOfAddress: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.proofOfAddress?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.proofOfAddress?.name}
                                        {
                                            documents?.proofOfAddress?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("proofOfAddress").value = ""
                                                setDocuments({...documents, proofOfAddress: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={companyCharter} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.articlesMemorandums === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Company Charter
                                        </div>
                                        <div className={s.add_block_text}>
                                            Upload company Charter/Statute/Memorandum/Articles of Association/Memorandum of Association 
                                            or any other relevant statutory documents.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            personalAccount={true}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('articlesMemorandums').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"articlesMemorandums"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       articlesMemorandums: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.articlesMemorandums?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.articlesMemorandums?.name}
                                        {
                                            documents?.articlesMemorandums?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("articlesMemorandums").value = ""
                                                setDocuments({...documents, articlesMemorandums: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={registrationSertificate} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.registrationCertificate === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Registration Certificate
                                        </div>
                                        <div className={s.add_block_text}>
                                            Upload Certificate of Incorporation or Certificate of enterprise
                                            registration.
                                        </div>
                                        {/*<div className={s.add_block_text}>*/}
                                        {/*    You can paste a link to government website with company information.*/}
                                        {/*</div>*/}
                                        {/*<div className={s.input_block}>*/}
                                        {/*    <div className={s.input_label}>*/}
                                        {/*        Link*/}
                                        {/*    </div>*/}
                                        {/*    <MyInput/>*/}
                                        {/*</div>*/}

                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            personalAccount={true}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('registrationCertificate').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"registrationCertificate"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       registrationCertificate: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.registrationCertificate?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.registrationCertificate?.name}
                                        {
                                            documents?.registrationCertificate?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("registrationCertificate").value = ""
                                                setDocuments({...documents, registrationCertificate: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={beneficiaryIdDoc} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryIdDocument === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary Identity document (Passport, ID Card, Driving license)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Good photo tips:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            A color photo contains full document in clear focus and the data can be read easily.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             There're no light glares impeding document readability.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             A photo was not edited in graphic applications (we will definitely check).
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                            Picture format: JPEG, JPG, PNG, GIF, HEIC, PDF. 
                                        </span>
                                            </li>
                                            {/* <li>
                                        <span className={s.text_color}>
                                             Maximum file size: 8Mb.
                                        </span>
                                            </li> */}
                                            <div className={s.attention}>
                                                Please upload Identity document (Passport, ID Card, Driving license)
                                                photos
                                                of each
                                                Shareholder with more than 25% ownership, all Board Members and
                                                Directors.
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            personalAccount={true}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('beneficiaryIdDocument').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf, .png, .jpg, .jpeg, .pdf, .heic"
                                               id={"beneficiaryIdDocument"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       beneficiaryIdDocument: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.beneficiaryIdDocument?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.beneficiaryIdDocument?.name}
                                        {
                                            documents?.beneficiaryIdDocument?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("beneficiaryIdDocument").value = ""
                                                setDocuments({...documents, beneficiaryIdDocument: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={beneficiaryIdcp} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryIdcp === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary IDCP
                                        </div>
                                        <div className={s.add_block_text} style={{marginBottom: '16px'}}>
                                            Upload an ID confirmation photo (selfie of the Beneficiary holding in his
                                            hands
                                            his ID
                                            and a paper with written name of platform "Mondial", date (dd/mm/yyyy/)
                                            and
                                            Beneficiary's
                                            signature)
                                        </div>
                                        <div className={s.attention}>
                                            Please upload Identity document (Passport, ID Card, Driving license) photos
                                            of
                                            each
                                            Shareholder with more than 25% ownership, all Board Members and Directors.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            personalAccount={true}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('beneficiaryIdcp').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept=".png, .jpg, .jpeg, .pdf, .heic, application/pdf"
                                               id={"beneficiaryIdcp"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       beneficiaryIdcp: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.beneficiaryIdcp?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.beneficiaryIdcp?.name}
                                        {
                                            documents?.beneficiaryIdcp?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("beneficiaryIdcp").value = ""
                                                setDocuments({...documents, beneficiaryIdcp: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={BeneficiaryProofOfAddress} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryProofOfAddress === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary Proof of Address
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Acceptable documents are:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            Bank statement with the date of issue and the name of the person (the document
                                            must be not older than 3 months);
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Utility bill for gas, electricity, water, internet, etc. linked to the
                                            property (the document must not be older than 3 months).
                                        </span>
                                            </li>
                                        </ul>
                                        {/*<div className={s.attention}>*/}
                                        {/*    Please upload Identity document (Passport, ID Card, Driving license) photos*/}
                                        {/*    of*/}
                                        {/*    each*/}
                                        {/*    Shareholder with more than 25% ownership, all Board Members and Directors.*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            personalAccount={true}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('beneficiaryProofOfAddress').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"beneficiaryProofOfAddress"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       beneficiaryProofOfAddress: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.beneficiaryProofOfAddress?.name &&
                                            <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.beneficiaryProofOfAddress?.name}
                                        {
                                            documents?.beneficiaryProofOfAddress?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("beneficiaryProofOfAddress").value = ""
                                                setDocuments({...documents, beneficiaryProofOfAddress: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={Poa} alt=""/>
                                    </div>
                                    <div>
                                        <div className={s.add_doc_title}>
                                            PoA (If applicable)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            If the company is represented by a person not authorized to do so by the
                                            company’s
                                            Articles of Association or equivalent document, please upload a Power of
                                            Attorney
                                            asserting the right of this person to represent company's interests.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            personalAccount={true}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('poA').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"poA"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({...documents, poA: event.currentTarget.files[0]})
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.poA?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.poA?.name}
                                        {
                                            documents?.poA?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("poA").value = ""
                                                setDocuments({...documents, poA: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={license} alt=""/>
                                    </div>
                                    <div>
                                        <div className={s.add_doc_title}>
                                            Licence (If applicable)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            If your business requires a license in order to operate, please upload it.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            personalAccount={true}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('license').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"license"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({...documents, license: event.currentTarget.files[0]})
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.license?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.license?.name}
                                        {
                                            documents?.license?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("license").value = ""
                                                setDocuments({...documents, license: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.required_fields} data-first-char="*">
                                required fields.
                            </div>
                            <div className={s.btn_block}>
                                <div className={s.button_back_wrapper}>
                                    <TransparentButton
                                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                        title={"Back"}
                                        personalAccount={true}
                                        type={"button"}
                                        onClick={() => props.setCurrent(0)}/>
                                </div>
                                <div className={s.button_apply_wrapper}>
                                    <MyBtn title={'Apply'} type="submit" personalAccount={true}/>
                                </div>
                            </div>

                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default KycPersonalStepTwo;

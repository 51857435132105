import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Checkbox, ConfigProvider, DatePicker, Popover} from "antd";
import s from "./StatementForm.module.css";
import dayjs from "dayjs";
import MyInput from "../../../../../components/ui/MyInput/MyInput";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {Formik, useFormikContext} from "formik";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import {setFilters} from "../../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import {useAppDispatch} from "../../../../../hooks/redux";
import CustomDropdownForAccounts
    from "../../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import CustomDropdown from "../../../../../components/ui/CustomDropdown/CustomDropdown";
import {
    getFirstDayOfCurrentMonth, getFirstDayOfLastWeek, getFirstDayOfPreviousMonth,
    getFirstWeekDay,
    getLastDayOfCurrentMonth, getLastDayOfLastWeek, getLastDayOfPreviousMonth,
    getLastWeekDay,
    myDate
} from "../../../../../helpers/CalculateDate";
import * as yup from "yup";
import {useAccountsForSelect} from "../../../../../hooks/useAccountsForSelect";
import info from "../../../../../assets/newImg/personalAccount/information.svg";

dayjs.extend(weekday)
dayjs.extend(localeData)

type propsType = {
    setDataForStatement: Dispatch<SetStateAction<any>>
}

const optionsTwo: string[] = [
    'Indicated by user',
    'Today',
    'Yesterday',
    'This week',
    'This month',
    'Last week',
    'Last 30 days',
    'Last month',
    'Last and current month',
];

const dateFormat = 'YYYY-MM-DD';

const SetDateByPeriod = (props: any) => {

    const { values } = useFormikContext();

    useEffect(() => {
        // @ts-ignore
        if (values.period === 'Today') {
            props.setDate({
                ...props.date,
                from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate()),
                to: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate())
            })
        }
        // @ts-ignore
        if (values.period === 'Yesterday') {
            props.setDate({
                ...props.date,
                from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + (myDate.getDate() - 1)),
                to: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + (myDate.getDate() - 1))
            })
        }
        // @ts-ignore
        if (values.period === 'This week') {
            props.setDate({
                ...props.date,
                from: (getFirstWeekDay().getFullYear() + "-" + (getFirstWeekDay().getMonth() + 1) + "-" + getFirstWeekDay().getDate()),
                to: (getLastWeekDay().getFullYear() + "-" + (getLastWeekDay().getMonth() + 1) + "-" + getLastWeekDay().getDate())
            })
        }
        // @ts-ignore
        if (values.period === 'This month') {
            props.setDate({
                ...props.date,
                from: (getFirstDayOfCurrentMonth().getFullYear() + "-" + (getFirstDayOfCurrentMonth().getMonth() + 1) + "-" + getFirstDayOfCurrentMonth().getDate()),
                to: (getLastDayOfCurrentMonth().getFullYear() + "-" + (getLastDayOfCurrentMonth().getMonth() + 1) + "-" + getLastDayOfCurrentMonth().getDate())
            })
        }
        // @ts-ignore
        if (values.period === 'Last week') {
            props.setDate({
                ...props.date,
                from: (getFirstDayOfLastWeek().getFullYear() + "-" + (getFirstDayOfLastWeek().getMonth() + 1) + "-" + getFirstDayOfLastWeek().getDate()),
                to: (getLastDayOfLastWeek().getFullYear() + "-" + (getLastDayOfLastWeek().getMonth() + 1) + "-" + getLastDayOfLastWeek().getDate())
            })
        }
        // @ts-ignore
        if (values.period === 'Last 30 days') {

            let myTempDate = new Date()
            const timestamp = myTempDate.setDate(myTempDate.getDate() -30)
            const thirtyDaysAgo = new Date(timestamp)

            props.setDate({
                ...props.date,
                from: (thirtyDaysAgo.getFullYear() + "-" + (thirtyDaysAgo.getMonth() + 1) + "-" + thirtyDaysAgo.getDate()),
                to: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate())
            })
        }
        // @ts-ignore
        if (values.period === 'Last month') {

            props.setDate({
                ...props.date,
                from: (getFirstDayOfPreviousMonth().getFullYear() + "-" + (getFirstDayOfPreviousMonth().getMonth() + 1) + "-" + getFirstDayOfPreviousMonth().getDate()),
                to: (getLastDayOfPreviousMonth().getFullYear() + "-" + (getLastDayOfPreviousMonth().getMonth() + 1) + "-" + getLastDayOfPreviousMonth().getDate())
            })
        }
        // @ts-ignore
        if (values.period === 'Last and current month') {

            props.setDate({
                ...props.date,
                from: (getFirstDayOfPreviousMonth().getFullYear() + "-" + (getFirstDayOfPreviousMonth().getMonth() + 1) + "-" + getFirstDayOfPreviousMonth().getDate()),
                to: (getLastDayOfCurrentMonth().getFullYear() + "-" + (getLastDayOfCurrentMonth().getMonth() + 1) + "-" + getLastDayOfCurrentMonth().getDate())
            })
        }

        // @ts-ignore
    },[props.period, values.period])

    return null
}

const validationSchema = yup.object({
    recipient: yup.string().max(20, 'max 20 symbols'),
    amount: yup.string()
        .matches(/^[0-9]+$/, "Must be only digits").max(20, 'max 20 digits'),
})

export const StatementForm = (props: propsType) => {

    const dispatch = useAppDispatch()
    const accountsList = useAccountsForSelect()
    const [isIncoming, setIsIncoming] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isOutgoing, setIsOutgoing] = useState(false)
    const [currency, setCurrency] = useState('')
    const [period, setPeriod] = useState('')
    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ?  ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate()),
        to: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ?  ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate())
    })

    useEffect(() => {
        return () => {
            dispatch(setFilters({
                accountNumber: '',
                period: null,
                from: '',
                to: '',
                purpose: '',
                recipient: '',
                amount: '',
                incoming: false,
                outgoing: false,
                currency: '',
                selectedType: '',
                templateName: ''
            }))
        }
    }, [])

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    },[])

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        color: '#000',
        padding: '17px 16px',
        height: 52,
        width: isMobile ? "46%" : 146,
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    DatePicker: {
                        colorPrimary: '#5E4005',
                        fontWeightStrong: 600,
                        colorLink: 'red'
                    }
                },
                token: {
                    colorPrimary: '#5E4005',
                    colorBorder: '#EEEFEF',
                    fontFamily: 'Inter',
                },
            }}
        >
            <Formik
                initialValues={{
                    accountNumber: '',
                    period: period ? period : '',
                    from: '',
                    to: '',
                    recipient: '',
                    amount: '',
                    incoming: false,
                    outgoing: false,
                    currency: ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const tempData = {
                        ...values,
                        from: date.from,
                        to: date.to,
                        incoming: isIncoming,
                        outgoing: isOutgoing,
                        currency: currency
                    }
                    props.setDataForStatement(tempData)
                    dispatch(setFilters(tempData))
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit
                  }) => (
                    <form className={s.form} onSubmit={handleSubmit}>
                        <div className={s.dropdown_wrapper}>
                            
                            <div className={s.accountNumberContainer}>
                                <p className={s.labelText} style={{marginBottom: 0}}>Account Number</p>
                                <Popover content={'Your internal account number.'}
                                        color={'#EDEDED'}
                                        placement={"bottom"}

                                        overlayInnerStyle={{
                                            fontFamily: 'Inter',
                                            padding: '13px 16px',
                                            backgroundColor: '#EDEDED',
                                            color: '#000',
                                            fontWeight: '700',
                                            width: '280px',
                                            boxShadow: 'none',
                                        }}
                                >
                                    <img src={info} alt=""/>
                                </Popover>
                            </div>
                            <CustomDropdownForAccounts
                                setCurrency={setCurrency}
                                items={accountsList}
                                id={'accountNumber'}
                                name={'accountNumber'}
                            />
                        </div>

                        <div className={s.periodRow}>
                            <div className={s.dropdown_wrapper}>
                                <p className={s.labelText}>Period</p>
                                <CustomDropdown
                                    items={optionsTwo}
                                    id={'period'}
                                    name={'period'}
                                />
                            </div>
                            <div className={''}>
                                <p className={s.labelText}>From</p>
                                <DatePicker suffixIcon={null} style={dateStyle}
                                            defaultValue={dayjs(new Date(), dateFormat)}
                                            value={dayjs(date.from)}
                                            format={dateFormat}
                                            onChange={(_, dateString) => {
                                                if (!dateString) {
                                                    setDate({...date, from: new Date().toDateString()})
                                                } else {
                                                    setDate({...date, from: dateString})
                                                }
                                                handleChange(values.period = 'Indicated by user')

                                            }}
                                />
                            </div>
                            <div className={''}>
                                <p className={s.labelText}>To</p>
                                <DatePicker suffixIcon={null} style={dateStyle}
                                            defaultValue={dayjs(new Date(), dateFormat)}
                                            value={dayjs(date.to)}
                                            format={dateFormat}
                                            onChange={(_, dateString) => {
                                                if (!dateString) {
                                                    setDate({...date, to: new Date().toDateString()})
                                                } else {
                                                    setDate({...date, to: dateString})
                                                }
                                                handleChange(values.period = 'Indicated by user')
                                            }}
                                />
                            </div>
                        </div>
                        <div className={s.recipientAmount}>
                            <div className={s.inputWrapper}>
                                <p className={s.labelText}>Recipient</p>
                                <MyInput
                                    id={'recipient'}
                                    name={'recipient'}
                                    onChange={handleChange}
                                    value={values.recipient}
                                    isError={errors.recipient}
                                />
                                {errors.recipient &&
                                    <div className={s.error_message}>{errors.recipient}</div>}
                            </div>
                            <div className={s.inputWrapper}>
                                <p className={s.labelText}>Amount</p>
                                <MyInput
                                    id={'amount'}
                                    name={'amount'}
                                    onChange={handleChange}
                                    value={values.amount}
                                    isError={errors.amount}
                                />
                                {errors.amount &&
                                    <div className={s.error_message}>{errors.amount}</div>}
                            </div>
                        </div>
                        <div className={s.transactionsBlock}>
                            <p className={s.labelText}>Transactions</p>
                            <div className={s.checkboxWrapper}>
                                <Checkbox
                                    className={s.checkbox}
                                    value={isIncoming}
                                    onChange={() => setIsIncoming(prevState => !prevState)}
                                >
                                    Incoming
                                </Checkbox>
                                <Checkbox
                                    className={s.checkbox}
                                    value={isOutgoing}
                                    onChange={() => setIsOutgoing(prevState => !prevState)}
                                >
                                    Outgoing
                                </Checkbox>
                            </div>
                        </div>
                        <div className={s.buttonWrapper}>
                            <MyBtn type={'submit'} style={{maxWidth: 180}} title={'Show'}/>
                        </div>
                        <SetDateByPeriod
                            values={values}
                            period={period}
                            date={date}
                            setDate={setDate}
                        />
                    </form>)}
            </Formik>
        </ConfigProvider>
    )
}

import React, {useEffect, useState} from 'react'
import s from './AccountTopUp.module.css'
import {Popover} from "antd";
import infoIcon from "../../../../assets/newImg/personalAccount/info.svg"
import CustomDropdownForAccounts from "../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {Formik, useFormikContext} from "formik";
import {getTopUpInfo} from "../../../../store/reducers/ActionCreators";

const AutoSubmitComponent = () => {

    const {values, submitForm} = useFormikContext();

    useEffect(() => {
        submitForm()
    }, [values])

    return null
}

export const AccountTopUp = () => {

    const accountsList = useAppSelector(state => state.userReducer.accountsList)
    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const [topUpInfo, setTopUpInfo] = useState({
        'beneficiaryName': '',
        'beneficiaryAccountNumber': '',
        'beneficiaryAddress': '',
        'referenceCode': '',
    })

    return (
        <div className={s.accountTopUpWrapper}>
            <div className={s.infoWrapper}>
                <p>
                    Account top-up executed not in accordance with our instructions is charged with an additional
                    crediting/refunding fee, depending on the particular bank.
                </p>
                <p>You can add funds to your Mondial account by a standard transfer from you bank account. There
                    is <b>no fee&nbsp;</b>
                    for topping up your Mondial account unless the incoming payment is made via the TARGET2 system. In
                    this case, a crediting fee applies: for amounts up to 9,999.99 USD, the fee is <b>3.00 USD</b>, for
                    10,000.00
                    USD or more, the fee is <b>4.00 USD.</b></p>
            </div>
            <Formik initialValues={{
                accountNumber: ''
            }}
                    onSubmit={(values) => {
                        dispatch(getTopUpInfo(token, values))
                            .then((res: any) => {
                                setTopUpInfo(res.data)
                            })
                            .catch(() => {
                                setTopUpInfo({
                                    'beneficiaryName': '',
                                    'beneficiaryAccountNumber': '',
                                    'beneficiaryAddress': '',
                                    'referenceCode': '',
                                })
                            })
                    }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={s.detailsWrapper}>
                            <p className={s.detailsTitle}>Details for the Bank Transfer</p>
                            <div>
                                <p className={s.labelText}>Payer account <Popover
                                    content={"Your internal account number."}
                                    color={'#EEEFEF'}
                                    placement={"bottom"}
                                    overlayInnerStyle={{
                                        fontFamily: 'Inter',
                                        padding: '13px 16px',
                                        backgroundColor: '#EEEFEF',
                                        boxShadow: 'none'
                                    }}
                                >
                                    <img src={infoIcon} alt=""/>
                                </Popover></p>
                                <CustomDropdownForAccounts
                                    items={accountsList}
                                    id='accountNumber'
                                    name='accountNumber'
                                    placeholder={"Please select the account"}
                                />
                            </div>
                            <div className={s.infoWrapper}>
                                <div className={s.line}>
                                    <p className={s.field}>
                                        Beneficiary Name
                                    </p>
                                    <p className={s.info}>
                                        {topUpInfo.beneficiaryName}
                                    </p>
                                </div>

                                <div className={s.line}>
                                    <p className={s.field}>
                                        Beneficiary account number
                                    </p>
                                    <p className={s.info}>
                                        {topUpInfo.beneficiaryAccountNumber}
                                    </p>
                                </div>

                                <div className={s.line}>
                                    <p className={s.field}>
                                        Beneficiary address
                                    </p>
                                    <p className={s.info}>
                                        {topUpInfo.beneficiaryAddress}
                                    </p>
                                </div>

                                <div className={s.line}>
                                    <p className={s.field}>
                                        Reference code
                                    </p>
                                    <p className={s.info}>
                                        {topUpInfo.referenceCode}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <AutoSubmitComponent/>

                    </form>
                )}
            </Formik>
        </div>
    )
}

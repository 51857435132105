import {useNavigate} from "react-router-dom";
import BankingTemplate from '../../../components/BankingTemplate/BankingTemplate';
import corporate from '../../../assets/newImg/publicPages/corporate.jpeg';
import briefcase from '../../../assets/newImg/publicPages/briefcase.svg';
import wallet from '../../../assets/newImg/publicPages/wallet-money.svg';
import business from '../../../assets/newImg/publicPages/business.svg';
import group from '../../../assets/newImg/publicPages/group.svg'; 

const Corporate = () => {

    const navigate = useNavigate();

    const text = "In the dynamic world of business, Mondial Private Bank is your steadfast partner. Our corporate banking division offers " +
    "a range of services tailored to your business needs. Whether you're looking to expand internationally, manage cash flows, or " +
    "ensure seamless transactions, our corporate solutions are designed to help your business grow.";

    const services = [
        {
            icon: briefcase,
            description: 'Business Accounts and Cash Management',
        },
        {
            icon: wallet,
            description: 'Trade Finance and Working Capital Solutions',
        },
        {
            icon: business,
            description: 'Financing Options',
        },
        {
            icon: group,
            description: 'Foreign Exchange and Treasury Services',
        },
    ]

    return (
        <BankingTemplate title='corporate banking' 
                         backColor='#E9E0CE' 
                         textColor='#816838'
                         text={text}
                         image={corporate}
                         subTitle='Discover how our corporate banking expertise can support your business ambitions' 
                         servicesInfo={services}
        />
    );
};

export default Corporate;

import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import s from './TwoFactor.module.css'
import {Switch} from "antd";
import {ReactComponent as Check} from "../../../../assets/newImg/personalAccount/checked.svg";
import {ReactComponent as Cross} from "../../../../assets/newImg/personalAccount/cross.svg";
import './index.css'
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {
    disable2faAutentication,
    disableSmsAutentication,
    get2FaThunk,
    setSmsCodeThunk
} from "../../../../store/reducers/ActionCreators";
import {setIsLoading} from "../../../../store/reducers/UserSlice";
import Form2FaSettings from "../../../../components/Form2FaSettings/Form2FaSettings";

const TwoFactor = () => {

    const status2Fa = useAppSelector(state => state.userReducer.user.verificationsList.twoFaVerifyEnabled)
    const phoneVerifyEnabled = useAppSelector(state => state.userReducer.user.verificationsList.phoneVerifyEnabled)
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const [qrData, setQrData] = useState({
        code: '',
        qr: ''
    })

    const dispatch = useAppDispatch()

    const [is2faSettingsOpen, setIs2faSettingsOpen] = useState(false)
    const [checkedSw, setCheckedSw] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    const twoFaHandleChange = () => {
        if (status2Fa) {
            dispatch(disable2faAutentication(token))
            return
        }

        !status2Fa && setIs2faSettingsOpen(true)
        dispatch(setIsLoading(true))
        dispatch(get2FaThunk(token))
            .then((res) => {
                setQrData(res.data)
            })
        dispatch(setIsLoading(false))
    }
    const setSmsCodeHandleChange = () => {
        if (phoneVerifyEnabled) {
            dispatch(disableSmsAutentication(token))
            return
        }

        dispatch(setSmsCodeThunk(token))
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            {is2faSettingsOpen
                ? <Form2FaSettings qrData={qrData} setIs2faSettingsOpen={setIs2faSettingsOpen}/>
                : <div>
                    <div className={s.wrapper}>
                        <p className={s.common_desc}>
                            The use of two-factor authentication is a prerequisite. Take advantage of the security tools
                            we offer
                            to keep your account secure at all times.
                        </p>
                        <br/>
                        <p className={s.common_desc}>
                            If multiple two-factor authentication methods are enabled, then U2F takes precedence over
                            2FA and SMS.
                        </p>
                    </div>

                    <div className={s.autentication_list_block}>
                        <div className={s.autentication_item}>
                            <div className={s.row}>
                                <span className={s.autentication_title}>SMS Code</span>
                                <Switch
                                    rootClassName={s.my_root_switch}
                                    className={s.my_switch}
                                    checked={phoneVerifyEnabled}
                                    onChange={() => setSmsCodeHandleChange()}
                                    checkedChildren={<Check style={phoneVerifyEnabled ? {
                                        position: 'relative',
                                        boxShadow: 'none',
                                        left: 19,
                                        top: isMobile ? 5 : 2
                                    } : {position: 'relative', left: 19, top: 100}}/>}
                                    unCheckedChildren={<Cross style={phoneVerifyEnabled ? {
                                        position: 'relative',
                                        left: 19.5,
                                        top: 100
                                    } : {position: 'relative', right: isMobile ? 19 : 20, top: isMobile ? 12 : -1}}/>}

                                />
                            </div>
                            <div className={s.autentication_item_description}>
                                <b>SMS Code</b> two-factor authentication - SMS code that will be sent to the phone
                                specified during
                                user registration.
                            </div>
                        </div>

                        <div className={s.autentication_item}>
                            <div className={s.row}>
                                <span className={s.autentication_title}>Google Authenticator (2FA)</span>
                                <Switch
                                    rootClassName={s.my_root_switch}
                                    className={s.my_switch}
                                    checked={status2Fa}
                                    onChange={() => twoFaHandleChange()}
                                    checkedChildren={<Check style={status2Fa ? {
                                        position: 'relative',
                                        boxShadow: 'none',
                                        left: isMobile ? 17 : 19,
                                        top: isMobile ? 5 : 2
                                    } : {position: 'relative', left: 19, top: 100}}/>}
                                    unCheckedChildren={<Cross style={status2Fa ? {
                                        position: 'relative',
                                        left: 19,
                                        top: 100
                                    } : {position: 'relative', right: isMobile ? 19 : 20, top: isMobile ? 12 : -1}}/>}

                                />
                            </div>
                            <div className={s.autentication_item_description}>
                                <b>Google Authenticator (2FA)</b> two-factor authentication - software token in Google
                                App for Android
                                and iPhone.
                            </div>
                        </div>

                        <div className={s.autentication_item}>
                            <div className={s.row}>
                                <span className={s.autentication_title}>Fido Certified U2F</span>
                                <Switch
                                    rootClassName={s.my_root_switch}
                                    className={s.my_switch}
                                    checked={checkedSw}
                                    onChange={() => setCheckedSw(!checkedSw)}
                                    checkedChildren={<Check style={checkedSw ? {
                                        position: 'relative',
                                        boxShadow: 'none',
                                        left: 18,
                                        top: isMobile ? 5 : 2
                                    } : {position: 'relative', left: 19, top: 100}}/>}
                                    unCheckedChildren={<Cross style={checkedSw ? {
                                        position: 'relative',
                                        left: 19.5,
                                        top: 100
                                    } : {position: 'relative', right: isMobile ? 19 : 20, top: isMobile ? 12 : -1}}/>}

                                />
                            </div>
                            <div className={s.autentication_item_description}>
                                <b>FIDO U2F</b> two-factor authentication - hardware token (physical security key).
                            </div>
                        </div>
                    </div>
                </div>
            }

        </motion.div>
    );
};

export default TwoFactor;

import React, {Dispatch, SetStateAction, useLayoutEffect, useState} from 'react';
import * as yup from "yup";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/redux";
import s from "../KycBusinessStepTwo/KycBusinessStepTwo.module.css";
import CustomModal from "../../../../../../components/ui/CustomModal/CustomModal";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import {Formik} from "formik";
import {editKycThunk, setTokenState} from "../../../../../../store/reducers/ActionCreators";
import MyInput from "../../../../../../components/ui/MyInput/MyInput";
import CustomDropdown from "../../../../../../components/ui/CustomDropdown/CustomDropdown";
import PhoneCodesInput from "../../../../../../components/ui/PhoneCodesInput/PhoneCodesInput";
import legal_account_application from "../../../../../../assets/newImg/personalAccount/legal_account_application.png";
import classnames from "classnames";
import download_legal from "../../../../../../assets/newImg/personalAccount/download_legal_application.svg";
import {ReactComponent as Plus} from "../../../../../../assets/newImg/personalAccount/plus.svg";
import attachedDoc from "../../../../../../assets/newImg/personalAccount/attached_document.svg";
import delete_document from "../../../../../../assets/newImg/personalAccount/delete_document.svg";
import proofOfAddress from "../../../../../../assets/newImg/personalAccount/proof_of_address.png";
import companyCharter from "../../../../../../assets/newImg/personalAccount/company_charter.png";
import registrationSertificate from "../../../../../../assets/newImg/personalAccount/registration_sertificate.png";
import beneficiaryIdDoc from "../../../../../../assets/newImg/personalAccount/beneficiary_identity.png";
import beneficiaryIdcp from "../../../../../../assets/newImg/personalAccount/beneficiary_idcp.png";
import BeneficiaryProofOfAddress from "../../../../../../assets/newImg/personalAccount/proof_of_address.png";
import Poa from "../../../../../../assets/newImg/personalAccount/poa.png";
import license from "../../../../../../assets/newImg/personalAccount/license.png";
import {ReactComponent as Back}  from "../../../../../../assets/newImg/personalAccount/back.svg";
import {motion} from "framer-motion";
import rejectedDoc from "../../../../../../assets/newImg/personalAccount/rejected_document.svg";
import delete_rejected_document from "../../../../../../assets/newImg/personalAccount/rejected_close.svg";
import TransparentButton from "../../../../../../components/ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {countries, phonecodes} from "../../../../../../Constants/StaticData";
import {setDisabled} from "../../../../../../store/reducers/UserSlice";
import SelectWithSearch from "../../../../../../components/ui/SelectWithSearch/SelectWithSearch";

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const validationSchema = yup.object({
    phoneNumber: yup.number().required('Required field'),
    companyName: yup.string().required('Required field'),
})

const EditKyc = (props: PropsType) => {

    const token: string = useAppSelector(state => state.userReducer.user.userToken)
    const newKycList = useAppSelector(state => state.userReducer.user.kycList)
    const dispatch = useAppDispatch()

    // @ts-ignore
    const phoneNumber = newKycList[0].phoneNumber.split(' ')

    const [code, setCode] = useState<any>()
    const [documents, setDocuments] = useState<any>(
        {
            accountApplication: '',
            proofOfAddress: '',
            articlesMemorandums: '',
            registrationCertificate: '',
            beneficiaryIdDocument: '',
            beneficiaryIdcp: '',
            beneficiaryProofOfAddress: '',
            poA: '',
            license: '',
        }
    )
    const [docsError, setDocsError] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again later.");
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    useLayoutEffect(() => {
        const prevPhone = phonecodes.filter((item) => item.ph === phoneNumber[0])
        setCode(prevPhone[0])
    }, [])

    function toBase64(file: any) {

        if (!file) {
            return
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const closeAndRedirect = () => {
        setIsModalOpen(false)
        props.setCurrent(0)
    }

    // @ts-ignore
    const owner = newKycList[0]?.owner

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>

                <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                    <div className={s.flex_modal}>
                        <div className={s.modal_title}>
                            Success!
                        </div>
                        <div className={s.modal_subtitle}>
                            Your details will be sent for review.
                        </div>
                        <div className={s.button_block}>
                            <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal type="error" isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen}>
                    <div className={s.flex}>
                        <div className={s.modal_title}>
                            Oops!
                        </div>
                        <div className={s.modal_subtitle}>
                            {errorMessage}
                        </div>
                        <div className={s.button_block}>
                            <MyBtn buttonType="error" title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                        </div>
                    </div>
                </CustomModal>

                <div className={s.title}>
                    KYC for Business Account
                </div>

                <Formik
                    initialValues={{
                        // @ts-ignore
                        kycId: newKycList[0].id,
                        companyName: owner,
                        // @ts-ignore
                        country: newKycList[0].country,
                        phoneNumber: phoneNumber[1],
                        files: {
                            accountApplication: '',
                            articlesMemorandums: '',
                            proofOfAddress: '',
                            registrationCertificate: '',
                            beneficiaryIdDocument: '',
                            beneficiaryIdcp: '',
                            beneficiaryProofOfAddress: '',
                        }
                    }}
                    onSubmit={async (values) => {
                        let tempData = {
                            ...values,
                            phoneNumber: `${code.ph} ${values.phoneNumber}`,
                            files: {}
                        }

                        let convertedAccountApplication = await toBase64(documents.accountApplication) as string
                        let convertedarticlesMemorandums = await toBase64(documents.articlesMemorandums) as string
                        let convertedProofOfAddress = await toBase64(documents.proofOfAddress) as string
                        let convertedRegistrationCertificate = await toBase64(documents.registrationCertificate) as string
                        let convertedBeneficiaryIdDocument = await toBase64(documents.beneficiaryIdDocument) as string
                        let convertedBeneficiaryIdcp = await toBase64(documents.beneficiaryIdcp) as string
                        let convertedBeneficiaryProofOfAddress = await toBase64(documents.beneficiaryProofOfAddress) as string

                        if (convertedBeneficiaryProofOfAddress) {
                            tempData.files = {
                                ...tempData.files,
                                beneficiaryProofOfAddress: convertedBeneficiaryProofOfAddress,
                            }
                        }

                        if (convertedBeneficiaryIdcp) {
                            tempData.files = {
                                ...tempData.files,
                                beneficiaryIdcp: convertedBeneficiaryIdcp,
                            }
                        }

                        if (convertedBeneficiaryIdDocument) {
                            tempData.files = {
                                ...tempData.files,
                                beneficiaryIdDocument: convertedBeneficiaryIdDocument,
                            }
                        }

                        if (convertedRegistrationCertificate) {
                            tempData.files = {
                                ...tempData.files,
                                registrationCertificate: convertedRegistrationCertificate,
                            }
                        }

                        if (convertedProofOfAddress) {
                            tempData.files = {
                                ...tempData.files,
                                proofOfAddress: convertedProofOfAddress,
                            }
                        }

                        if (convertedAccountApplication) {
                            tempData.files = {
                                ...tempData.files,
                                accountApplication: convertedAccountApplication,
                            }
                        }

                        if (convertedarticlesMemorandums) {
                            tempData.files = {
                                ...tempData.files,
                                articlesMemorandums: convertedarticlesMemorandums,
                            }
                        }

                        if (documents.poA !== '') {
                            // @ts-ignore
                            let convertedPoA = await toBase64(documents.poA) as string

                            if (convertedPoA) {
                                tempData.files = {
                                    ...tempData.files,
                                    poA: convertedPoA,
                                }
                            }
                        }

                        if (documents.license !== '') {
                            // @ts-ignore
                            let convertedLicense = await toBase64(documents.license) as string

                            if (convertedLicense) {
                                tempData.files = {
                                    ...tempData.files,
                                    license: convertedLicense,
                                }
                            }
                        }

                        dispatch(editKycThunk(token, tempData))
                            .then((res) => {
                                if (res.data === "Updated") {
                                    setIsModalOpen(true)
                                }
                            })
                            .catch((error) => {
                                if (error.response.data === "Invalid authorization data") {
                                    dispatch(setTokenState(false))
                                    return
                                }

                                if (error.response.data === "Too many phones. Max count is 5") {
                                    setErrorMessage(error.response.data)
                                    setIsErrorModalOpen(true)
                                    return
                                }

                                if (error.response.data === "Phone already exist") {
                                    setErrorMessage("This phone number already exists. Please try another phone number.")
                                    setIsErrorModalOpen(true)
                                    return
                                }

                                if (error.code === "ERR_NETWORK" && !error.response) {
                                    setErrorMessage("Maximum files size is 17 MB")
                                    setIsErrorModalOpen(true)
                                } else {
                                    setErrorMessage("Something went wrong. Please try again later.")
                                    setIsErrorModalOpen(true)
                                }
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (

                        <form onSubmit={handleSubmit}>
                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Company name
                                    </div>
                                    <MyInput id='companyName'
                                             isError={errors.companyName}
                                             name='companyName'
                                             onChange={handleChange}
                                             value={values.companyName}
                                             touched={touched.companyName}
                                    />
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Country
                                    </div>
                                    <SelectWithSearch
                                        id="country"
                                        name="country"
                                        isError={errors.country}
                                        items={countries}
                                    />
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Phone number
                                    </div>
                                    <PhoneCodesInput
                                        initCode={code}
                                        phonecodes={phonecodes}
                                        setCode={setCode}
                                        id='phoneNumber'
                                        name='phoneNumber'
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                        error={errors.phoneNumber}
                                        touched={touched.phoneNumber}
                                    />
                                </div>
                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={legal_account_application} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.accountApplication === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Legal Account Application
                                        </div>
                                        <div>
                                            <div className={s.passport_spread_list}>
                                                <div>
                                        <span className={classnames(s.flex, s.text_color)}>
                                           1. <img src={download_legal} alt=""/>
                                            <a href="/Legal_Account_Application_form.pdf" target={"_blank"} className={s.download_link}>
                                                Download legal account application
                                            </a>
                                        </span>
                                                </div>
                                                <div>
                                        <span className={s.text_color}>
                                             2. Fill in relevant information.
                                        </span>
                                                </div>
                                                <div>
                                        <span className={s.text_color}>
                                            3. Upload filled and signed legal account application form.
                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('accountApplication').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"accountApplication"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       accountApplication: event.currentTarget.files[0]
                                                   });
                                               }}
                                        />
                                    </div>
                                    <div className={classnames(s.attached_file)}>

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "accountApplication"
                                                && documents.accountApplication === ''
                                                && <img key={index} src={rejectedDoc} alt=""/>)
                                        }

                                        {
                                            documents?.accountApplication?.name && <img src={attachedDoc} alt=""/>
                                        }

                                        {documents?.accountApplication?.name}

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "accountApplication"
                                                && documents.accountApplication === ''
                                                && <span key={index} className={s.rejected_doc_name}>account_application</span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "New"
                                                && item.fileType === "accountApplication"
                                                && documents.accountApplication === ''
                                                && <span key={index} className={s.approved_doc_name}>account_application</span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Approve"
                                                && item.fileType === "accountApplication"
                                                && documents.accountApplication === ''
                                                && <span key={index} className={s.approved_doc_name}>account_application</span>)
                                        }

                                        {
                                            documents?.accountApplication?.name &&
                                            <img onClick={() => {
                                                setDocuments({...documents, accountApplication: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && documents.accountApplication === ''
                                                && item.fileType === "accountApplication"
                                                && <img className={s.delete_doc}
                                                        src={delete_rejected_document}
                                                        alt=""
                                                        key={index}
                                                />)

                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={proofOfAddress} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.proofOfAddress === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Company Proof of Address
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Acceptable documents are:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            Bank statement with the date of issue and the name of the person (the document
                                            must be not older than 3 months);
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Utility bill for gas, electricity, water, internet, etc. linked to the property
                                            (the document must not be older than 3 months).
                                        </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('proofOfAddress').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"proofOfAddress"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       proofOfAddress: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "proofOfAddress"
                                                && documents.proofOfAddress === ''
                                                && <img key={index} src={rejectedDoc} alt=""/>)
                                        }

                                        {
                                            documents?.proofOfAddress?.name && <img src={attachedDoc} alt=""/>
                                        }

                                        {documents?.proofOfAddress?.name}

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "proofOfAddress"
                                                && documents.proofOfAddress === ''
                                                && <span key={index} className={s.rejected_doc_name}>proof_of_Address</span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "New"
                                                && item.fileType === "proofOfAddress"
                                                && documents.proofOfAddress === ''
                                                && <span key={index} className={s.approved_doc_name}>proof_of_Address</span>)
                                        }

                                        {
                                            documents?.proofOfAddress?.name &&
                                            <img onClick={() => {
                                                setDocuments({...documents, proofOfAddress: ''})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item,index) => item.status === "Rejected"
                                                && documents.proofOfAddress === ''
                                                && item.fileType === "proofOfAddress"
                                                && <img className={s.delete_doc}
                                                        src={delete_rejected_document}
                                                        alt=""
                                                        key={index}
                                                />)

                                        }

                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={companyCharter} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.articlesMemorandums === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Articles & Memorandums of Association
                                        </div>
                                        <div className={s.add_block_text}>
                                            Upload company Charter/Statute/Memorandum/Articles of Association/Memorandum
                                            of
                                            Association
                                            or any other relevant statutory documents.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('articlesMemorandums').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"articlesMemorandums"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       articlesMemorandums: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "articlesMemorandums"
                                                && documents.articlesMemorandums === ''
                                                && <img key={index} src={rejectedDoc} alt=""/>)
                                        }

                                        {
                                            documents?.articlesMemorandums?.name && <img src={attachedDoc} alt=""/>
                                        }

                                        {documents?.articlesMemorandums?.name}

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "articlesMemorandums"
                                                && documents.articlesMemorandums === ''
                                                && <span key={index} className={s.rejected_doc_name}>articles_&_memorandums </span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "New"
                                                && item.fileType === "articlesMemorandums"
                                                && documents.articlesMemorandums === ''
                                                && <span key={index} className={s.approved_doc_name}>company_charter</span>)
                                        }

                                        {
                                            documents?.articlesMemorandums?.name &&
                                            <img onClick={() => {
                                                setDocuments({...documents, articlesMemorandums: null})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && documents.articlesMemorandums === ''
                                                && item.fileType === "articlesMemorandums"
                                                && <img className={s.delete_doc}
                                                        src={delete_rejected_document}
                                                        alt=""
                                                        key={index}
                                                />)

                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={registrationSertificate} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.registrationCertificate === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Registration Certificate
                                        </div>
                                        <div className={s.add_block_text}>
                                            Upload Certificate of Incorporation or Certificate of enterprise
                                            registration.
                                        </div>
                                        {/*<div className={s.add_block_text}>*/}
                                        {/*    You can paste a link to government website with company information.*/}
                                        {/*</div>*/}
                                        {/*<div className={s.input_block}>*/}
                                        {/*    <div className={s.input_label}>*/}
                                        {/*        Link*/}
                                        {/*    </div>*/}
                                        {/*    <MyInput/>*/}
                                        {/*</div>*/}

                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('registrationCertificate').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"registrationCertificate"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       registrationCertificate: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "registrationCertificate"
                                                && documents.registrationCertificate === ''
                                                && <img key={index} src={rejectedDoc} alt=""/>)
                                        }

                                        {
                                            documents?.registrationCertificate?.name && <img src={attachedDoc} alt=""/>
                                        }

                                        {documents?.registrationCertificate?.name}

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "registrationCertificate"
                                                && documents.registrationCertificate === ''
                                                &&
                                                <span key={index} className={s.rejected_doc_name}>registration_certificate</span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "New"
                                                && item.fileType === "registrationCertificate"
                                                && documents.registrationCertificate === ''
                                                &&
                                                <span key={index} className={s.approved_doc_name}>registration_certificate</span>)
                                        }

                                        {
                                            documents?.registrationCertificate?.name &&
                                            <img onClick={() => {
                                                setDocuments({...documents, registrationCertificate: null})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && documents.registrationCertificate === ''
                                                && item.fileType === "registrationCertificate"
                                                && <img className={s.delete_doc}
                                                        src={delete_rejected_document}
                                                        alt=""
                                                        key={index}
                                                />)

                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={beneficiaryIdDoc} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryIdDocument === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary Identity document (Passport, ID Card, Driving license)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Good photo tips:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            A color photo contains full document in clear focus and the data can be read easily.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             There're no light glares impeding document readability.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             A photo was not edited in graphic applications (we will definitely check).
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Picture format: JPEG, JPG, PNG, GIF, HEIC, PDF.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Minimum picture resolution: 800 x 600 pixels.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Maximum file size: 8Mb.
                                        </span>
                                            </li>
                                            <div className={s.attention}>
                                                Please upload Identity document (Passport, ID Card, Driving license)
                                                photos
                                                of each
                                                Shareholder with more than 25% ownership, all Board Members and
                                                Directors.
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('beneficiaryIdDocument').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"beneficiaryIdDocument"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       beneficiaryIdDocument: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "beneficiaryIdDocument"
                                                && documents.beneficiaryIdDocument === ''
                                                && <img key={index} src={rejectedDoc} alt=""/>)
                                        }

                                        {
                                            documents?.beneficiaryIdDocument?.name && <img src={attachedDoc} alt=""/>
                                        }

                                        {documents?.beneficiaryIdDocument?.name}

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "beneficiaryIdDocument"
                                                && documents.beneficiaryIdDocument === ''
                                                && <span key={index} className={s.rejected_doc_name}>beneficiary_id_document</span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "New"
                                                && item.fileType === "beneficiaryIdDocument"
                                                && documents.beneficiaryIdDocument === ''
                                                && <span key={index} className={s.approved_doc_name}>beneficiary_id_document</span>)
                                        }

                                        {
                                            documents?.beneficiaryIdDocument?.name &&
                                            <img onClick={() => {
                                                setDocuments({...documents, beneficiaryIdDocument: null})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && documents.beneficiaryIdDocument === ''
                                                && item.fileType === "beneficiaryIdDocument"
                                                && <img className={s.delete_doc}
                                                        src={delete_rejected_document}
                                                        alt=""
                                                        key={index}
                                                />)

                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={beneficiaryIdcp} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryIdcp === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary IDCP
                                        </div>
                                        <div className={s.add_block_text} style={{marginBottom: '16px'}}>
                                            Upload an ID confirmation photo (selfie of the Beneficiary holding in his
                                            hands
                                            his ID
                                            and a paper with written name of platform "Iberbanco", date (dd/mm/yyyy/)
                                            and
                                            Beneficiary's
                                            signature)
                                        </div>
                                        <div className={s.attention}>
                                            Please upload Identity document (Passport, ID Card, Driving license) photos
                                            of
                                            each
                                            Shareholder with more than 25% ownership, all Board Members and Directors.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('beneficiaryIdcp').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="image/png, image/jpeg, application/pdf"
                                               id={"beneficiaryIdcp"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       beneficiaryIdcp: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "beneficiaryIdcp"
                                                && documents.beneficiaryIdcp === ''
                                                && <img key={index} src={rejectedDoc} alt=""/>)
                                        }

                                        {
                                            documents?.beneficiaryIdcp?.name && <img src={attachedDoc} alt=""/>
                                        }

                                        {documents?.beneficiaryIdcp?.name}

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "beneficiaryIdcp"
                                                && documents.beneficiaryIdcp === ''
                                                && <span key={index} className={s.rejected_doc_name}>beneficiary_idcp</span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "New"
                                                && item.fileType === "beneficiaryIdcp"
                                                && documents.beneficiaryIdcp === ''
                                                && <span key={index} className={s.approved_doc_name}>beneficiary_idcp</span>)
                                        }

                                        {
                                            documents?.beneficiaryIdcp?.name &&
                                            <img onClick={() => {
                                                setDocuments({...documents, beneficiaryIdcp: null})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && documents.beneficiaryIdcp === ''
                                                && item.fileType === "beneficiaryIdcp"
                                                && <img className={s.delete_doc}
                                                        src={delete_rejected_document}
                                                        alt=""
                                                        key={index}
                                                />)

                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={BeneficiaryProofOfAddress} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryProofOfAddress === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary Proof of Address
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Acceptable documents are:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            Bank statement with the date of issue and the name of the person (the document
                                            must be not older than 3 months);
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Utility bill for gas, electricity, water, internet, etc. linked to the
                                            property (the document must not be older than 3 months).
                                        </span>
                                            </li>
                                        </ul>
                                        {/*<div className={s.attention}>*/}
                                        {/*    Please upload Identity document (Passport, ID Card, Driving license) photos*/}
                                        {/*    of*/}
                                        {/*    each*/}
                                        {/*    Shareholder with more than 25% ownership, all Board Members and Directors.*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('beneficiaryProofOfAddress').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"beneficiaryProofOfAddress"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       beneficiaryProofOfAddress: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "beneficiaryProofOfAddress"
                                                && documents.beneficiaryProofOfAddress === ''
                                                && <img key={index} src={rejectedDoc} alt=""/>)
                                        }

                                        {
                                            documents?.beneficiaryProofOfAddress?.name &&
                                            <img src={attachedDoc} alt=""/>
                                        }

                                        {documents?.beneficiaryProofOfAddress?.name}

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "beneficiaryProofOfAddress"
                                                && documents.beneficiaryProofOfAddress === ''
                                                && <span
                                                    key={index}
                                                    className={s.rejected_doc_name}>beneficiary_proof_of_address</span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "New"
                                                && item.fileType === "beneficiaryProofOfAddress"
                                                && documents.beneficiaryProofOfAddress === ''
                                                && <span
                                                    key={index}
                                                    className={s.approved_doc_name}>beneficiary_proof_of_address</span>)
                                        }

                                        {
                                            documents?.beneficiaryProofOfAddress?.name &&
                                            <img onClick={() => {
                                                setDocuments({...documents, beneficiaryProofOfAddress: null})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && documents.beneficiaryProofOfAddress === ''
                                                && item.fileType === "beneficiaryProofOfAddress"
                                                && <img className={s.delete_doc}
                                                        src={delete_rejected_document}
                                                        alt=""
                                                        key={index}
                                                />)

                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={Poa} alt=""/>
                                    </div>
                                    <div>
                                        <div className={s.add_doc_title}>
                                            PoA (If applicable)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            If the company is represented by a person not authorized to do so by the
                                            company’s
                                            Articles of Association or equivalent document, please upload a Power of
                                            Attorney
                                            asserting the right of this person to represent company's interests.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('poA').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"poA"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({...documents, poA: event.currentTarget.files[0]})
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "poA"
                                                && documents.poA === ''
                                                && <img key={index} src={rejectedDoc} alt=""/>)
                                        }

                                        {
                                            documents?.poA?.name && <img src={attachedDoc} alt=""/>
                                        }

                                        {documents?.poA?.name}

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "poA"
                                                && documents.poA === ''
                                                && <span key={index} className={s.rejected_doc_name}>poA</span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "New"
                                                && item.fileType === "poA"
                                                && documents.poA === ''
                                                && <span key={index} className={s.approved_doc_name}>poA</span>)
                                        }

                                        {
                                            documents?.poA?.name &&
                                            <img onClick={() => {
                                                setDocuments({...documents, poA: null})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && documents.poA === ''
                                                && item.fileType === "poA"
                                                && <img className={s.delete_doc}
                                                        src={delete_rejected_document}
                                                        alt=""
                                                        key={index}
                                                />)

                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={license} alt=""/>
                                    </div>
                                    <div>
                                        <div className={s.add_doc_title}>
                                            Licence (If applicable)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            If your business requires a license in order to operate, please upload it.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('license').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"license"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({...documents, license: event.currentTarget.files[0]})
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "license"
                                                && documents.license === ''
                                                && <img key={index} src={rejectedDoc} alt=""/>)
                                        }

                                        {
                                            documents?.license?.name && <img src={attachedDoc} alt=""/>
                                        }

                                        {documents?.license?.name}

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "license"
                                                && documents.license === ''
                                                && <span key={index} className={s.rejected_doc_name}>license</span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "New"
                                                && item.fileType === "license"
                                                && documents.license === ''
                                                && <span key={index} className={s.approved_doc_name}>license</span>)
                                        }

                                        {
                                            documents?.license?.name &&
                                            <img onClick={() => {
                                                setDocuments({...documents, license: null})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && documents.license === ''
                                                && item.fileType === "license"
                                                && <img className={s.delete_doc}
                                                        src={delete_rejected_document}
                                                        alt=""
                                                        key={index}
                                                />)

                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.required_fields}>
                                * required fields.
                            </div>

                            <div className={s.btn_block}>
                                <div className={s.button_back_wrapper}>
                                    <TransparentButton
                                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                        title={"Back"}
                                        type={"button"}
                                        onClick={() => props.setCurrent(0)}/>
                                </div>
                                <div className={s.button_apply_wrapper}>
                                    <MyBtn title={'Apply'} type="submit"/>
                                </div>
                            </div>

                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default EditKyc;

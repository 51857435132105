import React from 'react';
import styles from './BankHistory.module.css'
import s from "../../../styles/styles.module.css"
import classnames from "classnames";
import {motion} from 'framer-motion';

const BankHistory = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={classnames(s.wrapper, s.main_block)}>
                <div className={s.container}>
                    <div className={s.title}>
                        <div className={s.main_title}>
                            our history
                        </div>
                        <div className={s.back_title}>
                            our history
                        </div>
                    </div>
                    <div className={styles.history_block}>
                        <div className={styles.history_first_part}>
                            <div className={styles.history_num_text}>
                                <div className={styles.history_num}>
                                    1993
                                </div>
                                <div className={classnames(s.main_text, styles.history_text)}>
                                    Mondial Private Bank (IBU) Ltd. is a licensed International Bank registered in the Turkish Republic of Northern Cyprus. 
                                    Mondial Private Bank (IBU) Ltd. Incorporated in 1993 and licensed by Central Bank of North Cyprus in 1993 under the 
                                    Banking Secrecy Act and Cyprus Banking Law No. 48 - 1990, and provides secure financial services and asset and portfolio 
                                    protection recognized internationally.
                                </div>
                            </div>
                            <span className={s.main_text}>Mondial Private Bank (IBU) Ltd. known as "Mondial Private Bank (IBU) Ltd." has been the first bank to introduce many 
                            developments with the goal of providing the best customer service for both individual and corporate clients.
                            Mondial Private Bank (IBU) Ltd. providing a full range of financial services for personal and corporate clients including 
                            Trade Financing, Investment Banking, Merchant Banking, Forex exchange operations, International Funds Management and 
                            Derivative Financial Market Services as well as other conventional banking services. We offers trust services to our 
                            clients and supply a perfect role on tax saving and planning with the offshore secret knowledge.</span>
                        </div>
                        <div className={styles.history_second_part}>
                            <div className={styles.history_num_text}>
                                <div className={styles.history_num}>
                                    2007
                                </div>
                                <div className={classnames(s.main_text, styles.history_text)}>
                                    Mondial Private Bank (IBU) Ltd. has taken steady steps to become a leader among International Banks. In 2007, 
                                    particularly after introducing our new product, MasterCard and Visa we enjoyed a great demand from our customer base.
                                </div>
                            </div>
                            <span className={s.main_text}>Our international staff from multicultural backgrounds are here to assist you every step of the way towards 
                            financial liberty through modern International banking. Every client is important and receives individual 
                            attention from their personal accounts manager.</span>
                        </div>
                    </div>

                    <div className={styles.history_block_mobile}>
                        <div className={styles.history_first_part}>
                            <div className={styles.history_num_text}>
                                <div className={styles.history_num}>
                                    1993
                                </div>
                                <div className={classnames(s.main_text, styles.history_text)}>
                                    Mondial Private Bank (IBU) Ltd. is a licensed International Bank registered in the Turkish Republic of Northern Cyprus.
                                </div>
                            </div>
                            <div className={s.main_text}>
                                Incorporated in 1993 and licensed by Central Bank of North Cyprus in 1993 under the Banking Secrecy Act and Cyprus Banking 
                                Law No. 48 - 1990, and provides secure financial services and asset and portfolio protection recognized internationally.
                            </div>
                            <div className={s.main_text}>
                                Mondial Private Bank (IBU) Ltd. known as "Mondial Private Bank (IBU) Ltd." has been the first bank to introduce many 
                                developments with the goal of providing the best customer service for both individual and corporate clients.
                            </div>
                            <div className={s.main_text}>
                                Mondial Private Bank (IBU) Ltd. providing a full range of financial services for personal and corporate clients 
                                including , Trade Financing, Investment Banking, Merchant Banking, Forex exchange operations, International Funds 
                                Management and Derivative Financial Market Services as well as other conventional banking services. We offers trust 
                                services to our clients and supply a perfect role on tax saving and planning with the offshore secret knowledge.
                            </div>
                        </div>
                        <div className={styles.history_second_part}>
                            <div className={styles.history_num_text}>
                                <div className={styles.history_num}>
                                    2007
                                </div>
                                <div className={classnames(s.main_text, styles.history_text)}>
                                    Mondial Private Bank (IBU) Ltd. has taken steady steps to become a leader among International Banks. 
                                </div>
                            </div>
                            <span className={s.main_text}>
                                In 2007, particularly after introducing our new product, MasterCard and Visa we enjoyed a great demand from our customer base.
                                <br/>
                                Our international staff from multicultural backgrounds are here to assist you every step of the way towards financial 
                                liberty through modern International banking. Every client is important and receives individual attention from their 
                                personal accounts manager.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default BankHistory;

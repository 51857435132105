import React, {useState} from 'react';
import s from "../BankTransfer/BankTransfer.module.css";
import ToAnIberbancoStepOne from "./ToAnIberbancoStepOne/ToAnIberbancoStepOne";
import ToAnIberbancoStepTwo from "./ToAnIberbancoStepTwo/ToAnIberbancoStepTwo";

const ToAnIberbanco = () => {
    const [current, setCurrent] = useState(0);
    const [ourTransferData, setOurTransferData] = useState({})

    const steps = [
        {
            content: <ToAnIberbancoStepOne setCurrent={setCurrent}
                                           setTransferData={setOurTransferData}
            />
        },
        {
            content: <ToAnIberbancoStepTwo setCurrent={setCurrent}
                                           current={current}
                                           transferData={ourTransferData}
            />
        },
    ];

    return (
        <div className={s.wrapper}>
            {steps[current].content}
        </div>
    );
};

export default ToAnIberbanco;

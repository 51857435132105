import React from 'react';
import styles from './Investments.module.css'
import s from "../../../styles/styles.module.css"
import classnames from "classnames";
import {motion} from 'framer-motion';

const Investments = () => {

    const services = [
        'Portfolio Management',
        'Direct Equity and Bond Investments',
        'Structured Products',
    ]

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={classnames(s.wrapper, s.main_block)}>
                <div className={s.container}>
                    <div className={s.title}>
                        <div className={s.main_title}>
                            Investments
                        </div>
                        <div className={s.back_title}>
                            Investments
                        </div>
                    </div>
                    <div className={styles.subtitle}>
                        Your Vision, Our Expertise
                    </div>
                    <div className={styles.invest_text}>
                        <div className={styles.invest_text_body}>
                            At Mondial Private Bank, investing is about realizing your financial aspirations. Our investment division offers 
                            diverse portfolio options, asset management, and bespoke investment solutions guided by market insights and expertise.
                        </div>
                    </div>
                    <div className={styles.invest_picture}></div>
                    <div className={s.title}>
                        <div className={s.main_title}>
                            key services
                        </div>
                        <div className={s.back_title}>
                            key services
                        </div>
                    </div>
                    <div className={styles.subtitle}>
                        Begin your investment journey with us and turn your goals into achievements
                    </div>
                    <div className={styles.services}>
                        {/* <div className={styles.services_content}>
                            
                        </div> */}
                        {services.map(item => {
                            return <div className={styles.services_item}>
                                        {item}
                                   </div>
                        })}
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Investments;

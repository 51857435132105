export const PrepareDataForIberbancoTransaction = (values: any) => {
     return {
          accNumberFrom: values.accNumberFrom,
          accNumberTo: values.accNumberTo,
          amount: Number(values.amount),
          email: values.email,
          paymentDate: values.paymentDate,
          reference: values.reference,
          templateName: values.templateName,
          type: values.type
     }
}

import {
    ABOUT, 
    PERSONAL, 
    CORPORATE,
    INVESTMENTS, 
    CONTACT_US, 
    BANKS_HISTORY,
    PRIVACY_POLICY,
    KYC_AML_POLICY,
    TERMS_AND_CONDITIONS
} from "./RoutesConstants";

import About from "../pages/PublicPages/About/About";
import Personal from "../pages/PublicPages/Personal/Personal"
import Corporate from "../pages/PublicPages/Corporate/Corporate";
import Investments from "../pages/PublicPages/Investments/Investments";
import Contacts from "../pages/PublicPages/Contacts/Contacts";
import BankHistory from "../pages/PublicPages/BankHistory/BankHistory";
import PrivacyPolicy from "../pages/PublicPages/PrivacyPolicy/PrivacyPolicy";
import KYCAMLPolicy from "../pages/PublicPages/KYCAMLPolicy/KYCAMLPolicy";
import TermsAndConditions from "../pages/PublicPages/TermsAndConditions/TermsAndConditions";

export const publicRoutes = [
    {
        name: 'About',
        path: ABOUT,
        element: <About />
    },
    {
        name: 'Personal',
        path: PERSONAL,
        element: <Personal />
    },
    {
        name: 'Corporate',
        path: CORPORATE,
        element: <Corporate />
    },
    {
        name: 'Investments',
        path: INVESTMENTS,
        element: <Investments />
    },
    {
        name: 'Contact us',
        path: CONTACT_US,
        element: <Contacts />
    },
    {
        name: "Bank's History",
        path: BANKS_HISTORY,
        element: <BankHistory />
    },
    {
        name: 'Privacy Policy',
        path: PRIVACY_POLICY,
        element: <PrivacyPolicy />
    },
    {
        name: 'KYC/AML Policy',
        path: KYC_AML_POLICY,
        element: <KYCAMLPolicy />
    },
    {
        name: 'Terms and Conditions',
        path: TERMS_AND_CONDITIONS,
        element: <TermsAndConditions />
    },
]

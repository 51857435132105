import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./BankTransactionTemplate.module.css";
import {DatePicker, Popover} from "antd";
import info from "../../../assets/newImg/personalAccount/information.svg";
import classnames from "classnames";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../../assets/newImg/personalAccount/back.svg";
import MyBtn from "../../ui/MyBtn/MyBtn";
import CustomDropdown from "../../ui/CustomDropdown/CustomDropdown";
import CustomCheckbox from "../../ui/customCheckbox/CustomCheckbox";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import CustomDropdownForAccounts from "../../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import MyTextarea from "../../ui/MyTextarea/MyTextarea";
import MyInput from "../../ui/MyInput/MyInput";
import {createTemplate, getTemplateByIdThunk, SendTransferThunk} from "../../../store/reducers/ActionCreators";
import {PrepareDataForPaymentTemplate} from "../../../helpers/PrepareDataForPaymentTemplate";
import {ITemplate} from "../../../types/interfaces";
import {RangePickerProps} from "antd/es/date-picker";
import dayjs from "dayjs";
import {myDate} from "../../../helpers/CalculateDate";
import CustomModal from "../../ui/CustomModal/CustomModal";
import {
    azaCountries, bankAccountTypesTED,
    bankAccountTypesUS, bankCodeListEGPBank,
    bankCodeListGhanaGHSBank, bankCodeListKESBank,
    bankCodeListNgnBank,
    bankCodeListNigeriaUSDBank,
    bankCodeListTEDPayments,
    bankCodeListZARBank,
    branchCodeListBWPBank,
    branchCodeListUGXBank, branchCodeListZMWBank,
    countries, idTypesKenya,
    idTypesMorocco,
    languages,
    mobileProvidersGhana,
    mobileProvidersGuinea, mobileProvidersKenya,
    mobileProvidersUganda,
    mobileProvidersXAF, otherBank,
    paymentRegions,
    pixKeyTypesBrazil,
    transferReasons,
    xofCashCountries,
    xofMobileCountries
} from "../../../Constants/StaticData";
import {
    getValidationSchemaForTemplates
} from "../../BankTransferFormPersonal/FormHelpersForTemplates/FormHelpersForTemplates";
import {
    getItemsPaymentType, getMobileProvider,
    getPhoneCode,
    getValidationSchema, PaymentTypeHelper
} from "../../BankTransferFormPersonal/FormHelpers/FormHelpers";
import CustomDropdownForAzaTransactions
    from "../../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import {
    getAccountType,
    getBankCode,
    getBranchCode, getIdType,
    getProvider,
    getRecipientCountry,
    getTransferReason
} from "./FormHelpers/FormHelpers";
import PhoneCodesInput from "../../ui/PhoneCodesInput/PhoneCodesInput";
import {PrepareDataForAzaTransaction} from "../../../helpers/PrepareDataForAzaTranzactions";
import {setDisabled} from "../../../store/reducers/UserSlice";

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
    setTransferData?: Dispatch<SetStateAction<any>>
    id?: string | number;
    templateName: string,
    setPurpose: Dispatch<SetStateAction<string>>
}

const CurrencyHelper = (props: any) => {
    useEffect(() => {
        props.accountsList.map((account: any) => {
            if (account.number === props.accNumberFrom) {
                props.setCurrency(account.currency)
            }
        })
    }, [props.accountsList, props.accNumberFrom])

    return null
}

const BankTransactionTemplate = (props: PropsType) => {
    const navigate = useNavigate()

    const [paymentType, setPaymentType] = useState("SHA")
    const [paymentBankTemplate, setPaymentBankTemplate] = useState<ITemplate>()
    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ?  ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate()),
    })
    const [isShowSuccessModal, setIsShowSuccessModal] = useState(false)

    const [code, setCode] = useState({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    })
    const [paymentTypeByRegion, setPaymentTypeByRegion] = useState('')

    const accountsList = useAppSelector(state => state.userReducer.accountsList)
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const dispatch = useAppDispatch()
    const [isMobile, setIsMobile] = useState(false)
    const [currency ,setCurrency] = useState('')

    useEffect(() => {
        if (props.id !== 'create') {
            dispatch(getTemplateByIdThunk(token, props.id))
                .then((res) => {
                    if (res.data.type === 'Intra Transaction') {
                        props.setPurpose('Transfer to Iberbanco User')
                    } else {
                        setPaymentBankTemplate(res.data)
                    }
                })
        }
    }, [])

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth])

    const currencyArr = [
        'CAD',
        'EUR',
        'GBP',
        'USD',
    ]

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf('day');
    };

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 800,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 52,
        width: isMobile ? '100%' : 311,
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    const dateFormat = 'YYYY-MM-DD';
    const selectedBankCode = getBankCode(paymentBankTemplate?.recipientDetails.paymentMethod, paymentBankTemplate?.recipientDetails.bankCode);

    const selectedBranchCode = getBranchCode(paymentBankTemplate?.recipientDetails.paymentMethod, paymentBankTemplate?.recipientDetails.branchCode);

    const selectedAccountType = getAccountType(paymentBankTemplate?.recipientDetails.paymentMethod, paymentBankTemplate?.recipientDetails.bankAccountType);

    const selectedRecipientCountry = getRecipientCountry(paymentBankTemplate?.recipientDetails.paymentMethod, paymentBankTemplate?.recipientDetails.country);

    const selectedMobileProvider = getProvider(paymentBankTemplate?.recipientDetails.paymentMethod, paymentBankTemplate?.recipientDetails.mobileProvider, selectedRecipientCountry?.name);

    const selectedTransferReason = getTransferReason(paymentBankTemplate?.recipientDetails.transferReason);

    const selectedIdType = getIdType(paymentBankTemplate?.recipientDetails.paymentMethod, paymentBankTemplate?.recipientDetails.identityCardType);

    const selectedPIX = pixKeyTypesBrazil.find((bankCode) => bankCode.value === paymentBankTemplate?.recipientDetails.pixKeyType) || otherBank;


    //to show phone number without code
    const mobCountry = paymentBankTemplate?.recipientDetails.recipientsBankCountry === "WAEMU Region\/XOF"
        ? selectedRecipientCountry?.name
        : paymentBankTemplate?.recipientDetails.recipientsBankCountry;
    const plainPhoneNumber = (paymentBankTemplate?.recipientDetails.phoneNumber || '')
        .replace(`+${getPhoneCode(mobCountry || '', null).ph}`, '');

    const myInitValues = {
        payerAccount: paymentBankTemplate?.account,
        paymentRegions: paymentBankTemplate?.paymentRegion,
        fullName: paymentBankTemplate?.recipientDetails?.fullName,
        firstName: paymentBankTemplate?.recipientDetails?.firstName,
        lastName: paymentBankTemplate?.recipientDetails?.lastName,
        middleName: paymentBankTemplate?.recipientDetails?.middleName,
        paymentTypeByRegionForm: paymentBankTemplate?.recipientDetails.paymentMethod,
        recipientsAddress: paymentBankTemplate?.recipientDetails.recipientsAddress,
        recipientsAccountNumber: paymentBankTemplate?.recipientDetails.recipientsAccountNumber,
        recipientsSwiftBic: paymentBankTemplate?.recipientDetails.recipientsSwiftBic,
        recipientsBankCountry: paymentBankTemplate?.recipientDetails.recipientsBankCountry  ?? '',
        recipientsBankAddress: paymentBankTemplate?.recipientDetails.recipientsBankAddress,
        recipientsBank: paymentBankTemplate?.recipientDetails.recipientsBank,
        recipientsIban: paymentBankTemplate?.recipientDetails.recipientsIban,
        bankCode: {
            name: selectedBankCode?.name,
            value: selectedBankCode?.value,
        },
        bankAccount: paymentBankTemplate?.recipientDetails?.bankAccount,
        bankAccountType: {
            name: selectedAccountType?.name,
            value: selectedAccountType?.value
        },
        email: paymentBankTemplate?.email,
        emailLanguage: 'EN',
        transferCurrency: 'USD',
        purpose: '',
        reference: '',
        type: '2',
        paymentDate: date.from,
        amount: '',
        phoneNumber: plainPhoneNumber,
        recipientCountry: {
            name: selectedRecipientCountry?.name,
            value: selectedRecipientCountry?.value
        },
        mobileProvider: {
            name: selectedMobileProvider?.name,
            value: selectedMobileProvider?.value
        },
        street: paymentBankTemplate?.recipientDetails?.street,
        city: paymentBankTemplate?.recipientDetails?.city,
        postalCode: paymentBankTemplate?.recipientDetails?.postalCode,
        identityCardId: paymentBankTemplate?.recipientDetails?.identityCardId,
        identityCardType:  {
            name: selectedIdType?.name,
            value: selectedIdType?.value
        },
        transferReason: {
            name: selectedTransferReason?.name,
            value: selectedTransferReason?.value
        },
        routingNumber: paymentBankTemplate?.recipientDetails?.routingNumber,
        cashProvider: paymentBankTemplate?.recipientDetails?.cashProvider,
        branchCode: {
            name: selectedBranchCode?.name,
            value: selectedBranchCode?.value
        },
        branchCodeTED: paymentBankTemplate?.recipientDetails?.branchCode,
        pixKeyType: {
            name: selectedPIX?.name,
            value: selectedPIX?.value
        },
        pixKeyValue: paymentBankTemplate?.recipientDetails?.pixKeyValue,
        relationshipToSender: paymentBankTemplate?.recipientDetails?.relationshipToSender,
    }

/*
    const validationSchema = yup.object({
        payerAccount: yup.string().required('Required field'),
        recipientsAccountNumber: yup.string().required('Required field').max(40, 'max 40 symbols'),
        fullName: yup.string().required('Required field').max(90, 'max 90 symbols'),
        recipientsBank: yup.string().required('Required field').max(60, 'max 60 symbols'),
        recipientsBankCountry: yup.string().required('Required field').max(100, 'max 100 symbols'),
        recipientsBankAddress: yup.string().required('Required field').max(100, 'max 100 symbols'),
        reference: yup.string().required('Required field').max(100, 'max 100 symbols'),
        purpose: yup.string().required('Required field').max(100, 'max 100 symbols'),
        recipientsSwiftBic: yup.string().required('Required field').max(12, 'max 12 symbols'),
        recipientsAddress: yup.string().required('Required field').max(100, 'max 100 symbols'),
        email: yup.string().email("invalid format"),
        amount: yup.string()
            .matches(/^[0-9/,]+$/, "Must be only digits")
            .required('Required field')
            .max(10, 'max 10 digits')
            .test(
                'no-leading-zero',
                'invalid format',
                (value, context) => {

                    const tempArr = context.originalValue.split('')

                    const onlyZero = tempArr.every((item:string) => {
                        if (item === '0') {
                            return true
                        }
                    })

                    if (context.originalValue && onlyZero) {
                        return false
                    } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                        return false
                    }
                    return true
                }
            )
    })
*/
    const closeAndRedirect = () => {
        setIsShowSuccessModal(false)
        navigate(-1)
    }

    return (
        <div className={s.form_wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={myInitValues}
                  //  validationSchema={props.id !== 'create' ? validationSchema : ''}
                    validationSchema={props.id === "create"
                        ? getValidationSchemaForTemplates(paymentTypeByRegion ?? '')
                        : getValidationSchema(paymentBankTemplate?.recipientDetails.paymentMethod ?? '', '') }
                    onSubmit={(values) => {
                        dispatch(setDisabled(true))
                        const tempData = {
                            ...values,
                            paymentType,
                            phoneNumber: values.phoneNumber !== "" ? `+${code.ph}${values.phoneNumber}` : "",
                            templateName: props.templateName,
                            transferCurrency: currency,
                            paymentDate: date.from,
                        }

                        if (props.id === 'create') {
                            const preparedData = PrepareDataForPaymentTemplate(tempData)
                            dispatch(createTemplate(token, preparedData))
                                .then((res) => {
                                    if (res.data === 'Created') {
                                        setIsShowSuccessModal(true)
                                    }
                                })
                                .finally(() => {
                                    dispatch(setDisabled(false))
                                })
                        } else {
                                const secondTempData = {
                                    ...tempData,
                                    type: 'international'
                                }
                                const preparedData = PrepareDataForAzaTransaction(secondTempData)
                                dispatch(SendTransferThunk(token, preparedData))
                                    .then((res) => {
                                        if (res.data === 'Created') {
                                            navigate(-1)
                                        } else {
                                            console.error(res.data)
                                        }
                                    })
                                    .finally(() => {
                                        dispatch(setDisabled(false))
                                    })
                            }
                        }}
                        >

                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit}>

                            <CustomModal setIsModalOpen={setIsShowSuccessModal} isModalOpen={isShowSuccessModal}>
                                <div>
                                    <div className={s.success_title}>Success!</div>
                                    <span className={s.success_subtitle}>Your template was successfully created.</span>
                                    <div className={s.success_button_wrapper}>
                                        <div className={s.btn_block}>
                                            <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                                        </div>
                                    </div>
                                </div>
                            </CustomModal>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Payer account
                                        <Popover content={"Please select the account you wish to make a transfer from."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdownForAccounts
                                        items={accountsList}
                                        placeholder={"Please select the account"}
                                        id="payerAccount"
                                        name="payerAccount"
                                        isError={errors.payerAccount}
                                        touched={touched.payerAccount}
                                    />
                                    {errors.payerAccount && touched.payerAccount &&
                                        <div className={s.error_message}>{errors.payerAccount}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Payment region
                                        <Popover content={"Please select the account you wish to make a transfer from."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdown
                                        customId="paymentRegions"
                                        items={paymentRegions}
                                        placeholder={"Please select the region"}
                                        id="paymentRegions"
                                        name="paymentRegions"
                                        isError={errors.paymentRegions}
                                        touched={touched.paymentRegions}
                                    />
                                    {errors.paymentRegions && touched.paymentRegions &&
                                        <div className={s.error_message}>{errors.paymentRegions}</div>}
                                </div>
                            </div>

                            {
                                values.paymentRegions === "PAN AFRICA" &&
                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * RECIPIENT’S BANK COUNTRY/REGION
                                        </div>
                                        <CustomDropdown
                                            items={azaCountries}
                                            placeholder={"Country/Region"}
                                            id="recipientsBankCountry"
                                            name="recipientsBankCountry"
                                            isError={errors.recipientsBankCountry}
                                            touched={touched.recipientsBankCountry}
                                            resetAnotherField={true}
                                            onChange={() => {}}
                                        />
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * PAYMENT METHOD
                                        </div>
                                        <CustomDropdown
                                            customId="paymentTypeByRegionForm"
                                            items={getItemsPaymentType(values.recipientsBankCountry)}
                                            placeholder={"Please select the payment method"}
                                            id="paymentTypeByRegionForm"
                                            name="paymentTypeByRegionForm"
                                            isError={errors.paymentTypeByRegionForm}
                                            touched={touched.paymentTypeByRegionForm}
                                            setItem={setPaymentTypeByRegion}
                                        />
                                    </div>
                                </div>
                            }

                            {/*initial form*/}
                            {
                                values.paymentRegions !== "PAN AFRICA" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Full Name
                                                <Popover
                                                    content={"Enter the name and surname of the recipient or the name of " +
                                                        "the legal person."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName && touched.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * recipient’s Address
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress && touched.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN / Account number
                                                <Popover
                                                    content={"Enter the recipient’s bank account number or IBAN account."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SWIFT / BIC code
                                                <Popover
                                                    content={"A SWIFT code is an international bank code that identifies " +
                                                        "particular banks worldwide. It's also known as a Bank Identifier Code (BIC). " +
                                                        "A SWIFT code consists of 8 or 11 characters."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsSwiftBic"}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * recipient’s bank country
                                            </div>
                                            <CustomDropdown
                                                items={countries}
                                                id="recipientsBankCountry"
                                                name="recipientsBankCountry"
                                                isError={errors.recipientsBankCountry}
                                                touched={touched.recipientsBankCountry}
                                            />
                                            {errors.recipientsBankCountry && touched.recipientsBankCountry &&
                                                <div className={s.error_message}>{errors.recipientsBankCountry}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * recipient’s bank
                                            </div>
                                            <MyInput id={"recipientsBank"}
                                                     name={"recipientsBank"}
                                                     touched={touched.recipientsBank}
                                                     value={values.recipientsBank}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsBank}
                                            />
                                            {errors.recipientsBank && touched.recipientsBank &&
                                                <div className={s.error_message}>{errors.recipientsBank}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                * recipient’s bank address
                                            </div>
                                            <MyInput
                                                id={"recipientsBankAddress"}
                                                name={"recipientsBankAddress"}
                                                touched={touched.recipientsBankAddress}
                                                value={values.recipientsBankAddress}
                                                onChange={handleChange}
                                                isError={errors.recipientsBankAddress}
                                            />
                                            {errors.recipientsBankAddress && touched.recipientsBankAddress &&
                                                <div className={s.error_message}>{errors.recipientsBankAddress}</div>}
                                        </div>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    Email
                                                    <Popover
                                                        content={"Please indicate the recipient’s email address and language, " +
                                                            "if you would like us to send them an electronically signed automatic email " +
                                                            "about the performed transfer."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Inter',
                                                            width: '280px',
                                                            padding: '13px 16px'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"email"}
                                                         name={"email"}
                                                         touched={touched.email}
                                                         value={values.email}
                                                         onChange={handleChange}
                                                         isError={errors.email}
                                                />
                                            </div>

                                            <div className={s.languages_dropdown}>
                                                <CustomDropdown
                                                    items={languages}
                                                    id="emailLanguage"
                                                    name="emailLanguage"
                                                    isError={errors.emailLanguage}
                                                    touched={touched.emailLanguage}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA NGN::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "NGN::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"bankCode"}
                                                name={"bankCode"}
                                                touched={touched.bankCode}
                                                isError={errors.bankCode && errors.bankCode.name}
                                                items={bankCodeListNgnBank}
                                            />
                                            {errors.bankCode && touched.bankCode &&
                                                <div className={s.error_message}>{errors.bankCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA NGN::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "NGN::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>

                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA NigeriaUSD::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "NigeriaUSD::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"bankCode"}
                                                name={"bankCode"}
                                                touched={touched.bankCode}
                                                isError={errors.bankCode && errors.bankCode.name}
                                                items={bankCodeListNigeriaUSDBank}
                                            />
                                            {errors.bankCode && touched.bankCode &&
                                                <div className={s.error_message}>{errors.bankCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * PHONE NUMBER
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA NigeriaUSD::Cash*/}
                            {
                                (values.paymentTypeByRegionForm === "NigeriaUSD::Cash" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * PHONE NUMBER
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA GHS::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "GHS::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"bankCode"}
                                                name={"bankCode"}
                                                touched={touched.bankCode}
                                                isError={errors.bankCode && errors.bankCode.name}
                                                items={bankCodeListGhanaGHSBank}
                                            />
                                            {errors.bankCode && touched.bankCode &&
                                                <div className={s.error_message}>{errors.bankCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>

                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA GHS::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "GHS::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_block}>
                                                    <div className={s.input_label}>
                                                        * Phone number
                                                    </div>
                                                    <PhoneCodesInput
                                                        initCode={code}
                                                        setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                        id='phoneNumber'
                                                        name='phoneNumber'
                                                        onChange={handleChange}
                                                        value={values.phoneNumber}
                                                        error={errors.phoneNumber}
                                                        touched={touched.phoneNumber}
                                                        phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                        selectable = {false}
                                                    />
                                                    {errors.phoneNumber &&
                                                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                                                </div>
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * MOBILE PROVIDER
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"mobileProvider"}
                                                name={"mobileProvider"}
                                                touched={touched.mobileProvider}
                                                isError={errors.mobileProvider && errors.mobileProvider.name}
                                                items={mobileProvidersGhana}
                                            />
                                            {errors.mobileProvider && touched.mobileProvider &&
                                                <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA GHS::Cash*/}
                            {
                                (values.paymentTypeByRegionForm === "GHS::Cash" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_block}>
                                                    <div className={s.input_label}>
                                                        * Phone number
                                                    </div>
                                                    <PhoneCodesInput
                                                        initCode={code}
                                                        setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                        id='phoneNumber'
                                                        name='phoneNumber'
                                                        onChange={handleChange}
                                                        value={values.phoneNumber}
                                                        error={errors.phoneNumber}
                                                        touched={touched.phoneNumber}
                                                        phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                        selectable = {false}
                                                    />
                                                    {errors.phoneNumber &&
                                                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                                                </div>
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA UGX::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "UGX::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * STREET
                                            </div>
                                            <MyInput id={"street"}
                                                     name={"street"}
                                                     touched={touched.street}
                                                     value={values.street}
                                                     onChange={handleChange}
                                                     isError={errors.street}
                                            />
                                            {errors.street && touched.street &&
                                                <div className={s.error_message}>{errors.street}</div>}

                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * CITY
                                            </div>
                                            <MyInput id={"city"}
                                                     name={"city"}
                                                     touched={touched.city}
                                                     value={values.city}
                                                     onChange={handleChange}
                                                     isError={errors.city}
                                            />
                                            {errors.city && touched.city &&
                                                <div className={s.error_message}>{errors.city}</div>}

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                POSTAL CODE
                                            </div>
                                            <MyInput id={"postalCode"}
                                                     name={"postalCode"}
                                                     touched={touched.postalCode}
                                                     value={values.postalCode}
                                                     onChange={handleChange}
                                                     isError={errors.postalCode}
                                            />
                                            {errors.postalCode && touched.postalCode &&
                                                <div className={s.error_message}>{errors.postalCode}</div>}

                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * IDENTITY CARD ID
                                            </div>
                                            <MyInput id={"identityCardId"}
                                                     name={"identityCardId"}
                                                     touched={touched.identityCardId}
                                                     value={values.identityCardId}
                                                     onChange={handleChange}
                                                     isError={errors.identityCardId}
                                            />
                                            {errors.identityCardId && touched.identityCardId &&
                                                <div className={s.error_message}>{errors.identityCardId}</div>}

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}

                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * BRANCH CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"branchCode"}
                                                name={"branchCode"}
                                                touched={touched.branchCode}
                                                isError={errors.branchCode && errors.branchCode.name}
                                                items={branchCodeListUGXBank}
                                            />
                                            {errors.branchCode && touched.branchCode &&
                                                <div className={s.error_message}>{errors.branchCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * TRANSFER REASON
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"transferReason"}
                                                name={"transferReason"}
                                                touched={touched.transferReason}
                                                isError={errors.transferReason && errors.transferReason.name}
                                                items={transferReasons}
                                            />
                                            {errors.transferReason && touched.transferReason &&
                                                <div className={s.error_message}>{errors.transferReason.name}</div>}
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA UGX::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "UGX::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * MOBILE PROVIDER
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"mobileProvider"}
                                                    name={"mobileProvider"}
                                                    touched={touched.mobileProvider}
                                                    isError={errors.mobileProvider && errors.mobileProvider.name}
                                                    items={mobileProvidersUganda}
                                                />
                                                {errors.mobileProvider && touched.mobileProvider &&
                                                    <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA EUR::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "EUR::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK NAME
                                                </div>
                                                <MyInput id={"recipientsBank"}
                                                         name={"recipientsBank"}
                                                         touched={touched.recipientsBank}
                                                         value={values.recipientsBank}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsBank}
                                                />
                                                {errors.recipientsBank && touched.recipientsBank &&
                                                    <div className={s.error_message}>{errors.recipientsBank}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BIC CODE
                                                </div>
                                                <MyInput id={"recipientsSwiftBic"}
                                                         name={"recipientsSwiftBic"}
                                                         touched={touched.recipientsSwiftBic}
                                                         value={values.recipientsSwiftBic}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsSwiftBic}
                                                />
                                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                    <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA GBP::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "GBP::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK NAME
                                                </div>
                                                <MyInput id={"recipientsBank"}
                                                         name={"recipientsBank"}
                                                         touched={touched.recipientsBank}
                                                         value={values.recipientsBank}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsBank}
                                                />
                                                {errors.recipientsBank && touched.recipientsBank &&
                                                    <div className={s.error_message}>{errors.recipientsBank}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BIC CODE
                                                </div>
                                                <MyInput id={"recipientsSwiftBic"}
                                                         name={"recipientsSwiftBic"}
                                                         touched={touched.recipientsSwiftBic}
                                                         value={values.recipientsSwiftBic}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsSwiftBic}
                                                />
                                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                    <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA USD::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "USD::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT
                                                </div>
                                                <MyInput id={"bankAccount"}
                                                         name={"bankAccount"}
                                                         touched={touched.bankAccount}
                                                         value={values.bankAccount}
                                                         onChange={handleChange}
                                                         isError={errors.bankAccount}
                                                />
                                                {errors.bankAccount && touched.bankAccount &&
                                                    <div className={s.error_message}>{errors.bankAccount}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankAccountType"}
                                                    name={"bankAccountType"}
                                                    touched={touched.bankAccountType}
                                                    isError={errors.bankAccountType && errors.bankAccountType.name}
                                                    items={bankAccountTypesUS}
                                                />
                                                {errors.bankAccountType && touched.bankAccountType &&
                                                    <div className={s.error_message}>{errors.bankAccountType.name}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK NAME
                                                </div>
                                                <MyInput id={"recipientsBank"}
                                                         name={"recipientsBank"}
                                                         touched={touched.recipientsBank}
                                                         value={values.recipientsBank}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsBank}
                                                />
                                                {errors.recipientsBank && touched.recipientsBank &&
                                                    <div className={s.error_message}>{errors.recipientsBank}</div>}
                                            </div>

                                        </div>
                                    </div>


                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * ROUTING NUMBER
                                                </div>
                                                <MyInput id={"routingNumber"}
                                                         name={"routingNumber"}
                                                         touched={touched.routingNumber}
                                                         value={values.routingNumber}
                                                         onChange={handleChange}
                                                         isError={errors.routingNumber}
                                                         placeholder={"9 digits"}
                                                />
                                                {errors.routingNumber && touched.routingNumber &&
                                                    <div className={s.error_message}>{errors.routingNumber}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * SWIFT CODE
                                                </div>
                                                <MyInput id={"recipientsSwiftBic"}
                                                         name={"recipientsSwiftBic"}
                                                         touched={touched.recipientsSwiftBic}
                                                         value={values.recipientsSwiftBic}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsSwiftBic}
                                                />
                                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                    <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA MAD::Cash*/}
                            {
                                (values.paymentTypeByRegionForm === "MAD::Cash" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTITY CARD TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"identityCardType"}
                                                    name={"identityCardType"}
                                                    touched={touched.identityCardType}
                                                    isError={errors.identityCardType && errors.identityCardType.name}
                                                    items={idTypesMorocco}
                                                />
                                                {errors.identityCardType && touched.identityCardType &&
                                                    <div className={s.error_message}>{errors.identityCardType.name}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTITY CARD ID
                                                </div>
                                                <MyInput id={"identityCardId"}
                                                         name={"identityCardId"}
                                                         touched={touched.identityCardId}
                                                         value={values.identityCardId}
                                                         onChange={handleChange}
                                                         isError={errors.identityCardId}
                                                />
                                                {errors.identityCardId && touched.identityCardId &&
                                                    <div className={s.error_message}>{errors.identityCardId}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XOF::Cash*/}
                            {
                                (values.paymentTypeByRegionForm === "XOF::Cash" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * COUNTRY
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"recipientCountry"}
                                                    name={"recipientCountry"}
                                                    touched={touched.recipientCountry}
                                                    isError={errors.recipientCountry && errors.recipientCountry.name}
                                                    items={xofCashCountries}
                                                />
                                                {errors.recipientCountry && touched.recipientCountry &&
                                                    <div className={s.error_message}>{errors.recipientCountry.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode('XOF', values.recipientCountry?.name))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode('XOF', values.recipientCountry?.name)]}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>

                                        </div>

                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * CASH PROVIDER
                                                </div>
                                                <MyInput id={"cashProvider"}
                                                         name={"cashProvider"}
                                                         touched={touched.cashProvider}
                                                         value={"wizall"}
                                                         onChange={handleChange}
                                                         isError={errors.cashProvider}
                                                />
                                                {errors.cashProvider && touched.cashProvider &&
                                                    <div className={s.error_message}>{errors.cashProvider}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XOF::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "XOF::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COUNTRY
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"recipientCountry"}
                                                name={"recipientCountry"}
                                                touched={touched.recipientCountry}
                                                isError={errors.recipientCountry && errors.recipientCountry.name}
                                                items={xofMobileCountries}
                                            />
                                            {errors.recipientCountry && touched.recipientCountry &&
                                                <div className={s.error_message}>{errors.recipientCountry.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * MOBILE PROVIDER
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"mobileProvider"}
                                                name={"mobileProvider"}
                                                touched={touched.mobileProvider}
                                                isError={errors.mobileProvider && errors.mobileProvider.name}
                                                items={getMobileProvider(values.recipientCountry?.name)}
                                            />
                                            {errors.mobileProvider && touched.mobileProvider &&
                                                <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode('XOF', values.recipientCountry?.name))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode('XOF', values.recipientCountry?.name)]}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XOF::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "XOF::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                         placeholder={"AA123 12345 123456789012 12"}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XAF::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "XAF::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                         placeholder={"AA123 12345 123456789012 12"}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XAF::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "XAF::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * MOBILE PROVIDER
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"mobileProvider"}
                                                    name={"mobileProvider"}
                                                    touched={touched.mobileProvider}
                                                    isError={errors.mobileProvider && errors.mobileProvider.name}
                                                    items={mobileProvidersXAF}
                                                />
                                                {errors.mobileProvider && touched.mobileProvider &&
                                                    <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode('Cameroon', null))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode('Cameroon', null)]}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA GNF::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "GNF::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * MOBILE PROVIDER
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"mobileProvider"}
                                                    name={"mobileProvider"}
                                                    touched={touched.mobileProvider}
                                                    isError={errors.mobileProvider && errors.mobileProvider.name}
                                                    items={mobileProvidersGuinea}
                                                />
                                                {errors.mobileProvider && touched.mobileProvider &&
                                                    <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA ZAR::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "ZAR::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    STREET
                                                </div>
                                                <MyInput id={"street"}
                                                         name={"street"}
                                                         touched={touched.street}
                                                         value={values.street}
                                                         onChange={handleChange}
                                                         isError={errors.street}
                                                />
                                                {errors.street && touched.street &&
                                                    <div className={s.error_message}>{errors.street}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                POSTAL CODE
                                            </div>
                                            <MyInput id={"postalCode"}
                                                     name={"postalCode"}
                                                     touched={touched.postalCode}
                                                     value={values.postalCode}
                                                     onChange={handleChange}
                                                     isError={errors.postalCode}
                                            />
                                            {errors.postalCode && touched.postalCode &&
                                                <div className={s.error_message}>{errors.postalCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    CITY
                                                </div>
                                                <MyInput id={"city"}
                                                         name={"city"}
                                                         touched={touched.city}
                                                         value={values.city}
                                                         onChange={handleChange}
                                                         isError={errors.city}
                                                />
                                                {errors.city && touched.city &&
                                                    <div className={s.error_message}>{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * EMAIL
                                            </div>
                                            <MyInput id={"email"}
                                                     name={"email"}
                                                     touched={touched.email}
                                                     value={values.email}
                                                     onChange={handleChange}
                                                     isError={errors.email}
                                            />
                                            {errors.email && touched.email &&
                                                <div className={s.error_message}>{errors.email}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors.bankCode && errors.bankCode.name}
                                                    items={bankCodeListZARBank}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors.bankCode.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * PHONE NUMBER
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                TRANSFER REASON
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"transferReason"}
                                                name={"transferReason"}
                                                touched={touched.transferReason}
                                                isError={errors.transferReason && errors.transferReason.name}
                                                items={transferReasons}
                                            />
                                            {errors.transferReason && touched.transferReason &&
                                                <div className={s.error_message}>{errors.transferReason.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA BWP::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "BWP::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT
                                                </div>
                                                <MyInput id={"recipientsAccountNumber"}
                                                         name={"recipientsAccountNumber"}
                                                         touched={touched.recipientsAccountNumber}
                                                         value={values.recipientsAccountNumber}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsAccountNumber}
                                                />
                                                {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                    <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK NAME
                                            </div>
                                            <MyInput id={"recipientsBank"}
                                                     name={"recipientsBank"}
                                                     touched={touched.recipientsBank}
                                                     value={values.recipientsBank}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsBank}
                                            />
                                            {errors.recipientsBank && touched.recipientsBank &&
                                                <div className={s.error_message}>{errors.recipientsBank}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_block}>
                                                    <div className={s.input_label}>
                                                        * BRANCH CODE
                                                    </div>
                                                    <CustomDropdownForAzaTransactions
                                                        id={"branchCode"}
                                                        name={"branchCode"}
                                                        touched={touched.branchCode}
                                                        isError={errors.branchCode && errors.branchCode.name}
                                                        items={branchCodeListBWPBank}
                                                    />
                                                    {errors.branchCode && touched.branchCode &&
                                                        <div className={s.error_message}>{errors.branchCode.name}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA PIX Payments*/}
                            {
                                (values.paymentTypeByRegionForm === "PIX Payments" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * CITY
                                                </div>
                                                <MyInput id={"city"}
                                                         name={"city"}
                                                         touched={touched.city}
                                                         value={values.city}
                                                         onChange={handleChange}
                                                         isError={errors.city}
                                                />
                                                {errors.city && touched.city &&
                                                    <div className={s.error_message}>{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * POSTAL CODE
                                            </div>
                                            <MyInput id={"postalCode"}
                                                     name={"postalCode"}
                                                     touched={touched.postalCode}
                                                     value={values.postalCode}
                                                     onChange={handleChange}
                                                     isError={errors.postalCode}
                                            />
                                            {errors.postalCode && touched.postalCode &&
                                                <div className={s.error_message}>{errors.postalCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * PIX KEY TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"pixKeyType"}
                                                    name={"pixKeyType"}
                                                    touched={touched.pixKeyType}
                                                    isError={errors.pixKeyType && errors.pixKeyType.name}
                                                    items={pixKeyTypesBrazil}
                                                />
                                                {errors.pixKeyType && touched.pixKeyType &&
                                                    <div className={s.error_message}>{errors.pixKeyType.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * PIX KEY VALUE
                                            </div>
                                            <MyInput id={"pixKeyValue"}
                                                     name={"pixKeyValue"}
                                                     touched={touched.pixKeyValue}
                                                     value={values.pixKeyValue}
                                                     onChange={handleChange}
                                                     isError={errors.pixKeyValue}
                                            />
                                            {errors.pixKeyValue && touched.pixKeyValue &&
                                                <div className={s.error_message}>{errors.pixKeyValue}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTITY CARD ID
                                                </div>
                                                <MyInput id={"identityCardId"}
                                                         name={"identityCardId"}
                                                         touched={touched.identityCardId}
                                                         value={values.identityCardId}
                                                         onChange={handleChange}
                                                         isError={errors.identityCardId}
                                                />
                                                {errors.identityCardId && touched.identityCardId &&
                                                    <div className={s.error_message}>{errors.identityCardId}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * TRANSFER REASON
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"transferReason"}
                                                name={"transferReason"}
                                                touched={touched.transferReason}
                                                isError={errors.transferReason && errors.transferReason.name}
                                                items={transferReasons}
                                            />
                                            {errors.transferReason && touched.transferReason &&
                                                <div className={s.error_message}>{errors.transferReason.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA TED Payments*/}
                            {
                                (values.paymentTypeByRegionForm === "TED Payments" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * CITY
                                                </div>
                                                <MyInput id={"city"}
                                                         name={"city"}
                                                         touched={touched.city}
                                                         value={values.city}
                                                         onChange={handleChange}
                                                         isError={errors.city}
                                                />
                                                {errors.city && touched.city &&
                                                    <div className={s.error_message}>{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * POSTAL CODE
                                            </div>
                                            <MyInput id={"postalCode"}
                                                     name={"postalCode"}
                                                     touched={touched.postalCode}
                                                     value={values.postalCode}
                                                     onChange={handleChange}
                                                     isError={errors.postalCode}
                                            />
                                            {errors.postalCode && touched.postalCode &&
                                                <div className={s.error_message}>{errors.postalCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors.bankCode && errors.bankCode.name}
                                                    items={bankCodeListTEDPayments}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors.bankCode.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BRANCH CODE
                                            </div>
                                            <MyInput id={"branchCodeTED"}
                                                     name={"branchCodeTED"}
                                                     touched={touched.branchCodeTED}
                                                     value={values.branchCodeTED}
                                                     onChange={handleChange}
                                                     isError={errors.branchCodeTED}
                                            />
                                            {errors.branchCodeTED && touched.branchCodeTED &&
                                                <div className={s.error_message}>{errors.branchCodeTED}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT
                                                </div>
                                                <MyInput id={"bankAccount"}
                                                         name={"bankAccount"}
                                                         touched={touched.bankAccount}
                                                         value={values.bankAccount}
                                                         onChange={handleChange}
                                                         isError={errors.bankAccount}
                                                />
                                                {errors.bankAccount && touched.bankAccount &&
                                                    <div className={s.error_message}>{errors.bankAccount}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT TYPE
                                                <Popover
                                                    content={"10 for savings, 20 for current, 30 for payment, 40 " +
                                                        "for salary"}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"bankAccountType"}
                                                name={"bankAccountType"}
                                                touched={touched.bankAccountType}
                                                isError={errors.bankAccountType && errors.bankAccountType.name}
                                                items={bankAccountTypesTED}
                                            />
                                            {errors.bankAccountType && touched.bankAccountType &&
                                                <div className={s.error_message}>{errors.bankAccountType.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IDENTITY CARD ID
                                            </div>
                                            <MyInput id={"identityCardId"}
                                                     name={"identityCardId"}
                                                     touched={touched.identityCardId}
                                                     value={values.identityCardId}
                                                     onChange={handleChange}
                                                     isError={errors.identityCardId}
                                            />
                                            {errors.identityCardId && touched.identityCardId &&
                                                <div className={s.error_message}>{errors.identityCardId}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * TRANSFER REASON
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"transferReason"}
                                                name={"transferReason"}
                                                touched={touched.transferReason}
                                                isError={errors.transferReason && errors.transferReason.name}
                                                items={transferReasons}
                                            />
                                            {errors.transferReason && touched.transferReason &&
                                                <div className={s.error_message}>{errors.transferReason.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA ZMW::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "ZMW::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT
                                                </div>
                                                <MyInput id={"bankAccount"}
                                                         name={"bankAccount"}
                                                         touched={touched.bankAccount}
                                                         value={values.bankAccount}
                                                         onChange={handleChange}
                                                         isError={errors.bankAccount}
                                                />
                                                {errors.bankAccount && touched.bankAccount &&
                                                    <div className={s.error_message}>{errors.bankAccount}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BRANCH CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"branchCode"}
                                                name={"branchCode"}
                                                touched={touched.branchCode}
                                                isError={errors.branchCode && errors.branchCode.name}
                                                items={branchCodeListZMWBank}
                                            />
                                            {errors.branchCode && touched.branchCode &&
                                                <div className={s.error_message}>{errors.branchCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA KES::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "KES::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * STREET
                                                </div>
                                                <MyInput id={"street"}
                                                         name={"street"}
                                                         touched={touched.street}
                                                         value={values.street}
                                                         onChange={handleChange}
                                                         isError={errors.street}
                                                />
                                                {errors.street && touched.street &&
                                                    <div className={s.error_message}>{errors.street}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                CITY
                                            </div>
                                            <MyInput id={"city"}
                                                     name={"city"}
                                                     touched={touched.city}
                                                     value={values.city}
                                                     onChange={handleChange}
                                                     isError={errors.city}
                                            />
                                            {errors.city && touched.city &&
                                                <div className={s.error_message}>{errors.city}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors.bankCode && errors.bankCode.name}
                                                    items={bankCodeListKESBank}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors.bankCode.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    IDENTITY CARD TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"identityCardType"}
                                                    name={"identityCardType"}
                                                    touched={touched.identityCardType}
                                                    isError={errors.identityCardType && errors.identityCardType.name}
                                                    items={idTypesKenya}
                                                />
                                                {errors.identityCardType && touched.identityCardType &&
                                                    <div className={s.error_message}>{errors.identityCardType.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                IDENTITY CARD ID
                                            </div>
                                            <MyInput id={"identityCardId"}
                                                     name={"identityCardId"}
                                                     touched={touched.identityCardId}
                                                     value={values.identityCardId}
                                                     onChange={handleChange}
                                                     isError={errors.identityCardId}
                                            />
                                            {errors.identityCardId && touched.identityCardId &&
                                                <div className={s.error_message}>{errors.identityCardId}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                RELATIONSHIP TO SENDER
                                            </div>
                                            <MyInput id={"relationshipToSender"}
                                                     name={"relationshipToSender"}
                                                     touched={touched.relationshipToSender}
                                                     value={values.relationshipToSender}
                                                     onChange={handleChange}
                                                     isError={errors.relationshipToSender}
                                            />
                                            {errors.relationshipToSender && touched.relationshipToSender &&
                                                <div className={s.error_message}>{errors.relationshipToSender}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA KES::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "KES::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * STREET
                                            </div>
                                            <MyInput id={"street"}
                                                     name={"street"}
                                                     touched={touched.street}
                                                     value={values.street}
                                                     onChange={handleChange}
                                                     isError={errors.street}
                                            />
                                            {errors.street && touched.street &&
                                                <div className={s.error_message}>{errors.street}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    CITY
                                                </div>
                                                <MyInput id={"city"}
                                                         name={"city"}
                                                         touched={touched.city}
                                                         value={values.city}
                                                         onChange={handleChange}
                                                         isError={errors.city}
                                                />
                                                {errors.city && touched.city &&
                                                    <div className={s.error_message}>{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Phone number
                                            </div>
                                            <PhoneCodesInput
                                                initCode={code}
                                                setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                id='phoneNumber'
                                                name='phoneNumber'
                                                onChange={handleChange}
                                                value={values.phoneNumber}
                                                error={errors.phoneNumber}
                                                touched={touched.phoneNumber}
                                                phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                selectable = {false}
                                            />
                                            {errors.phoneNumber &&
                                                <div className={s.error_message}>{errors.phoneNumber}</div>}
                                        </div>


                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTITY CARD TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"identityCardType"}
                                                    name={"identityCardType"}
                                                    touched={touched.identityCardType}
                                                    isError={errors.identityCardType && errors.identityCardType.name}
                                                    items={idTypesKenya}
                                                />
                                                {errors.identityCardType && touched.identityCardType &&
                                                    <div className={s.error_message}>{errors.identityCardType.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IDENTITY CARD ID
                                            </div>
                                            <MyInput id={"identityCardId"}
                                                     name={"identityCardId"}
                                                     touched={touched.identityCardId}
                                                     value={values.identityCardId}
                                                     onChange={handleChange}
                                                     isError={errors.identityCardId}
                                            />
                                            {errors.identityCardId && touched.identityCardId &&
                                                <div className={s.error_message}>{errors.identityCardId}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * MOBILE PROVIDER
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"mobileProvider"}
                                                name={"mobileProvider"}
                                                touched={touched.mobileProvider}
                                                isError={errors.mobileProvider && errors.mobileProvider.name}
                                                items={mobileProvidersKenya}
                                            />
                                            {errors.mobileProvider && touched.mobileProvider &&
                                                <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    RELATIONSHIP TO SENDER
                                                </div>
                                                <MyInput id={"relationshipToSender"}
                                                         name={"relationshipToSender"}
                                                         touched={touched.relationshipToSender}
                                                         value={values.relationshipToSender}
                                                         onChange={handleChange}
                                                         isError={errors.relationshipToSender}
                                                />
                                                {errors.relationshipToSender && touched.relationshipToSender &&
                                                    <div
                                                        className={s.error_message}>{errors.relationshipToSender}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA EGP::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "EGP::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * STREET
                                                </div>
                                                <MyInput id={"street"}
                                                         name={"street"}
                                                         touched={touched.street}
                                                         value={values.street}
                                                         onChange={handleChange}
                                                         isError={errors.street}
                                                />
                                                {errors.street && touched.street &&
                                                    <div className={s.error_message}>{errors.street}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * PHONE NUMBER
                                            </div>
                                            <PhoneCodesInput
                                                initCode={code}
                                                setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                id='phoneNumber'
                                                name='phoneNumber'
                                                onChange={handleChange}
                                                value={values.phoneNumber}
                                                error={errors.phoneNumber}
                                                touched={touched.phoneNumber}
                                                phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                selectable = {false}
                                            />
                                            {errors.phoneNumber &&
                                                <div className={s.error_message}>{errors.phoneNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors.bankCode && errors.bankCode.name}
                                                    items={bankCodeListEGPBank}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors.bankCode.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }


                            <div className={s.block}>
                                <div className={s.title}>
                                    Transfer Details
                                </div>
                                {
                                    props.id !== 'create' &&
                                    <div className={s.row}>
                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                * Payment date
                                                <Popover
                                                    content={"Please enter the date of the transfer. The date can be 2 years ahead from today."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <DatePicker suffixIcon={null} style={dateStyle}
                                                        defaultValue={dayjs(new Date(), dateFormat)}
                                                        disabledDate={disabledDate}
                                                        value={dayjs(date.from)}
                                                        onChange={(_, dateString) => {
                                                            if (!dateString) {
                                                                setDate({...date, from: new Date().toDateString()})
                                                            } else {
                                                                setDate({...date, from: dateString})
                                                            }
                                                        }}
                                                        format={dateFormat}/>
                                        </div>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    * Amount
                                                    <Popover content={"Select a currency and enter the transfer amount.\n" +
                                                        "In the account balance in the selected currency is insufficient, the system " +
                                                        "will automatically offer you to top up using funds in a different currency " +
                                                        "you have on the account."}
                                                             color={'#EEEFEF'}
                                                             overlayInnerStyle={{
                                                                 fontFamily: 'Inter',
                                                                 width: '280px',
                                                                 padding: '13px 16px'
                                                             }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"amount"}
                                                         name={"amount"}
                                                         value={values.amount}
                                                         onChange={handleChange}
                                                />
                                                {errors.amount && <div className={s.error_label}>{errors.amount}</div>}
                                            </div>

                                            <div className={s.languages_dropdown}>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                }

                                <div className={s.row}>
                                    <div className={s.textarea_block}>
                                        <div className={s.input_label}>
                                            ** Purpose
                                        </div>
                                        <MyTextarea
                                            id={"purpose"}
                                            name={"purpose"}
                                            onChange={handleChange}
                                            value={values.purpose}
                                            placeholder={"Enter your purpose of payment"}
                                            isError={errors.purpose}
                                            touched={touched.purpose}
                                        />
                                        {errors.purpose && <div className={s.error_label}>{errors.purpose}</div>}
                                    </div>
                                </div>

                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            ** reference
                                        </div>
                                        <MyInput id={"reference"}
                                                 name={"reference"}
                                                 touched={touched.reference}
                                                 value={values.reference}
                                                 onChange={handleChange}
                                                 placeholder={"Enter if required by the recipient"}
                                                 isError={errors.reference}
                                        />
                                        {errors.reference && <div className={s.error_label}>{errors.reference}</div>}
                                    </div>
                                    <div className={s.input_block}>

                                    </div>
                                </div>

                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            payment type
                                            <Popover content={<div>
                                                <p>
                                                    SHA - the payer (you) pays all the fees applied to the transfer, and
                                                    fees
                                                    applied by other banks are paid by the recipient (in some cases the
                                                    recipient can simply receive a smaller amount).
                                                </p>
                                                <p>
                                                    OUR - the payer (you) pays all the fees including the fees of other
                                                    banks
                                                    applied to this transfer. If the paid fee does not cover all the
                                                    fees of
                                                    a correspondent bank and the recipient’s bank, the missing amount
                                                    must
                                                    be paid additionally. Additional fees of other banks may also be
                                                    deducted
                                                    from the transferred amount (frequent cases with transfers in USD).
                                                </p>
                                            </div>}
                                                     color={'#EEEFEF'}
                                                     overlayInnerStyle={{
                                                         fontFamily: 'Inter',
                                                         width: '280px',
                                                         padding: '13px 16px'
                                                     }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <div className={s.flex_label}>
                                            <label className={s.flex_label}>
                                                <CustomCheckbox
                                                    handleChange={() => setPaymentType("SHA")}
                                                    id="SHA"
                                                    name="SHA"
                                                    isChecked={paymentType === "SHA"}
                                                />
                                                <span
                                                    className={paymentType === "SHA" ? s.type_of_payment_active : s.type_of_payment_inactive}>SHA</span>
                                            </label>
                                            <label className={s.flex_label}>
                                                <CustomCheckbox
                                                    handleChange={() => setPaymentType("OUR")}
                                                    id="OUR"
                                                    name="OUR"
                                                    isChecked={paymentType === "OUR"}
                                                />
                                                <span
                                                    className={paymentType === "OUR" ? s.type_of_payment_active : s.type_of_payment_inactive}>OUR</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className={s.buttonsWrapper}>
                                <div className={s.btn_block}>
                                    <TransparentButton title={'Back'}
                                                       large
                                                       icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                                       onClick={() => {
                                                           navigate(-1);
                                                       }}/>
                                </div>
                                <MyBtn title={props.id === 'create' ? 'Save' : 'Make a transfer'}
                                       large
                                       style={{width: 180}}
                                       type={'submit'}
                                       onClick={() => console.log(errors)}
                                />
                            </div>

                            <CurrencyHelper
                                accountsList={accountsList}
                                accNumberFrom={values.payerAccount}
                                setCurrency={setCurrency}
                            />

                            <PaymentTypeHelper name={"paymentType"}/>

                        </form>
                    )}
                </Formik>
            </motion.div>
        </div>
    );
};

export default BankTransactionTemplate;

import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./BankTransferFormBusiness.module.css";
import {DatePicker, Popover} from "antd";
import info from "../../assets/newImg/personalAccount/information.svg";
import CustomDropdown from "../ui/CustomDropdown/CustomDropdown";
import MyInput from "../ui/MyInput/MyInput";
import classnames from "classnames";
import MyTextarea from "../ui/MyTextarea/MyTextarea";
import CustomCheckbox from "../ui/customCheckbox/CustomCheckbox";
import MyBtn from "../ui/MyBtn/MyBtn";
import {Persist} from "formik-persist";
import {motion} from "framer-motion";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import CustomDropdownForAccounts from "../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import dayjs from "dayjs";
import {myDate} from "../../helpers/CalculateDate";
import type {RangePickerProps} from 'antd/es/date-picker';
import {
    azaBusinessCountries,
    countries,
    bankAccountTypesUS,
    bankCodeListEGPBank,
    bankCodeListGhanaGHSBank,
    bankCodeListKESBank,
    bankCodeListNigeriaUSDBank,
    bankCodeListZARBank,
    entityTypes,
    natureOfBusinessTypes,
    paymentRegions,
    transferReasons,
    languages
} from "../../Constants/StaticData"
import {
    CurrencyHelper,
    getBusinessPaymentType,
    getPhoneCode
} from "../BankTransferFormPersonal/FormHelpers/FormHelpers";
import PhoneCodesInput from "../ui/PhoneCodesInput/PhoneCodesInput";
import CustomDropdownForAzaTransactions from "../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import {getValidationSchema} from "./FormHelpers/FormHelpers";
import {setFilters, setPaymentRegionFilter} from "../../store/reducers/TransactionsSlice/TransactionsSlice";
import {selectAccountsByFilter} from "../../store/reducers/TransactionsSlice/selector";
import {useSelector} from "react-redux";
import SelectWithSearch from "../ui/SelectWithSearch/SelectWithSearch";

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
    setTransferData?: Dispatch<SetStateAction<any>>
    id?: string | number;
}

const dateStyle = {
    fontFamily: 'Inter',
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '18px',
    color: '#2A2E37',
    padding: '17px 16px',
    height: 52,
    width: 311,
    border: '1.5px solid #EEEFEF',
    borderRadius: 8
}

const dateFormat = 'YYYY-MM-DD';

const BankTransferFormBusiness = (props: PropsType) => {

    const [paymentType, setPaymentType] = useState("SHA")
    const [paymentRegion, setPaymentRegion] = useState("SWIFT (International Transfer)")
    const [currency, setCurrency] = useState('')
    const dispatch = useAppDispatch()

    const accountsList = useSelector(selectAccountsByFilter)

    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ? ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate()),
    })
    const [code, setCode] = useState({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    })
    const [paymentTypeByRegion, setPaymentTypeByRegion] = useState('')

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf('day');
    };

    useEffect(() => {
        dispatch(setPaymentRegionFilter(paymentRegion))
    }, [paymentRegion])

    return (
        <div>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <Formik
                    initialValues={{
                        payerAccount: '',
                        paymentRegions: 'SWIFT (International Transfer)',
                        paymentType: '',
                        paymentTypeByRegionForm: '',
                        name: '',
                        firstName: '',
                        lastName: '',
                        fullName: '',
                        bankCode: {
                            name: '',
                            value: ''
                        },
                        bankAccount: '',
                        bankAccountType: {
                            name: '',
                            value: ''
                        },
                        recipientsAddress: '',
                        recipientsAccountNumber: '',
                        recipientsSwiftBic: '',
                        recipientsIban: '',
                        recipientsBankCountry: '',
                        recipientsBankAddress: '',
                        recipientsBank: '',
                        email: '',
                        emailLanguage: 'EN',
                        paymentDate: date.from,
                        transferCurrency: 'USD',
                        transferReason: {
                            name: '',
                            value: ''
                        },
                        routingNumber: '',
                        street: '',
                        city: '',
                        postalCode: '',
                        phoneNumber: '',
                        purpose: '',
                        reference: '',
                        templateName: '',
                        amount: '',
                        type: 'international',
                        relationshipToSender: '',
                        legalEntityType: {
                            name: '',
                            value: ''
                        },
                        natureOfBusiness: {
                            name: '',
                            value: ''
                        },
                        registrationNumber: '',
                        institutionNumber: '',
                        transitNumber: '',
                        uniqueId: '',
                        sortCode: '',

                    }}
                    validationSchema={getValidationSchema(paymentTypeByRegion, paymentRegion)}
                    onSubmit={(values) => {

                        let tempData = {
                            ...values,
                            phoneNumber: values.phoneNumber !== "" ? `+${code.ph}${values.phoneNumber}` : "",
                            transferCurrency: currency,
                            paymentDate: date.from
                        };

                        if (values.paymentRegions === "SWIFT (International Transfer)" ||
                            values.paymentRegions === "SEPA (only for EUR)" ||
                            values.paymentRegions === "PAN AFRICA") {
                            tempData = {...tempData, paymentType}
                        }

                        props.setTransferData && props.setTransferData(tempData)
                        props.setCurrent && props.setCurrent(1)
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Payer account
                                        <Popover content={"Please select the account you wish to make a transfer from."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdownForAccounts
                                        items={accountsList}
                                        placeholder={"Please select the account"}
                                        id="payerAccount"
                                        name="payerAccount"
                                        isError={errors.payerAccount}
                                        touched={touched.payerAccount}
                                        setCurrency={setCurrency}
                                    />
                                    {errors.payerAccount &&
                                        <div className={s.error_message}>{errors.payerAccount}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Payment region
                                        <Popover content={"Please select the account you wish to make a transfer from."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdown
                                        customId="paymentRegions"
                                        items={paymentRegions}
                                        placeholder={"Please select the account"}
                                        id="paymentRegions"
                                        name="paymentRegions"
                                        isError={errors.paymentRegions}
                                        touched={touched.paymentRegions}
                                        setItem={setPaymentRegion}
                                    />
                                    {errors.paymentRegions && touched.paymentRegions &&
                                        <div className={s.error_message}>{errors.paymentRegions}</div>}
                                </div>
                            </div>

                            {
                                values.paymentRegions === "PAN AFRICA" &&
                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            RECIPIENT’S BANK COUNTRY/REGION
                                        </div>
                                        <SelectWithSearch
                                            name={'recipientsBankCountry'}
                                            id="recipientsBankCountry"
                                            isError={errors.recipientsBankCountry}
                                            items={azaBusinessCountries}
                                            touched={touched.recipientsBankCountry}
                                        />
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            PAYMENT METHOD
                                        </div>
                                        <CustomDropdown
                                            customId="paymentTypeByRegionForm"
                                            items={getBusinessPaymentType(values.recipientsBankCountry)}
                                            placeholder={"Please select the payment method"}
                                            id="paymentTypeByRegionForm"
                                            name="paymentTypeByRegionForm"
                                            isError={errors.paymentTypeByRegionForm}
                                            touched={touched.paymentTypeByRegionForm}
                                            setItem={setPaymentTypeByRegion}
                                        />
                                    </div>
                                </div>
                            }

                            {/*SWIFT / SEPA*/}
                            {
                                (values.paymentRegions === "SWIFT (International Transfer)" || values.paymentRegions === "SEPA (only for EUR)") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Full Name
                                                <Popover
                                                    content={"Enter the name and surname of the recipient or the name of " +
                                                        "the legal person."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * recipient’s Address
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress && touched.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN / Account number
                                                <Popover
                                                    content={"Enter the recipient’s bank account number or IBAN account."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SWIFT / BIC code
                                                <Popover
                                                    content={"A SWIFT code is an international bank code that identifies " +
                                                        "particular banks worldwide. It's also known as a Bank Identifier Code (BIC). " +
                                                        "A SWIFT code consists of 8 or 11 characters."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"  "}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * recipient’s bank country
                                            </div>
                                            <SelectWithSearch
                                                name={'recipientsBankCountry'}
                                                id="recipientsBankCountry"
                                                isError={errors.recipientsBankCountry}
                                                items={countries}
                                                touched={touched.recipientsBankCountry}
                                            />
                                            {errors.recipientsBankCountry && touched.recipientsBankCountry &&
                                                <div className={s.error_message}>{errors.recipientsBankCountry}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * recipient’s bank
                                            </div>
                                            <MyInput id={"recipientsBank"}
                                                     name={"recipientsBank"}
                                                     touched={touched.recipientsBank}
                                                     value={values.recipientsBank}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsBank}
                                            />
                                            {errors.recipientsBank && touched.recipientsBank &&
                                                <div className={s.error_message}>{errors.recipientsBank}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                * recipient’s bank address
                                            </div>
                                            <MyInput
                                                id={"recipientsBankAddress"}
                                                name={"recipientsBankAddress"}
                                                touched={touched.recipientsBankAddress}
                                                value={values.recipientsBankAddress}
                                                onChange={handleChange}
                                                isError={errors.recipientsBankAddress}
                                            />
                                            {errors.recipientsBankAddress && touched.recipientsBankAddress &&
                                                <div className={s.error_message}>{errors.recipientsBankAddress}</div>}
                                        </div>

                                        <div className={classnames(s.input_block, s.short)}>
                                            <div className={s.input_label}>
                                                Email
                                                <Popover
                                                    content={"Please indicate the recipient’s email address and language, " +
                                                        "if you would like us to send them an electronically signed automatic email " +
                                                        "about the performed transfer."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"email"}
                                                     name={"email"}
                                                     touched={touched.email}
                                                     value={values.email}
                                                     onChange={handleChange}
                                                     isError={errors.email}
                                            />
                                        </div>

                                        <div className={s.languages_dropdown}>
                                            <CustomDropdown
                                                items={languages}
                                                id="emailLanguage"
                                                name="emailLanguage"
                                                isError={errors.emailLanguage}
                                                touched={touched.emailLanguage}
                                            />
                                        </div>

                                    </div>
                                </div>
                            }

                            {/*ACH (only for USD)*/}
                            {
                                values.paymentRegions === "ACH (only for USD)" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Full Name
                                                <Popover
                                                    content={"Enter the name and surname of the recipient or the name of " +
                                                        "the legal person."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName && touched.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * INSTITUTION NUMBER
                                            </div>
                                            <MyInput id={"institutionNumber"}
                                                     name={"institutionNumber"}
                                                     touched={touched.institutionNumber}
                                                     value={values.institutionNumber}
                                                     onChange={handleChange}
                                                     isError={errors.institutionNumber}
                                            />
                                            {errors.institutionNumber && touched.institutionNumber &&
                                                <div className={s.error_message}>{errors.institutionNumber}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * TRANSIT NUMBER
                                            </div>
                                            <MyInput id={"transitNumber"}
                                                     name={"transitNumber"}
                                                     touched={touched.transitNumber}
                                                     value={values.transitNumber}
                                                     onChange={handleChange}
                                                     isError={errors.transitNumber}
                                            />
                                            {errors.transitNumber && touched.transitNumber &&
                                                <div className={s.error_message}>{errors.transitNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * ACCOUNT NUMBER
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                RECIPIENT’S ADDRESS
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress && touched.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                RECIPIENT’S BANK COUNTRY
                                            </div>
                                            <SelectWithSearch
                                                name={'recipientsBankCountry'}
                                                id="recipientsBankCountry"
                                                isError={errors.recipientsBankCountry}
                                                items={countries}
                                                touched={touched.recipientsBankCountry}
                                            />
                                            {errors.recipientsBankCountry && touched.recipientsBankCountry &&
                                                <div className={s.error_message}>{errors.recipientsBankCountry}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                recipient’s bank
                                            </div>
                                            <MyInput id={"recipientsBank"}
                                                     name={"recipientsBank"}
                                                     touched={touched.recipientsBank}
                                                     value={values.recipientsBank}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsBank}
                                            />
                                            {errors.recipientsBankCountry && touched.recipientsBankCountry &&
                                                <div className={s.error_message}>{errors.recipientsBankCountry}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                RECIPIENT’S BANK ADDRESS
                                            </div>
                                            <MyInput id={"recipientsBankAddress"}
                                                     name={"recipientsBankAddress"}
                                                     touched={touched.recipientsBankAddress}
                                                     value={values.recipientsBankAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsBankAddress}
                                            />
                                            {errors.recipientsBankAddress && touched.recipientsBankAddress &&
                                                <div className={s.error_message}>{errors.recipientsBankAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    Email
                                                    <Popover
                                                        content={"Please indicate the recipient’s email address and language, " +
                                                            "if you would like us to send them an electronically signed automatic email " +
                                                            "about the performed transfer."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Inter',
                                                            width: '280px',
                                                            padding: '13px 16px'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"email"}
                                                         name={"email"}
                                                         touched={touched.email}
                                                         value={values.email}
                                                         onChange={handleChange}
                                                         isError={errors.email}
                                                />
                                            </div>

                                            <div className={s.languages_dropdown}>
                                                <CustomDropdown
                                                    items={languages}
                                                    id="emailLanguage"
                                                    name="emailLanguage"
                                                    isError={errors.emailLanguage}
                                                    touched={touched.emailLanguage}
                                                />
                                            </div>
                                        </div>

                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                UNIQUE ID
                                            </div>
                                            <MyInput
                                                id={"uniqueId"}
                                                name={"uniqueId"}
                                                touched={touched.uniqueId}
                                                value={values.uniqueId}
                                                onChange={handleChange}
                                                isError={errors.uniqueId}
                                            />
                                            {errors.uniqueId && touched.uniqueId &&
                                                <div className={s.error_message}>{errors.uniqueId}</div>}
                                        </div>

                                    </div>
                                </div>
                            }

                            {/*BACS (only for GBP)*/}
                            {
                                values.paymentRegions === "BACS (only for GBP)" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SORT CODE
                                            </div>
                                            <MyInput id={"sortCode"}
                                                     name={"sortCode"}
                                                     touched={touched.sortCode}
                                                     value={values.sortCode}
                                                     onChange={handleChange}
                                                     isError={errors.sortCode}
                                            />
                                            {errors.sortCode && touched.sortCode &&
                                                <div className={s.error_message}>{errors.sortCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * ACCOUNT NUMBER
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN
                                            </div>
                                            <MyInput id={"recipientsIban"}
                                                     name={"recipientsIban"}
                                                     touched={touched.recipientsIban}
                                                     value={values.recipientsIban}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsIban}
                                            />
                                            {errors.recipientsIban && touched.recipientsIban &&
                                                <div className={s.error_message}>{errors.recipientsIban}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SWIFT / BIC code
                                            </div>
                                            <MyInput id={"recipientsSwiftBic"}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                FULL NAME
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName && touched.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                RECIPIENT’S ADDRESS
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress && touched.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                RECIPIENT’S BANK COUNTRY
                                            </div>
                                            <SelectWithSearch
                                                name={'recipientsBankCountry'}
                                                id="recipientsBankCountry"
                                                isError={errors.recipientsBankCountry}
                                                items={countries}
                                                touched={touched.recipientsBankCountry}
                                            />
                                            {errors.recipientsBankCountry && touched.recipientsBankCountry &&
                                                <div className={s.error_message}>{errors.recipientsBankCountry}</div>}
                                        </div>

                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                recipient’s bank
                                            </div>
                                            <MyInput
                                                id={"recipientsBank"}
                                                name={"recipientsBank"}
                                                touched={touched.recipientsBank}
                                                value={values.recipientsBank}
                                                onChange={handleChange}
                                                isError={errors.recipientsBank}
                                            />
                                            {errors.recipientsBank && touched.recipientsBank &&
                                                <div className={s.error_message}>{errors.recipientsBank}</div>}
                                        </div>

                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                recipient’s bank address
                                            </div>
                                            <MyInput
                                                id={"recipientsBankAddress"}
                                                name={"recipientsBankAddress"}
                                                touched={touched.recipientsBankAddress}
                                                value={values.recipientsBankAddress}
                                                onChange={handleChange}
                                                isError={errors.recipientsBankAddress}
                                            />
                                            {errors.recipientsBankAddress && touched.recipientsBankAddress &&
                                                <div className={s.error_message}>{errors.recipientsBankAddress}</div>}
                                        </div>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    Email
                                                    <Popover
                                                        content={"Please indicate the recipient’s email address and language, " +
                                                            "if you would like us to send them an electronically signed automatic email " +
                                                            "about the performed transfer."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Inter',
                                                            width: '280px',
                                                            padding: '13px 16px'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"email"}
                                                         name={"email"}
                                                         touched={touched.email}
                                                         value={values.email}
                                                         onChange={handleChange}
                                                         isError={errors.email}
                                                />
                                            </div>

                                            <div className={s.languages_dropdown}>
                                                <CustomDropdown
                                                    items={languages}
                                                    id="emailLanguage"
                                                    name="emailLanguage"
                                                    isError={errors.emailLanguage}
                                                    touched={touched.emailLanguage}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA GHS::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "GHS::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COMPANY NAME
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"bankCode"}
                                                name={"bankCode"}
                                                touched={touched.bankCode}
                                                isError={errors?.bankCode?.name}
                                                items={bankCodeListGhanaGHSBank}
                                            />
                                            {errors?.bankCode?.name && touched?.bankCode?.name &&
                                                <div className={s.error_message}>{errors.bankCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>
                                    </div>
                                </div>

                            }

                            {/*PAN AFRICA EUR::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "EUR::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COMPANY NAME
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN
                                            </div>
                                            <MyInput id={"recipientsIban"}
                                                     name={"recipientsIban"}
                                                     touched={touched.recipientsIban}
                                                     value={values.recipientsIban}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsIban}
                                            />
                                            {errors.recipientsIban && touched.recipientsIban &&
                                                <div className={s.error_message}>{errors.recipientsIban}</div>}
                                        </div>

                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK NAME
                                            </div>
                                            <MyInput id={"recipientsBank"}
                                                     name={"recipientsBank"}
                                                     touched={touched.recipientsBank}
                                                     value={values.recipientsBank}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsBank}
                                            />
                                            {errors.recipientsBank && touched.recipientsBank &&
                                                <div className={s.error_message}>{errors.recipientsBank}</div>}
                                        </div>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BIC CODE
                                                </div>
                                                <MyInput id={"recipientsSwiftBic"}
                                                         name={"recipientsSwiftBic"}
                                                         touched={touched.recipientsSwiftBic}
                                                         value={values.recipientsSwiftBic}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsSwiftBic}
                                                />
                                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                    <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            }

                            {/*PAN AFRICA GBP::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "GBP::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COMPANY NAME
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK NAME
                                                </div>
                                                <MyInput id={"recipientsBank"}
                                                         name={"recipientsBank"}
                                                         touched={touched.recipientsBank}
                                                         value={values.recipientsBank}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsBank}
                                                />
                                                {errors.recipientsBank && touched.recipientsBank &&
                                                    <div className={s.error_message}>{errors.recipientsBank}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BIC CODE
                                                </div>
                                                <MyInput id={"recipientsSwiftBic"}
                                                         name={"recipientsSwiftBic"}
                                                         touched={touched.recipientsSwiftBic}
                                                         value={values.recipientsSwiftBic}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsSwiftBic}
                                                />
                                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                    <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XOF::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "XOF::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COMPANY NAME
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors?.transferReason?.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div
                                                        className={s.error_message}>{errors?.transferReason?.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA ZAR::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "ZAR::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COMPANY NAME
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    STREET
                                                </div>
                                                <MyInput id={"street"}
                                                         name={"street"}
                                                         touched={touched.street}
                                                         value={values.street}
                                                         onChange={handleChange}
                                                         isError={errors.street}
                                                />
                                                {errors.street && touched.street &&
                                                    <div className={s.error_message}>{errors.street}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                POSTAL CODE
                                            </div>
                                            <MyInput id={"postalCode"}
                                                     name={"postalCode"}
                                                     touched={touched.postalCode}
                                                     value={values.postalCode}
                                                     onChange={handleChange}
                                                     isError={errors.postalCode}
                                            />
                                            {errors.postalCode && touched.postalCode &&
                                                <div className={s.error_message}>{errors.postalCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    CITY
                                                </div>
                                                <MyInput id={"city"}
                                                         name={"city"}
                                                         touched={touched.city}
                                                         value={values.city}
                                                         onChange={handleChange}
                                                         isError={errors.city}
                                                />
                                                {errors.city && touched.city &&
                                                    <div className={s.error_message}>{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * EMAIL
                                            </div>
                                            <MyInput id={"email"}
                                                     name={"email"}
                                                     touched={touched.email}
                                                     value={values.email}
                                                     onChange={handleChange}
                                                     isError={errors.email}
                                            />
                                            {errors.email && touched.email &&
                                                <div className={s.error_message}>{errors.email}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors?.bankCode?.name}
                                                    items={bankCodeListZARBank}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors?.bankCode?.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    initCode={code}
                                                    setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                    selectable={false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                TRANSFER REASON
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"transferReason"}
                                                name={"transferReason"}
                                                touched={touched.transferReason}
                                                isError={errors?.transferReason?.name}
                                                items={transferReasons}
                                            />
                                            {errors.transferReason && touched.transferReason &&
                                                <div className={s.error_message}>{errors?.transferReason?.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                LEGAL ENTITY TYPE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"legalEntityType"}
                                                name={"legalEntityType"}
                                                touched={touched.legalEntityType}
                                                isError={errors?.legalEntityType?.name}
                                                items={entityTypes}
                                            />
                                            {errors.legalEntityType && touched.legalEntityType &&
                                                <div className={s.error_message}>{errors?.legalEntityType?.name}</div>}

                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                CONTACT FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    CONTACT LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                REGISTRATION NUMBER
                                            </div>
                                            <MyInput id={"registrationNumber"}
                                                     name={"registrationNumber"}
                                                     touched={touched.registrationNumber}
                                                     value={values.registrationNumber}
                                                     onChange={handleChange}
                                                     isError={errors.registrationNumber}
                                            />
                                            {errors.registrationNumber && touched.registrationNumber &&
                                                <div className={s.error_message}>{errors.registrationNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    NATURE OF BUSINESS
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"natureOfBusiness"}
                                                    name={"natureOfBusiness"}
                                                    touched={touched.natureOfBusiness}
                                                    isError={errors?.natureOfBusiness?.name}
                                                    items={natureOfBusinessTypes}
                                                />
                                                {errors.natureOfBusiness && touched.natureOfBusiness &&
                                                    <div
                                                        className={s.error_message}>{errors?.natureOfBusiness?.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XAF::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "XAF::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COMPANY NAME
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                         placeholder={"AA123 12345 123456789012 12"}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors?.transferReason?.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div
                                                        className={s.error_message}>{errors?.transferReason?.name}</div>}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            }

                            {/*PAN AFRICA USD::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "USD::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COMPANY NAME
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT
                                                </div>
                                                <MyInput id={"bankAccount"}
                                                         name={"bankAccount"}
                                                         touched={touched.bankAccount}
                                                         value={values.bankAccount}
                                                         onChange={handleChange}
                                                         isError={errors.bankAccount}
                                                />
                                                {errors.bankAccount && touched.bankAccount &&
                                                    <div className={s.error_message}>{errors.bankAccount}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankAccountType"}
                                                    name={"bankAccountType"}
                                                    touched={touched.bankAccountType}
                                                    isError={errors?.bankAccountType?.name}
                                                    items={bankAccountTypesUS}
                                                />
                                                {errors.bankAccountType && touched.bankAccountType &&
                                                    <div
                                                        className={s.error_message}>{errors?.bankAccountType?.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK NAME
                                                </div>
                                                <MyInput id={"recipientsBank"}
                                                         name={"recipientsBank"}
                                                         touched={touched.recipientsBank}
                                                         value={values.recipientsBank}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsBank}
                                                />
                                                {errors.recipientsBank && touched.recipientsBank &&
                                                    <div className={s.error_message}>{errors.recipientsBank}</div>}
                                            </div>

                                        </div>
                                    </div>


                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * ROUTING NUMBER
                                                </div>
                                                <MyInput id={"routingNumber"}
                                                         name={"routingNumber"}
                                                         touched={touched.routingNumber}
                                                         value={values.routingNumber}
                                                         onChange={handleChange}
                                                         isError={errors.routingNumber}
                                                         placeholder={"9 digits"}
                                                />
                                                {errors.routingNumber && touched.routingNumber &&
                                                    <div className={s.error_message}>{errors.routingNumber}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * SWIFT CODE
                                                </div>
                                                <MyInput id={"recipientsSwiftBic"}
                                                         name={"recipientsSwiftBic"}
                                                         touched={touched.recipientsSwiftBic}
                                                         value={values.recipientsSwiftBic}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsSwiftBic}
                                                />
                                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                    <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA KES::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "KES::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COMPANY NAME
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * STREET
                                            </div>
                                            <MyInput id={"street"}
                                                     name={"street"}
                                                     touched={touched.street}
                                                     value={values.street}
                                                     onChange={handleChange}
                                                     isError={errors.street}
                                            />
                                            {errors.street && touched.street &&
                                                <div className={s.error_message}>{errors.street}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                CITY
                                            </div>
                                            <MyInput id={"city"}
                                                     name={"city"}
                                                     touched={touched.city}
                                                     value={values.city}
                                                     onChange={handleChange}
                                                     isError={errors.city}
                                            />
                                            {errors.city && touched.city &&
                                                <div className={s.error_message}>{errors.city}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors?.bankCode?.name}
                                                    items={bankCodeListKESBank}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors?.bankCode?.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    RELATIONSHIP TO SENDER
                                                </div>
                                                <MyInput id={"relationshipToSender"}
                                                         name={"relationshipToSender"}
                                                         touched={touched.relationshipToSender}
                                                         value={values.relationshipToSender}
                                                         onChange={handleChange}
                                                         isError={errors.relationshipToSender}
                                                />
                                                {errors.relationshipToSender && touched.relationshipToSender &&
                                                    <div
                                                        className={s.error_message}>{errors.relationshipToSender}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA EGP::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "EGP::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COMPANY NAME
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * STREET
                                                </div>
                                                <MyInput id={"street"}
                                                         name={"street"}
                                                         touched={touched.street}
                                                         value={values.street}
                                                         onChange={handleChange}
                                                         isError={errors.street}
                                                />
                                                {errors.street && touched.street &&
                                                    <div className={s.error_message}>{errors.street}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Phone number
                                            </div>
                                            <PhoneCodesInput
                                                initCode={code}
                                                setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                id='phoneNumber'
                                                name='phoneNumber'
                                                onChange={handleChange}
                                                value={values.phoneNumber}
                                                error={errors.phoneNumber}
                                                touched={touched.phoneNumber}
                                                phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                selectable={false}
                                            />
                                            {errors.phoneNumber &&
                                                <div className={s.error_message}>{errors.phoneNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors?.bankCode?.name}
                                                    items={bankCodeListEGPBank}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors?.bankCode?.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors?.transferReason?.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div
                                                        className={s.error_message}>{errors?.transferReason?.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA USD::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "NigeriaUSD::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COMPANY NAME
                                            </div>
                                            <MyInput id={"name"}
                                                     name={"name"}
                                                     touched={touched.name}
                                                     value={values.name}
                                                     onChange={handleChange}
                                                     isError={errors.name}
                                            />
                                            {errors.name && touched.name &&
                                                <div className={s.error_message}>{errors.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * PHONE NUMBER
                                            </div>
                                            <PhoneCodesInput
                                                initCode={code}
                                                setCode={setCode(getPhoneCode(values.recipientsBankCountry, null))}
                                                id='phoneNumber'
                                                name='phoneNumber'
                                                onChange={handleChange}
                                                value={values.phoneNumber}
                                                error={errors.phoneNumber}
                                                touched={touched.phoneNumber}
                                                phonecodes={[getPhoneCode(values.recipientsBankCountry, null)]}
                                                selectable={false}
                                            />
                                            {errors.phoneNumber &&
                                                <div className={s.error_message}>{errors.phoneNumber}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"bankCode"}
                                                name={"bankCode"}
                                                touched={touched.bankCode}
                                                isError={errors?.bankCode?.name}
                                                items={bankCodeListNigeriaUSDBank}
                                            />
                                            {errors.bankCode && touched.bankCode &&
                                                <div className={s.error_message}>{errors?.bankCode?.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>
                                    </div>
                                </div>
                            }


                            <div className={s.block}>
                                <div className={s.title}>
                                    Transfer Details
                                </div>

                                {props.id !== 'create' && <div className={s.row}>
                                    <div className={s.input_block_fixed}>
                                        <div className={s.input_label}>
                                            * Payment date
                                            <Popover
                                                content={"Please enter the date of the transfer. The date can be 2 years ahead from today."}
                                                color={'#EEEFEF'}
                                                overlayInnerStyle={{
                                                    fontFamily: 'Inter',
                                                    width: '280px',
                                                    padding: '13px 16px'
                                                }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <DatePicker suffixIcon={null} style={dateStyle}
                                                    defaultValue={dayjs(new Date(), dateFormat)}
                                                    disabledDate={disabledDate}
                                                    value={dayjs(date.from)}
                                                    onChange={(_, dateString) => {
                                                        if (!dateString) {
                                                            setDate({...date, from: new Date().toDateString()})
                                                        } else {
                                                            setDate({...date, from: dateString})
                                                        }
                                                    }}
                                                    format={dateFormat}/>
                                    </div>

                                    <div className={classnames(s.input_block, s.short)}>
                                        <div className={s.input_label}>
                                            * Amount
                                            <Popover content={"Select a currency and enter the transfer amount.\n" +
                                                "In the account balance in the selected currency is insufficient, the system " +
                                                "will automatically offer you to top up using funds in a different currency " +
                                                "you have on the account."}
                                                     color={'#EEEFEF'}
                                                     overlayInnerStyle={{
                                                         fontFamily: 'Inter',
                                                         width: '280px',
                                                         padding: '13px 16px'
                                                     }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <MyInput id={"amount"}
                                                 name={"amount"}
                                                 touched={touched.amount}
                                                 value={values.amount}
                                                 onChange={handleChange}
                                                 isError={errors.amount}
                                        />
                                        {errors.amount &&
                                            <div className={s.error_message}>{errors.amount}</div>}
                                    </div>

                                    <div className={s.languages_dropdown}>
                                        <div className={s.input_label}>
                                            Currency
                                        </div>
                                        <MyInput
                                            id="transferCurrency"
                                            name="transferCurrency"
                                            value={currency}
                                            disabled={true}
                                        />
                                    </div>
                                </div>}

                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            ** Purpose
                                        </div>
                                        <MyTextarea
                                            id={"purpose"}
                                            name={"purpose"}
                                            onChange={handleChange}
                                            value={values.purpose}
                                            placeholder={"Enter your purpose of payment"}
                                            isError={errors.purpose}
                                            touched={touched.purpose}
                                        />
                                        {errors.purpose &&
                                            <div className={s.error_message}>{errors.purpose}</div>}
                                    </div>
                                </div>

                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            ** reference
                                        </div>
                                        <MyInput id={"reference"}
                                                 name={"reference"}
                                                 touched={touched.reference}
                                                 value={values.reference}
                                                 onChange={handleChange}
                                                 placeholder={"Enter if required by the recipient"}
                                                 isError={errors.reference}
                                        />
                                        {errors.reference &&
                                            <div className={s.error_message}>{errors.reference}</div>}
                                    </div>
                                    <div className={s.input_block}>

                                    </div>
                                </div>

                                {
                                    (
                                        values.paymentRegions === "SWIFT (International Transfer)" ||
                                        values.paymentRegions === "SEPA (only for EUR)" ||
                                        values.paymentRegions === "PAN AFRICA"
                                    )   && <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            payment type
                                            <Popover content={<div>
                                                <p>
                                                    SHA - the payer (you) pays all the fees applied to the transfer, and
                                                    fees
                                                    applied by other banks are paid by the recipient (in some cases the
                                                    recipient can simply receive a smaller amount).
                                                </p>
                                                <p>
                                                    OUR - the payer (you) pays all the fees including the fees of other
                                                    banks
                                                    applied to this transfer. If the paid fee does not cover all the
                                                    fees of
                                                    a correspondent bank and the recipient’s bank, the missing amount
                                                    must
                                                    be paid additionally. Additional fees of other banks may also be
                                                    deducted
                                                    from the transferred amount (frequent cases with transfers in USD).
                                                </p>
                                            </div>}
                                                     color={'#EEEFEF'}
                                                     overlayInnerStyle={{
                                                         fontFamily: 'Inter',
                                                         width: '280px',
                                                         padding: '13px 16px'
                                                     }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        {
                                            (
                                                values.paymentRegions === "SWIFT (International Transfer)" ||
                                                values.paymentRegions === "SEPA (only for EUR)" ||
                                                values.paymentRegions === "PAN AFRICA"
                                            )  &&
                                            <div className={s.flex_label}>
                                                <label className={s.flex_label}>
                                                    <CustomCheckbox
                                                        handleChange={() => setPaymentType("SHA")}
                                                        id="SHA"
                                                        name="SHA"
                                                        isChecked={paymentType === "SHA"}
                                                    />
                                                    <span
                                                        className={paymentType === "SHA" ? s.type_of_payment_active : s.type_of_payment_inactive}>SHA</span>
                                                </label>
                                                <label className={s.flex_label}>
                                                    <CustomCheckbox
                                                        handleChange={() => setPaymentType("OUR")}
                                                        id="OUR"
                                                        name="OUR"
                                                        isChecked={paymentType === "OUR"}
                                                    />
                                                    <span
                                                        className={paymentType === "OUR" ? s.type_of_payment_active : s.type_of_payment_inactive}>OUR</span>
                                                </label>
                                            </div>
                                        }
                                    </div>
                                </div>
                                }

                            </div>

                            {!props.id && <div className={s.block}>
                                <div className={s.title}>
                                    Save the Transfer Details as a Template
                                </div>

                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            template Name
                                            <Popover
                                                content={"In order to save this transfer as a template, please enter the " +
                                                    "template name."}
                                                color={'#EEEFEF'}
                                                overlayInnerStyle={{
                                                    fontFamily: 'Inter',
                                                    width: '280px',
                                                    padding: '13px 16px'
                                                }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <MyInput id={"templateName"}
                                                 name={"templateName"}
                                                 touched={touched.templateName}
                                                 value={values.templateName}
                                                 onChange={handleChange}
                                        />
                                        {errors.templateName &&
                                            <div className={s.error_message}>{errors.templateName}</div>}
                                    </div>

                                    <div className={s.input_block}>
                                    </div>
                                </div>
                            </div>
                            }

                            <div className={s.continue_block}>
                                <div className={s.required}>
                                    <div>
                                        * required fields.
                                    </div>
                                    <div>
                                        ** one of the fields is required.
                                    </div>
                                </div>
                                {!props.id && <div className={s.button_wrapper}>
                                    <MyBtn title={"Continue"} type={"submit"}/>
                                </div>}
                            </div>

                            <Persist name="transfer-form"/>

                            <CurrencyHelper
                                accountsList={accountsList}
                                payerAccount={values.payerAccount}
                                setCurrency={setCurrency}
                            />

                        </form>
                    )}
                </Formik>
            </motion.div>
        </div>
    );
};

export default BankTransferFormBusiness;

import React, { useState } from 'react';
import s from "./Emails.module.css";
import Icon from "@ant-design/icons";
import classnames from "classnames";
import MyBtn from "../ui/MyBtn/MyBtn";
import plus from "../../assets/newImg/personalAccount/plusWhite.svg";
import { IEmails, IPhoneNumber} from "../../types/interfaces";
import { ReactComponent as Lock } from "../../assets/newImg/personalAccount/lock.svg";
import { ReactComponent as Basket } from "../../assets/newImg/personalAccount/trash.svg";
import { ReactComponent as Back } from "../../assets/newImg/personalAccount/back.svg";
import CustomModal from "../ui/CustomModal/CustomModal";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import * as yup from "yup";
import {
    addNewEmailThunk,
    deleteEmailThunk,
    getCodeForVerifyEmailThunk, getEmailListThunk,
    sendEmailCodeThunk, setMainEmailThunk, setTokenState
} from "../../store/reducers/ActionCreators";
import MyInput from "../ui/MyInput/MyInput";
import styles from "../ContactUsForm/ContactUsForm.module.css";

type PropsType = {
    emailList: IEmails[]
}

const validationSchema = yup.object({
    email: yup.string().required('Required field').email('invalid email address'),
})
const Emails = ( props: PropsType) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const [isAddNeEmailPopupOpen, setIsAddNewEmailPopupOpen] = useState(false)
    const [isVerifyPopupShow, setIsVerifyPopupShow] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [isCodeWrong, setIsCodeWrong] = useState(false)
    const [emailForVerifying, setEmailForVerifying] = useState<IEmails>({
        id: null,
        isMain: null,
        isVerify: null,
        email: null
    })

    const addNewEmail = () => {
        setIsAddNewEmailPopupOpen(true)
    }

    const formikEmails = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {

            const tempData = {
                ...values,
                email: `${values.email}`,
            }

            dispatch(addNewEmailThunk(token, tempData))
                .then((res) => {
                    if (res.data.status === "Added") {
                        setEmailForVerifying({...emailForVerifying, email: tempData.email, id: res.data.id})
                        setIsAddNewEmailPopupOpen(false)
                        setIsVerifyPopupShow(true)
                        dispatch(getCodeForVerifyEmailThunk(token,res.data.id))
                            .then((res) => {
                                console.log(res)
                                dispatch(getEmailListThunk(token))
                        })
                    }
                })
                .catch((e) => {
                    setIsAddNewEmailPopupOpen(false)
                    if (e.response.data === "Email already exist") {
                        setIsErrorModalOpen(true)
                    }
                    if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                    }
                    if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                    }
            })

            formikHelpers.resetForm()
        }
    })

    const formikVerify = useFormik({
        initialValues: {
            code: '',
        },
        onSubmit: values => {
            console.log("submit")
            dispatch(sendEmailCodeThunk(token, emailForVerifying.id, values.code))
                .then((res) => {
                    if (res.data === "Verified") {
                        setIsVerifyPopupShow(false)
                    }
                })
                .catch((err) => {
                    setIsCodeWrong(true)
                })
        }
    })

    const startVerifying = (item: IEmails) => {
        setEmailForVerifying(item)
        dispatch(getCodeForVerifyEmailThunk(token, item.id))
            .then(() => {
                setIsVerifyPopupShow(true)
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const deleteEmail = (item: IEmails) => {
        dispatch(deleteEmailThunk(token, item.id))
    }

    const setMainEmail = (item: IEmails) => {
        dispatch(setMainEmailThunk(token, item.id))
    }

    return (
        <div className={s.emails_block}>

            <CustomModal
                setIsModalOpen={setIsErrorModalOpen}
                isModalOpen={isErrorModalOpen}
                type={"error"}
            >
                <div className={styles.popup_title}>
                    Failed!
                </div>
                <div className={styles.popup_subtitle}>
                    This email already exists. Please try another email.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)} personalAccount={true}/>
                    </div>
                </div>
            </CustomModal>

            {/*verify email*/}

            <CustomModal
                setIsModalOpen={setIsVerifyPopupShow}
                isModalOpen={isVerifyPopupShow}
                type={"confirm"}
            >
                <div className={s.add_email_popup_title}>
                    Email authentication
                </div>
                <form onSubmit={formikVerify.handleSubmit} className={s.from_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.verify_subtitle}>
                            Confirmation code sent to email <span className={s.verify_phone}>+ {emailForVerifying.email}</span>
                        </div>
                        <div className={s.input_label}>
                            confirmation code
                        </div>
                        <MyInput
                            value={formikVerify.values.code}
                            onChange={formikVerify.handleChange}
                            id={"code"}
                            name={"code"}
                            isError={isCodeWrong}
                            touched={formikVerify.touched.code}
                        />
                        {   isCodeWrong &&
                            <div className={s.error}>Confirmation code is wrong</div>
                        }
                    </div>

                    <div className={s.buttonsWrapper}>
                        <TransparentButton
                            buttonType={'confirm'}
                            medium
                            personalAccount={true}
                            style={{width: 180}}
                            title={'Cancel'}
                            onClick={() => setIsVerifyPopupShow(false)}
                            icon={<Icon component={() => <Back/>} rev={undefined} />}
                        />
                        <MyBtn
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Verify'}
                            type={"submit"}
                            personalAccount={true}
                        />
                    </div>
                </form>
            </CustomModal>

            {/*add new email*/}

            <CustomModal
                setIsModalOpen={setIsAddNewEmailPopupOpen}
                isModalOpen={isAddNeEmailPopupOpen}
                type={"confirm"}
            >
                <div className={s.add_email_popup_title}>
                    Add email
                </div>
                <form onSubmit={formikEmails.handleSubmit} className={s.from_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            Email
                        </div>
                        <MyInput
                            value={formikEmails.values.email}
                            onChange={formikEmails.handleChange}
                            touched={formikEmails.touched}
                            id={"email"}
                            name={"email"}
                        />
                        {formikEmails.errors.email && <div className={s.error}>{formikEmails.errors.email}</div>}
                    </div>

                    <div className={s.buttonsWrapper}>
                        <div className={s.button_block}>
                            <TransparentButton
                                buttonType={'confirm'}
                                medium
                                personalAccount={true}
                                title={'Cancel'}
                                onClick={() => setIsAddNewEmailPopupOpen(false)}
                                icon={<Icon component={() => <Back/>} rev={undefined} />}
                            />
                        </div>
                        <div className={s.button_block}>
                            <MyBtn
                                buttonType={'confirm'}
                                medium
                                title={'Add'}
                                type={"submit"}
                                personalAccount={true}
                            />
                        </div>
                    </div>
                </form>
            </CustomModal>

            {/*Emails table*/}

            <div className={s.list_title}>
                Emails
            </div>
            <div className={s.emails_list_header}>

                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Email
                    </div>
                </div>
                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Verify
                    </div>
                </div>
                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Set main
                    </div>
                </div>
                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Delete
                    </div>
                </div>
            </div>

            {/*Emails list*/}

            {
                props.emailList?.map((item, index) => {
                    return (
                        <div className={s.emails_list} key={item.id}>

                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    {item.email}
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    {
                                        item.isVerify
                                            ? <div>Verified</div>
                                            : <div className={s.need_to_verify} onClick={() => startVerifying(item)}>
                                                <Icon component={() => <Lock/>} alt="" rev={undefined}/>
                                                Verify
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    {
                                        item.isMain
                                            ? <div>Main</div>
                                            : <div className={item.isVerify ? s.set_as_main : s.disabled_phone} onClick={() => item.isVerify && setMainEmail(item)}>
                                                <div className={s.tick_svg}></div>
                                                Set as main
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    <div className={classnames(s.flex, item.isMain ? s.disabled : s.action)} onClick={() => !item.isMain && deleteEmail(item)}>
                                        <Icon component={() => <Basket/>} rev={undefined}/>
                                        <div className={item.isMain ? s.disabled : s.delete_text_active}>Delete</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <div className={s.emails_list_mobile}>
                {
                    props.emailList?.map((item, index) => {
                        return (
                            <div className={s.phone_card}>
                                <div className={s.row}>
                                    <div className={s.block}>
                                        <div className={s.label}>
                                            Email
                                        </div>
                                        <div className={s.list_block_value}>
                                            {item.email}
                                        </div>
                                    </div>

                                    <div className={s.block}>
                                        <div className={s.label}>
                                            Verify
                                        </div>
                                        <div className={s.list_block_value}>
                                            {
                                                item.isVerify
                                                    ? <div>Verified</div>
                                                    : <div className={s.need_to_verify} onClick={() => startVerifying(item)}>
                                                        <Icon component={() => <Lock/>} alt="" rev={undefined}/>
                                                        Verify
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={s.row}>
                                    <div className={s.block}>
                                        <div className={s.label}>
                                            Set Main
                                        </div>
                                        <div className={s.list_block_value}>
                                            {
                                                item.isMain
                                                    ? <div>Main</div>
                                                    : <div className={item.isVerify ? s.set_as_main : s.disabled} onClick={() => item.isVerify && setMainEmail(item)}>
                                                        <div className={s.tick_svg}></div>
                                                        Set as main
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                    <div className={s.block}>
                                        <div className={s.label}>
                                            Action
                                        </div>
                                        <div className={s.list_block_value}>
                                            <div className={classnames(s.flex, item.isMain ? s.disabled : s.action)} onClick={() => !item.isMain && deleteEmail(item)}>
                                                <Icon component={() => <Basket/>} rev={undefined}/>
                                                <div className={item.isMain ? s.disabled : s.delete_text_active}>Delete</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className={s.button_wrapper}>
                <div className={s.button_block}>
                    <MyBtn
                        localDisable={props.emailList?.length >= 5}
                        icon={plus}
                        personalAccount={true}
                        title={'New email'}
                        onClick={() => addNewEmail()}
                    />
                </div>
            </div>

        </div>
    );
};

export default Emails;

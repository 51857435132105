import React, {Dispatch, SetStateAction, useState} from 'react';
import s from './ExchangeStepTwo.module.css'
import TransparentButton from "../../../../../components/ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../../../../assets/newImg/personalAccount/back.svg";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import Table from "antd/es/table";
import CustomModal from "../../../../../components/ui/CustomModal/CustomModal";
import {SendTransferThunk} from "../../../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {ITransacton} from "../../../../../types/interfaces";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
    exchangeData: Array<ITransacton>
}

const columns = [
    {
        title: 'from Account',
        dataIndex: 'fromAccount',
        className: `${s.short}`,
        align: 'left'
    },
    {
        title: 'To Account',
        dataIndex: 'toAccount',
        align: 'left',
        className: `${s.short}`,
    },
    {
        title: 'To sell',
        dataIndex: 'toSell',
        align: 'right',
        className: `${s.short}`,
    },
    {
        title: 'To buy',
        dataIndex: 'toBuy',
        align: 'right',
        className: `${s.short}`,
    },
    // {
    //     title: 'Ratio',
    //     dataIndex: 'ratio',
    //     align: 'right',
    //     className: `${s.short}`,
    // },
    Table.EXPAND_COLUMN,
];


export interface IExchangeBody {
    amount: number,
    from: string,
    to: string,
}

const ExchangeStepTwo = (props: PropsType) => {

    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)
    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.user.userToken)

    const tableData = props.exchangeData.map((item: any, index: number) => {
        return {
            key: item.index,
            fromAccount: item.accNumberFrom,
            toAccount: item.accNumberTo,
            toSell: (item.amount ? item.amount : item.result) + ' ' + item.forAmountCurrency,
            toBuy: (item.toAmount? item.toAmount : item.result) + ' ' + item.getAmountCurrency,
            // ratio: <>
            //     <p className={s.ratio_cell}>1 USD: 0.91 EUR;</p>
            //     <p className={s.ratio_cell}>1 EUR: 1.09 USD</p>
            // </>
        }
    })

    const sendExchangeData = () => {
        const tempData = {
            ...props.exchangeData[0],
            amount: (props.exchangeData[0].amount && props.exchangeData[0].result) ? Number(props.exchangeData[0].amount) : props.exchangeData[0].result
        }
        delete tempData.forAmountCurrency
        delete tempData.getAmountCurrency
        delete tempData.toAmount
        delete tempData.result
        dispatch(SendTransferThunk(token, tempData))
            .then((res) => {
                if (res.data === 'Created') {
                    setIsSuccessPopupOpen(true)
                } else {
                    setIsErrorPopupOpen(true)
                }
            })
    }

    const closeAndRedirect = () => {
        setIsSuccessPopupOpen(false)
        props.setCurrent(0)
    }

    return (
        <div className={s.wrapper}>

            <CustomModal isModalOpen={isSuccessPopupOpen} setIsModalOpen={setIsSuccessPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_title}>
                        Success!
                    </div>
                    <div className={s.popup_subtitle}>
                        Your request has been sent.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn title={'Ok'} medium onClick={() => closeAndRedirect()}/>
                    </div>
                </div>
            </CustomModal>
            <CustomModal type="error" isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_title}>
                        Oops!
                    </div>
                    <div className={s.popup_subtitle}>
                        Something went wrong. Please try again.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" title={"Ok"} onClick={() => {
                            setIsErrorPopupOpen(false)
                        }}/>
                    </div>
                </div>
            </CustomModal>

            <h2 className={s.title}>Sell Currency</h2>
            <Table
                bordered={false}
                rowClassName={(record, index) => index % 2 !== 0 ? `${s.table_row_dark}` : ''}
                className={s.statementTable}
                columns={columns}
                pagination={false}
                //for info
                /*expandable={{
                    expandIcon: ({expanded, onExpand, record}) =>
                        (<img src={arrowDown} className={expanded ? s.open : s.close}
                              onClick={e => onExpand(record, e)}/>)
                    ,
                    expandedRowRender: (record) => <p style={{margin: 0}}>{record.description}</p>,
                    expandRowByClick: true,

                }}*/
                dataSource={tableData}
            />

            <div >
                {
                    props.exchangeData.map((exchange, index) => <div key={index} className={s.transfer_card_mobile}>
                        <div className={s.mobile_row}>
                            <div>
                                <p className={s.transfer_label}>from Account</p>
                                <p className={s.transfer_value}>{exchange.accNumberFrom}</p>
                            </div>
                        </div>

                        <div className={s.mobile_row}>
                            <div>
                                <p className={s.transfer_label}>To Account</p>
                                <p className={s.transfer_value}>{exchange.accNumberTo}</p>
                            </div>
                        </div>

                        <div className={s.mobile_row}>
                            <div>
                                <p className={s.transfer_label}>To buy</p>
                                <p className={s.transfer_value}>
                                    {(exchange.toAmount? exchange.toAmount : exchange.result) + ' ' + exchange.getAmountCurrency}
                                </p>
                            </div>

                            <div>
                                <p className={s.transfer_label}>To sell</p>
                                <p className={s.transfer_value}>
                                    {(exchange.amount ? exchange.amount : exchange.result) + ' ' + exchange.forAmountCurrency}
                                </p>
                            </div>
                        </div>
                    </div>)
                }

            </div>

            <div className={s.buttonsWrapper}>
                <div className={s.button_block}>
                    <TransparentButton
                        title={'Back'}
                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        onClick={() => props.setCurrent(0)}
                    />
                </div>
                <div className={s.button_block}>
                    <MyBtn title={'Confirm'} large onClick={() => sendExchangeData()}/>
                </div>
            </div>
        </div>
    );
};

export default ExchangeStepTwo;

import React, { useState } from 'react';
import classnames from "classnames";
import styles from "./ContactUsForm.module.css";
import efficiently from "../../assets/newImg/publicPages/efficiently.svg";
import PhoneCodesInput from '../ui/PhoneCodesInput/PhoneCodesInput';
import MyInput from "../ui/MyInput/MyInput";
import MyTextarea from "../ui/MyTextarea/MyTextarea";
import MyBtn from "../ui/MyBtn/MyBtn";
import { useFormik } from "formik";
import { useAppDispatch } from "../../hooks/redux";
import { sendContactFormData } from "../../store/reducers/ActionCreators";
import * as yup from "yup";
import CustomModal from "../ui/CustomModal/CustomModal";
import { setDisabled } from "../../store/reducers/UserSlice";
import { phonecodes } from "../../Constants/StaticData";
import contactImg from "../../assets/newImg/publicPages/contact_form.png"

const validationSchema = yup.object({
    name: yup.string().required('Required field').max(100, 'Max 100 symbols'),
    message: yup.string().required('Required field').max(1000, 'Max 1000 symbols'),
    email: yup.string().email("Invalid format").required('Required field'),
    lastName: yup.string().required('Required field').max(100, 'Max 100 symbols'),
    country: yup.string().required('Required field').max(100, 'Max 100 symbols'),
    id: yup.string().matches(/^[0-9]+$/, "Must be only digits").max(100, 'Max 100 symbols'),
    phone: yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(6, "Min 6")
        .max(12, "Max 12")
        .required('Required field'),
})

const ContactUsForm = () => {

    const dispatch = useAppDispatch()
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

    const [code, setCode] = useState({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    })

    const contactForm = useFormik({
        initialValues: {
            name: "",
            lastName: "",
            country: "",
            id: "",
            phone: "",
            email: "",
            message: "",
        },
        validationSchema,
        onSubmit: (values, formikHelpers) => {
            dispatch(sendContactFormData(values))
                .then((res) => {
                    if (res.data === 'ok') {
                        setIsSuccessModalOpen(true)
                    }
                })
                .catch((e) => {
                    setIsErrorModalOpen(true)
                })
                .finally(() => {
                    dispatch(setDisabled(false))
                    formikHelpers.resetForm()
                })
        }
    })

    return (
        <>
            <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
                <div className={styles.popup_title}>
                    Success!
                </div>
                <div className={styles.popup_subtitle}>
                    Your request has been sent.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn title={"Ok"} onClick={() => setIsSuccessModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal setIsModalOpen={setIsErrorModalOpen} isModalOpen={isErrorModalOpen} type={"error"}>
                <div className={styles.popup_title}>
                    Oops!
                </div>
                <div className={styles.popup_subtitle}>
                    Something went wrong. Please try again later.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)} />
                    </div>
                </div>
            </CustomModal>

            <div className={classnames(styles.flex, styles.gap20)}>
                {/* <div className={classnames(styles.black_bg, styles.block)}> */}
                    <div className={styles.contact_img}>
                        <img src={contactImg} alt='contact-image' />
                    </div>
                {/* </div> */}
                <div className={classnames(styles.block)}>
                    <form onSubmit={contactForm.handleSubmit}>
                        <div className={styles.contact_row}>
                            <div className={styles.input_wrapper}>
                                <div className={styles.input_label}>
                                    <span className={styles.input_required}>*</span>
                                    Name
                                </div>
                                <MyInput
                                    placeholder={"Enter your name"}
                                    id={"name"}
                                    name={"name"}
                                    touched={contactForm.touched.name}
                                    isError={contactForm.errors.name}
                                    value={contactForm.values.name}
                                    onChange={contactForm.handleChange}
                                />
                                <div className={styles.error_text}>
                                    {contactForm.errors.name}
                                </div>
                            </div>

                            <div className={styles.input_wrapper}>
                                <div className={styles.input_label}><span className={styles.input_required}>*</span>Last Name</div>
                                <MyInput
                                    placeholder={"Enter your last name"}
                                    id={"lastName"}
                                    name={"lastName"}
                                    touched={contactForm.touched.lastName}
                                    isError={contactForm.errors.lastName}
                                    value={contactForm.values.lastName}
                                    onChange={contactForm.handleChange}
                                />
                                <div className={styles.error_text}>
                                    {contactForm.errors.lastName}
                                </div>
                            </div>
                        </div>

                        <div className={styles.contact_row}>
                            <div className={styles.input_wrapper}>
                                <div className={styles.input_label}><span className={styles.input_required}>*</span>Country</div>
                                <MyInput
                                    placeholder={"Enter your country"}
                                    id={"country"}
                                    name={"country"}
                                    touched={contactForm.touched.country}
                                    isError={contactForm.errors.country}
                                    value={contactForm.values.country}
                                    onChange={contactForm.handleChange}
                                />
                                <div className={styles.error_text}>
                                    {contactForm.errors.country}
                                </div>
                            </div>

                            <div className={styles.input_wrapper}>
                                <div className={styles.input_label}>Client ID</div>
                                <MyInput
                                    placeholder={"Enter your ID"}
                                    id={"id"}
                                    name={"id"}
                                    touched={contactForm.touched.id}
                                    isError={contactForm.errors.id}
                                    value={contactForm.values.id}
                                    onChange={contactForm.handleChange}
                                />
                                <div className={styles.error_text}>
                                    {contactForm.errors.id}
                                </div>
                            </div>
                        </div>

                        <div className={styles.contact_row}>
                            <div className={styles.input_wrapper}>
                                <div className={styles.input_label}><span className={styles.input_required}>*</span>Phone Number</div>
                                <PhoneCodesInput
                                    initCode={code}
                                    setCode={setCode}
                                    id={"phone"}
                                    name={"phone"}
                                    value={contactForm.values.phone}
                                    onChange={contactForm.handleChange}
                                    error={contactForm.errors.phone}
                                    touched={contactForm.touched}
                                    phonecodes={phonecodes}
                                />
                                <div className={styles.error_text}>
                                    {contactForm.errors.phone}
                                </div>
                            </div>

                            <div className={styles.input_wrapper}>
                                <div className={styles.input_label}><span className={styles.input_required}>*</span>Email</div>
                                <MyInput
                                    placeholder={"Enter your email"}
                                    id={"email"}
                                    name={"email"}
                                    isError={contactForm.errors.email}
                                    touched={contactForm.touched.email}
                                    value={contactForm.values.email}
                                    onChange={contactForm.handleChange}
                                />
                                <div className={styles.error_text}>
                                    {contactForm.errors.email}
                                </div>
                            </div>
                        </div>

                        <div className={styles.contact_row}>
                            <div className={styles.input_wrapper}>
                                <div className={styles.input_label}><span className={styles.input_required}>*</span>Message</div>
                                <MyTextarea
                                    placeholder={"Enter your message"}
                                    id={"message"}
                                    name={"message"}
                                    isError={contactForm.errors.message}
                                    value={contactForm.values.message}
                                    onChange={contactForm.handleChange}
                                />
                                <div className={styles.error_text}>
                                    {contactForm.errors.message}
                                </div>
                            </div>
                        </div>

                        <div className={styles.contacts_button_wrapper}>
                            <div className={styles.button_block}>
                                <MyBtn title={"Submit"} type={"submit"}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>

    );
};

export default ContactUsForm;

import React, {useState} from 'react';
import s from "./PhoneNumbers.module.css";
import Icon from "@ant-design/icons";
import classnames from "classnames";
import MyBtn from "../ui/MyBtn/MyBtn";
import plus from "../../assets/newImg/personalAccount/plusWhite.svg";
import {IPhoneNumber} from "../../types/interfaces";
import {ReactComponent as Lock} from "../../assets/newImg/personalAccount/lock.svg";
import {ReactComponent as Basket} from "../../assets/newImg/personalAccount/trash.svg";
import {ReactComponent as Back} from "../../assets/newImg/personalAccount/back.svg";
import CustomModal from "../ui/CustomModal/CustomModal";
import PhoneCodesInput from "../ui/PhoneCodesInput/PhoneCodesInput";
import {useFormik} from "formik";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import * as yup from "yup";
import {
    addNewPhoneThunk,
    deletePhoneThunk,
    getCodeForVerifyPhoneThunk, getPhoneListThunk,
    sendCodeThunk, setMainPhoneThunk, setTokenState
} from "../../store/reducers/ActionCreators";
import MyInput from "../ui/MyInput/MyInput";
import {phonecodes} from "../../Constants/StaticData";
import styles from "../ContactUsForm/ContactUsForm.module.css";

type PropsType = {
    phoneList: IPhoneNumber[]
}

const validationSchema = yup.object({
    phoneNumber: yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(6, "min 6")
        .max(12, "max 12")
        .required('Required field'),
})
const PhoneNumbers = ( props: PropsType) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const [isAddNewPhonePopupOpen, setIsAddNewPhonePopupOpen] = useState(false)
    const [isVerifyPopupShow, setIsVerifyPopupShow] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [isSmsCodeWrong, setIsSmsCodeWrong] = useState(false)
    const [code, setCode] = useState({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    })
    const [phoneForVerifying, setPhoneForVerifying] = useState<IPhoneNumber>({
        id: null,
        isMain: null,
        isVerify: null,
        phoneNumber: null
    })

    const addNewPhone = () => {
        setIsAddNewPhonePopupOpen(true)
    }

    const formikPhones = useFormik({
        initialValues: {
            phoneNumber: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {

            const tempData = {
                ...values,
                phoneNumber: `${code.ph} ${values.phoneNumber}`,
            }

            dispatch(addNewPhoneThunk(token, tempData))
                .then((res) => {
                    if (res.data.status === "Added") {
                        setPhoneForVerifying({...phoneForVerifying, phoneNumber: tempData.phoneNumber, id: res.data.id})
                        setIsAddNewPhonePopupOpen(false)
                        setIsVerifyPopupShow(true)
                        dispatch(getCodeForVerifyPhoneThunk(token,res.data.id))
                            .then((res) => {
                                console.log(res)
                                dispatch(getPhoneListThunk(token))
                        })
                    }
                })
                .catch((e) => {
                    setIsAddNewPhonePopupOpen(false)
                    if (e.response.data === "Phone already exist") {
                        setIsErrorModalOpen(true)
                    }
                    if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                    }
                    if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                    }
            })

            formikHelpers.resetForm()
        }
    })

    const formikVerify = useFormik({
        initialValues: {
            code: '',
        },
        onSubmit: values => {
            console.log("submit")
            dispatch(sendCodeThunk(token, phoneForVerifying.id, values.code))
                .then((res) => {
                    if (res.data === "Verified") {
                        setIsVerifyPopupShow(false)
                    }
                })
                .catch((err) => {
                    setIsSmsCodeWrong(true)
                })
        }
    })

    const startVerifying = (item: IPhoneNumber) => {
        setPhoneForVerifying(item)
        dispatch(getCodeForVerifyPhoneThunk(token, item.id))
            .then(() => {
                setIsVerifyPopupShow(true)
            })
            .catch((e) => {
                console.error(e)
            })
    }

    // const handleCancel = () => {
    //     formikPhones.setFieldValue('phoneNumber', ''); 
    //     setIsAddNewPhonePopupOpen(false);
    // }

    const deletePhone = (item: IPhoneNumber) => {
        dispatch(deletePhoneThunk(token, item.id))
    }

    const setMainPhone = (item: IPhoneNumber) => {
        dispatch(setMainPhoneThunk(token, item.id))

    }

    return (
        <div className={s.phone_numbers_block}>

            <CustomModal
                setIsModalOpen={setIsErrorModalOpen}
                isModalOpen={isErrorModalOpen}
                type={"error"}
            >
                <div className={styles.popup_title}>
                    Failed!
                </div>
                <div className={styles.popup_subtitle}>
                    This phone number already exists. Please try another phone number.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)} personalAccount={true}/>
                    </div>
                </div>
            </CustomModal>

            {/*verify phone*/}

            <CustomModal
                setIsModalOpen={setIsVerifyPopupShow}
                isModalOpen={isVerifyPopupShow}
                type={"confirm"}
            >
                <div className={s.add_phone_popup_title}>
                    SMS authentication
                </div>
                <form onSubmit={formikVerify.handleSubmit} className={s.from_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.verify_subtitle}>
                            SMS with confirmation code sent to number <span className={s.verify_phone}>+ {phoneForVerifying.phoneNumber}</span>
                        </div>
                        <div className={s.input_label}>
                            confirmation code
                        </div>
                        <MyInput
                            value={formikVerify.values.code}
                            onChange={formikVerify.handleChange}
                            id={"code"}
                            name={"code"}
                            isError={isSmsCodeWrong}
                            touched={formikVerify.touched.code}
                        />
                        {   isSmsCodeWrong &&
                            <div className={s.error}>SMS code is wrong</div>
                        }
                    </div>

                    <div className={s.buttonsWrapper}>
                        <TransparentButton
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Cancel'}
                            personalAccount={true}
                            onClick={() => setIsVerifyPopupShow(false)}
                            icon={<Icon component={() => <Back/>} rev={undefined} />}
                        />
                        <MyBtn
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Verify'}
                            type={"submit"}
                            personalAccount={true}
                        />
                    </div>
                </form>
            </CustomModal>

            {/*add new phone*/}

            <CustomModal
                setIsModalOpen={setIsAddNewPhonePopupOpen}
                isModalOpen={isAddNewPhonePopupOpen}
                type={"confirm"}
            >
                <div className={s.add_phone_popup_title}>
                    Add phone number
                </div>
                <form onSubmit={formikPhones.handleSubmit} className={s.from_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            Phone number
                        </div>
                        <PhoneCodesInput
                            initCode={code}
                            value={formikPhones.values.phoneNumber}
                            onChange={formikPhones.handleChange}
                            error={formikPhones.errors.phoneNumber}
                            touched={formikPhones.touched}
                            setCode={setCode}
                            id={"phoneNumber"}
                            name={"phoneNumber"}
                            phonecodes={phonecodes}
                        />
                        {formikPhones.errors.phoneNumber && <div className={s.error}>{formikPhones.errors.phoneNumber}</div>}
                    </div>

                    <div className={s.buttonsWrapper}>
                        <div className={s.button_block}>
                            <TransparentButton
                                buttonType={'confirm'}
                                medium
                                personalAccount={true}
                                title={'Cancel'}
                                onClick={() => setIsAddNewPhonePopupOpen(false)}
                                icon={<Icon component={() => <Back/>} rev={undefined} />}
                            />
                        </div>
                        <div className={s.button_block}>
                            <MyBtn
                                buttonType={'confirm'}
                                medium
                                title={'Add'}
                                type={"submit"}
                                personalAccount={true}
                            />
                        </div>
                    </div>
                </form>
            </CustomModal>

            {/*Phones table*/}

            <div className={s.list_title}>
                Phone Numbers
            </div>
            <div className={s.phones_list_header}>

                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Phone number
                    </div>
                </div>
                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Verify
                    </div>
                </div>
                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Set main
                    </div>
                </div>
                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Action
                    </div>
                </div>
            </div>

            {/*Phones list*/}

        <div className={s.phones_items_list}>

            {
                props.phoneList?.map((item, index) => {
                    return (
                        <div className={s.phones_list} key={item.id}>

                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    + {item.phoneNumber}
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    {
                                        item.isVerify
                                            ? <div>Verified</div>
                                            : <div className={s.need_to_verify} onClick={() => startVerifying(item)}>
                                                <Icon component={() => <Lock/>} alt="" rev={undefined}/>
                                                Verify
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    {
                                        item.isMain
                                            ? <div>Main</div>
                                            : <div className={item.isVerify ? s.set_as_main : s.disabled_phone} onClick={() => item.isVerify && setMainPhone(item)}>
                                                <div className={s.tick_svg}></div>
                                                Set as main
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    <div className={classnames(s.flex, item.isMain ? s.disabled : s.action)} onClick={() => !item.isMain && deletePhone(item)}>
                                        <Icon component={() => <Basket/>} rev={undefined}/>
                                        <div className={item.isMain ? s.disabled : s.delete_text_active}>Delete</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </div>

            <div className={s.phones_list_mobile}>
                {
                    props.phoneList?.map((item, index) => {
                        return (
                            <div className={s.phone_card}>
                                <div className={s.row}>
                                    <div className={s.block}>
                                        <div className={s.label}>
                                            phone number
                                        </div>
                                        <div className={s.list_block_value}>
                                            + {item.phoneNumber}
                                        </div>
                                    </div>

                                    <div className={s.block}>
                                        <div className={s.label}>
                                            verify
                                        </div>
                                        <div className={s.list_block_value}>
                                            {
                                                item.isVerify
                                                    ? <div>Verified</div>
                                                    : <div className={s.need_to_verify} onClick={() => startVerifying(item)}>
                                                        <Icon component={() => <Lock/>} alt="" rev={undefined}/>
                                                        Verify
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={s.row}>
                                    <div className={s.block}>
                                        <div className={s.label}>
                                            set main
                                        </div>
                                        <div className={s.list_block_value}>
                                            {
                                                item.isMain
                                                    ? <div>Main</div>
                                                    : <div className={item.isVerify ? s.set_as_main : s.disabled} onClick={() => item.isVerify && setMainPhone(item)}>
                                                        <div className={s.tick_svg}></div>
                                                        Set as main
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                    <div className={s.block}>
                                        <div className={s.label}>
                                            action
                                        </div>
                                        <div className={s.list_block_value}>
                                            <div className={classnames(s.flex, item.isMain ? s.disabled : s.action)} onClick={() => !item.isMain && deletePhone(item)}>
                                                <Icon component={() => <Basket/>} rev={undefined}/>
                                                <div className={item.isMain ? s.disabled : s.delete_text_active}>Delete</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className={s.button_wrapper}>
                <div className={s.button_block}>
                    <MyBtn
                        localDisable={props.phoneList?.length >= 5}
                        icon={plus}
                        title={'New phone'}
                        onClick={() => addNewPhone()}
                        personalAccount={true}
                    />
                </div>
            </div>

        </div>
    );
};

export default PhoneNumbers;

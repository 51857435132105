import React from 'react';
import styles from './PrivacyPolicy.module.css'
import s from "../../../styles/styles.module.css"
import classnames from "classnames";
import {motion} from 'framer-motion';

const PrivacyPolicy = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={classnames(s.wrapper, s.main_block)}>
                <div className={s.container}>
                    <div className={s.second_title}>
                        Privacy Agreement
                    </div>
                    <div className={classnames(s.policy_block, styles.policy_block)}>
                        <div className={s.policy_item}>
                            <span className={s.main_text}>
                                <p>Mondial Private IBU is committed to protecting all Personal Information obtained while conducting our business. We encourage
                                you to read this Privacy Agreement so that you can understand how we collect, use, share and protect your Personal Information.
                                This Privacy Agreement will continue to apply for as long as Mondial Private IBU holds your Personal Information, including
                                after the termination of any of your product or service agreements with us. By providing us with Personal Information, you
                                are consenting to the collection, use and sharing of your information as set out in this Privacy Agreement.</p>
                                <br/>
                                <p>This Agreement has been designed to comply with all applicable laws and explains:</p>
                                <br/>
                                <ul>
                                    <li>the types of Personal Information we collect.</li>
                                    <li>how your Personal Information is used.</li>
                                    <li>how your Personal Information may be shared.</li>
                                    <li>the steps we take to ensure your Personal Information is handled in a safe and secure manner.</li>
                                    <li>how you can access the personal information we retain and how to keep it accurate; and</li>
                                    <li>how to contact us with any privacy concerns.</li>
                                </ul>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                COLLECTING YOUR PERSONAL INFORMATION
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    Personal Information may be collected from you directly, from your interactions with us, and from other
                                    sources outside our organization, including from government agencies and registries, law enforcement
                                    authorities, public records, credit reporting agencies, other financial institutions, service providers,
                                    partners or third parties, agents and other organizations with whom you have arrangements or in accordance
                                    with their respective terms and conditions and/or privacy policies, or any other source, with your consent
                                    or as permitted or required by law. This information may be obtained by telephone, in writing or electronically.
                                    For legal entities such as businesses, partnerships, trusts, estates, or other organizations, we may collect
                                    information from each authorized person, partner, trustee, and executor, as appropriate.
                                </p>
                                <br/>
                                <p>
                                    You authorize the collection of Personal Information from these sources, and where applicable, you authorize
                                    these sources to provide us your Personal Information. When you apply for, provide a guarantee in respect of,
                                    or use any product or service, including when you register or use any of our online or mobile platforms, and
                                    while you are our current, prospective, or former customer, or act on behalf of one, we may collect and
                                    maintain Personal Information about you such as:
                                </p>
                                <br/>
                                <ul>
                                    <li>information establishing your identity (such as your name, address, email address, mobile telephone number,
                                    occupation, date of birth, and other identification documents, or images of such, required by law);</li>

                                    <li>information to authenticate your identity such as knowledge-based information (i.e. username, password and
                                    account information), biometric information (i.e. signature, fingerprint and voiceprint), device
                                    information (i.e. device model, browser type and activity, IP address, and approximate physical location
                                    of device) and images recorded by video surveillance at one of our locations;</li>

                                    <li>information about your financial status including your employment information and history, references,
                                    annual income, assets, liabilities, and credit history.</li>

                                    <li>business name, business address, business telephone number, business email address, name(s) of owner(s),
                                    officer(s) and director(s); industry type and financial status (for business clients).</li>

                                    <li>information reflecting your business dealings with us.</li>
                                </ul>
                                other information with your consent, or as permitted or required by law; and • additional information may be requested to help us determine due diligence.
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                USING YOUR PERSONAL INFORMATION
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    We may use this Personal Information for the purposes communicated to you at the time of collection or in your
                                    agreement(s) with us, including to:
                                </p>
                                <br/>
                                <ul>
                                    <li>verify your identity and to authenticate you when you contact us, or we contact you.</li>
                                    <li>evaluate and process, account, transaction, and reports.</li>
                                    <li>set up your accounts and provide ongoing service.</li>
                                    <li>provide you with products and services you have requested.</li>
                                    <li>communicate with you, including to respond to your inquiries.</li>
                                    <li>understand your product and service requirements.</li>
                                    <li>market a product or service, either directly or through a service provider, partner or third party.</li>
                                    <li>help manage and assess our risks, operations, and relationship with you.</li>
                                    <li>prevent, detect, or investigate fraud or criminal activity.</li>
                                    <li>maintain the accuracy and integrity of information and</li>
                                    <li>comply with legal, regulatory, governmental, and contractual requirements.</li>
                                </ul>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                SHARING YOUR PERSONAL INFORMATION
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    We may share your Personal Information:
                                </p>
                                <br/>
                                <ul>
                                    <li>with Mondial Private IBU businesses, affiliates, and associated companies in which we have significant shareholdings;</li>
                                    <li>
                                        with our employees, agents, service providers and any other entities that perform services on our behalf (collectively,
                                        'service providers'). You acknowledge that some of our service providers may be located outside of Canada and as such
                                        your Personal Information may be accessible to governmental, national security and regulatory authorities in accordance
                                        with the laws of these jurisdictions.
                                    </li>
                                    <li>with other financial institutions to process payments or to verify information such as your identity and income.</li>
                                    <li>with payment card networks to operate or administer the payment card system that supports the products, services or
                                        accounts you may have with us.</li>
                                    <li>
                                        in response to a judicial or administrative orders, subpoena, warrant or other demand or request we believe to be
                                        valid from governments, regulators, courts and law enforcement authorities in Canada or other jurisdictions or
                                        countries.
                                    </li>
                                    <li>to meet requests for information from regulators.</li>
                                    <li>to satisfy legal, audit, insurance, self-regulatory and regulatory requirements applicable to us.</li>
                                    <li>
                                        with any person or organization, including an investigative body, to prevent, detect or suppress financial abuse,
                                        fraud, money laundering, cyber threats, criminal activity, protect our assets and interests, assist us with any
                                        internal or external investigation into potentially illegal or suspicious activity or manage, defend or settle
                                        any actual or potential loss;
                                    </li>
                                    <li>with your consent or knowledge (e.g., with another individual on a joint account, a power of attorney or
                                        an estate representative).</li>
                                    <li>where permitted or required by law; and</li>
                                    <li>
                                        where you might be a victim of fraud, financial abuse or other illegal activity, or where we have reasonable
                                        grounds to believe your interests can best be served by taking action, we may share information with the
                                        appropriate legal or governmental authorities, your next of kin or any other appropriate person (e.g.,
                                        notification of our concerns regarding potential financial abuse may be provided to a public guardian or
                                        trustee’s office). In these circumstances, we will only share information we believe is reasonably necessary
                                        to protect your interests.
                                    </li>
                                </ul>
                                <br/>
                                <p>
                                    We do not sell the names or other Personal Information of our customers. We do not disclose the names or other
                                    Personal Information of our customers to other companies outside of Mondial Private IBU without consent, unless
                                    required or permitted by law. Over time, we may buy new businesses or sell some of our businesses. Accordingly,
                                    Personal Information associated with any accounts, products or services of the business being purchased or sold
                                    will be transferred as a business asset to the new business owner.
                                </p>
                                <br/>
                                <p>
                                    We may use affiliates or other companies to provide services on our behalf such as data processing, account administration,
                                    analytics, and marketing. Such companies will be given only the Personal Information needed to perform those services
                                    and we do not authorize them to use or disclose Personal Information for their own marketing or other purposes. We have
                                    or will have contracts in place holding these companies to the same standards of confidentiality by which we are governed.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                HOW WE OBTAIN YOUR CONSENT
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    It is important to understand the different ways that we may obtain your consent to collect, use and share your
                                    personal information. Depending on the situation, we may obtain your consent in different ways. Express consent
                                    may be obtained verbally, electronically or in writing. Implied consent may be obtained through your use of a
                                    product, or when you approach us to obtain information, inquire about or apply for products, or services from us.
                                </p>
                                <br/>
                                <p>
                                    We will not make your consent a condition of obtaining a product or service, unless it is reasonably or legally
                                    required, and we will clearly indicate when this is the case.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                PROTECTING YOUR PERSONAL INFORMATION
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    We have implemented administrative, technological, and physical safeguards to protect the security of your
                                    Personal Information under our control, regardless of the format in which it is held, against unauthorized
                                    access, disclosure, use or modification.
                                </p>
                                <br/>
                                <p>
                                    We train our staff on the importance of protecting the confidentiality of your information, and regularly
                                    confirm this expectation in writing. We authorize staff to have access to your personal information only
                                    on a need-to-know basis to perform their job functions.
                                </p>
                                <br/>
                                <p>
                                    To the extent that a service provider requires access to your personal information for an authorized purpose,
                                    we require the service provider to implement similar safeguards to protect the security of your Personal Information.
                                    We audit our procedures and security measures regularly to help ensure that they are being properly administered and
                                    that they remain effective and appropriate to the sensitivity of the information.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                RETAINING YOUR PERSONAL INFORMATION
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    We will retain your Personal Information only as long as it is required for the reasons it was collected. This may
                                    include customer service, legal or regulatory needs that require us to keep your Personal Information beyond the end of
                                    your relationship with us.
                                </p>
                                <br/>
                                <p>
                                    When your Personal Information is no longer required, we will securely destroy it or convert it to an anonymous form.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                ACCESSING AND UPDATING YOUR PERSONAL INFORMATION
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    You are responsible for advising Mondial Private IBU of any inaccuracies or changes to your Personal Information (such as
                                    a change of address, telephone numbers, current address, marital status, etc.). If any of your Personal Information
                                    changes or you discover inaccuracies in our data, you are required to advise us so we can update our records. Please
                                    be prepared to verify your identity when updating or changing your Personal Information.
                                </p>
                                <br/>
                                <p>
                                    Subject to contractual and legal restrictions as well as reasonable notice, you may request access to, correction or deletion
                                    of the Personal Information we hold about you at any time by submitting a written request to the Chief compliance Officer.
                                    Please be prepared to verify your identity upon making such a request. We will respond to your request within 30 days of
                                    receiving all necessary information or advise you if we require additional time.
                                </p>
                                <br/>
                                <p>
                                    Please note that we may not be able to provide you access to your Personal Information in certain situations (e.g.,
                                    if disclosing the information would reveal confidential commercial information or information about a third party).
                                    If this is the case, we will inform you of the reasons that we were unable to provide you access.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                RESPECTING YOUR PRIVACY PREFERENCE
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    Subject to legal, regulatory, and contractual requirements, you can refuse or withdraw your consent to our collection, use
                                    or disclosure of your Personal Information at any time by giving us reasonable notice. In certain circumstances, your
                                    consent cannot be withdrawn. For example, you may not withdraw your consent where our collection, use and sharing are
                                    permitted or required by law, is required to ensure we have correct and up-to-date information about you, such as
                                    current address or is necessary to manage our business including the sharing of information when we assign our rights
                                    to others for business transactions.
                                </p>
                                <br/>
                                <p>
                                    If you have, or have provided a guarantee for, a credit product with us (such as a mortgage or line of credit), you may
                                    not, during the term of the facility, withdraw your consent to our ongoing collection, use or disclosure of your Personal
                                    Information in connection with such product. We can continue to disclose your Personal Information to credit bureaus even
                                    after the credit facility has matured and been paid out and you may not withdraw your consent to our doing so. We do this to
                                    help maintain the accuracy, completeness, and integrity of the credit reporting system.
                                </p>
                                <p>
                                    We will act on your instructions as quickly as possible, but it may take some time for our records to reflect your choice
                                    as many records can only be changed during regular file updates.
                                </p>
                                <br/>
                                <p>
                                    There are several privacy preferences available to you, subject to legal, regulatory, business, or contractual requirements.
                                </p>
                                <br/>
                                <p>
                                    <strong>Marketing offers:</strong> From time to time, we may send you information which may be of interest to you on
                                    other products and services that we offer and of partnerships with other entities we select, by mail, email, telephone,
                                    or other electronic means. You may opt out of receiving promotional messages, but please note that we may still send you
                                    transactional or business messages (such as those about your account or our ongoing business relations).
                                </p>
                                <br/>
                                <p>
                                    <strong>Participation in customer research and surveys:</strong> From time to time, we may ask you to participate in customer
                                    research or surveys that help us to improve our offer and better understand your needs. You may opt out of participating.
                                </p>
                                <br/>
                                <p>
                                    <strong>Contacting Us</strong>
                                </p>
                                <p>
                                    To request access to, or correction of, your Personal Information, to ask questions about our privacy policies or to refuse
                                    or withdraw your consent to our use of your Personal Information for purposes outlined in this Agreement, you may send in
                                    a written request or contact us at (please note that requests for access must be in writing):
                                </p>
                                <br/>
                                <p>
                                    <strong>Further Information</strong>
                                </p>
                                <p>
                                    We are committed to providing you with understandable and easily available information about how we manage your Personal Information.
                                </p>
                                <br/>
                                <ul>
                                    <li>Chief compliance Officer</li>
                                    <li>Mondial Private IBU</li>
                                    <li>Email:&nbsp;
                                        <a className={s.link} href='mailto:info@mondialprivatemail.com' target={"_blank"}>
                                             info@mondialprivatemail.com
                                        </a>
                                    </li>
                                </ul>
                                <br/>
                                <p>
                                    For further information about how we collect, use, and disclose your personal information, please refer to our Code of Privacy
                                    Principles available at any of our offices and on our website at www.Mondial Private IBUltd.com.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                CHANGING THIS AGREEMENT
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    We may amend this Agreement and our other privacy-related documents from time to time to take into consideration
                                    changes in legislation or other issues that may arise at our sole discretion. Any changes we make to this Agreement
                                    will be effective when we post the revised Agreement on our websites. If we intend to use or disclose your Personal
                                    Information for purposes materially different from what is described in this Agreement, we will take reasonable steps
                                    to notify you. We encourage you to review the Privacy Agreement whenever you interact with us to stay informed about
                                    our information practices, as your continued use of our services or provision of Personal Information after changes
                                    have been made to this Agreement constitutes your acceptance of those changes. It is your responsibility to ensure
                                    that you read, understand, and accept the latest version of the policy. The date at the end of this policy indicates
                                    when it was last revised.
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default PrivacyPolicy;

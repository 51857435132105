import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IEmails, IPhoneNumber} from "../../types/interfaces";

type UserState = {
    error: any,
    user: userType,
    registrationWasSuccess: boolean | null,
    accountsList: any,
    cardInfo: cardInfoType,
    isLoading: boolean,
    disabled: boolean
    currencyExchange: any
    currencyExchangeResult: any
    tokenIsValid:boolean

}

type userType = {
    userToken: string,
    userData: userDataType,
    kycList: string[],
    phoneList: IPhoneNumber[],
    emailList: IEmails[],
    is2Fa: boolean,
    verificationsList: verificationListType
    flags: flagsType
}

type verificationListType = {
    emailVerifyEnabled: boolean,
    twoFaVerifyEnabled: boolean,
    phoneVerifyEnabled: boolean,
}

type flagsType = {
    kycStatus: string | null
}

type cardInfoType = {
    phone: string,
    email: string,
    cardHolder: string,
    cardNumber: string,
    san: string,
    cvv: string,
    expireDate: string,
    balance: number,
    type: string | number,
    typeText: string,
    status: string | number,
    statusText: string,
    shippingAddress: shippingAddressType,
    shippingTrackingNo: string,
    description: string
}

type shippingAddressType = {
    DelvAddress1: string,
    DelvCity: string,
    DelvCountyState: string | null,
    DelvCountryCode: string | null,
    DelvPostCode: string,
    Delivery: string,
}

export type userDataType = {
    address: string | null
    callPhone: string | null
    city: string | null
    clientId: string | null
    companyName: string | null
    country: string | null
    email: string | null
    fax: string | null
    firstName: string | null
    lastName: string | null
    phoneNumber: string | null
    postCode: string | null
    salutation: string | null
    stateOrProvince: string | null
    accountType: string | null

}

const initialState: UserState = {
    error: null,
    user: {
        verificationsList: {
            emailVerifyEnabled :true,
            twoFaVerifyEnabled :false,
            phoneVerifyEnabled :false,
        },
        flags: {
            kycStatus: null
        },
        userToken: "",
        userData: {
            address: "",
            callPhone: "",
            city: "",
            clientId: "",
            companyName: "",
            country: "",
            email: "",
            fax: "",
            firstName: "",
            lastName: "",
            postCode: "",
            phoneNumber: "",
            salutation: "",
            stateOrProvince: "",
            accountType: "business",
        },
        kycList: [],
        phoneList: [],
        emailList: [],
        is2Fa: false,
    },
    registrationWasSuccess: null,
    accountsList: [],
    cardInfo: {
        phone: "",
        email: "",
        cardHolder: "",
        cardNumber: "",
        san: "",
        cvv: "",
        expireDate: "",
        balance: 0,
        shippingAddress: {
            DelvAddress1: "",
            DelvCity: "",
            DelvCountyState: "",
            DelvCountryCode: "",
            DelvPostCode: "",
            Delivery: "",
        },
        shippingTrackingNo: "",
        type: "",
        typeText: "",
        status: "",
        statusText: "",
        description: ""
    },
    currencyExchange: {
        amount: '',
        to: '',
        from: ''
    },
    currencyExchangeResult: null,
    isLoading: false,
    disabled: true,
    tokenIsValid: true
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData(state, action) {
            state.user = action.payload
            state.error = null
        },
        userAuthorizationError(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
        signOut(state) {
            state.user.userToken = ""
            state.user.userData = {
                address: null,
                callPhone: null,
                city: null,
                clientId: null,
                companyName: null,
                country: null,
                email: null,
                fax: null,
                firstName: null,
                lastName: null,
                phoneNumber: null,
                postCode: null,
                salutation: null,
                stateOrProvince: null,
                accountType: 'personal'
            }
            state.error = null
            state.isLoading = false
            state.disabled = false
            state.accountsList = []
            state.cardInfo = {
                phone: "",
                email: "",
                cardHolder: "",
                cardNumber: "",
                san: "",
                cvv: "",
                expireDate: "",
                balance: 0,
                shippingAddress: {
                    DelvAddress1: "",
                    DelvCity: "",
                    DelvCountyState: "",
                    DelvCountryCode: "",
                    DelvPostCode: "",
                    Delivery: "",
                },
                shippingTrackingNo: "",
                type: "",
                typeText: "",
                status: "",
                statusText: "",
                description: "",
            }
            state.tokenIsValid = true
            localStorage.clear()
        },
        setAccountsList(state, action) {
            state.accountsList = action.payload
        },
        setRegistrationWasSuccess(state, action) {
            state.registrationWasSuccess = action.payload
        },
        setUserEmail(state, action) {
            state.user.userData.email = action.payload
        },
        setKycList(state, action) {
            state.user.kycList = action.payload
        },
        setCardInfo(state, action) {
            state.cardInfo = action.payload
        },
        setIs2Fa(state, action) {
            state.user.verificationsList.twoFaVerifyEnabled = action.payload
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setDisabled(state, {payload}) {
            state.disabled = payload
        },
        setCurrencyExchangeAmount(state, action) {
            state.currencyExchange.amount = action.payload
        },
        setCurrencyExchangeResult(state, action) {
            state.currencyExchangeResult = action.payload
        },
        setPhoneList (state, action) {
            state.user.phoneList = action.payload
        },
        setEmailList (state, action) {
            state.user.emailList = action.payload
        },
        setIsSmsCode (state, action) {
            state.user.verificationsList.phoneVerifyEnabled = action.payload
        },
        setIs2faCode (state, action) {
            state.user.verificationsList.twoFaVerifyEnabled = action.payload
        },
        setCurrencyExchangeTo (state, action) {
            state.currencyExchange.to = action.payload
        },
        setCurrencyExchangeFrom (state, action) {
            state.currencyExchange.from = action.payload
        },
        setNewEmail (state, action) {
            state.user.userData.email = action.payload
        },
        setTokenStatus (state, action) {
            state.tokenIsValid = action.payload
        }
    }
})

export const {setAccountsList, setCardInfo, setIsLoading, setDisabled, setCurrencyExchangeAmount, setPhoneList, setEmailList, setCurrencyExchangeResult, setCurrencyExchangeTo, setCurrencyExchangeFrom, setNewEmail, setTokenStatus} = userSlice.actions;

export default userSlice.reducer

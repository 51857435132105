import * as yup from "yup";


export const validationSchema = yup.object().shape({
    payerAccount: yup.string().required('Required field'),
    paymentRegions: yup.string().required('Required field'),
    recipientsAccountNumber: yup.string().required('Required field').max(40, 'max 40 symbols'),
    fullName: yup.string().required('Required field').max(90, 'max 90 symbols'),
    recipientsBank: yup.string().required('Required field').max(60, 'max 60 symbols'),
    recipientsBankCountry: yup.string().required('Required field'),
    recipientsBankAddress: yup.string().required('Required field').max(100, 'max 100 symbols'),
    reference: yup.string().test({message: 'One of the fields is required',
        test: function (value) {
        const { purpose } = this.parent;
        if (!purpose) return value !== undefined && value !== null && value !== '';
        return true
    },
    }).max(100, 'max 100 symbols'),
    purpose: yup.string().test({
        message: 'One of the fields is required',
        test: function (value) {
            const {reference} = this.parent;
            if (!reference) return value !== undefined && value !== null && value !== '';
            return true
        },
    }).max(100, 'max 100 symbols'),
    recipientsSwiftBic: yup.string().required('Required field').max(12, 'max 12 symbols'),
    recipientsAddress: yup.string().required('Required field').max(100, 'max 100 symbols'),
    email: yup.string().email("invalid format"),
    templateName: yup.string().max(100, 'max 100 symbols'),
    paymentTypeByRegionForm: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {paymentRegions} = this.parent;
            if (paymentRegions === "PAN AFRICA") return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
},
    [ [ 'reference', 'purpose' ] ]);

/*
export const validationSchemaNGNBank = yup.object({
    payerAccount: yup.string().required('Required field'),
    paymentRegions: yup.string().required('Required field'),

    lastName: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankCode: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    recipientsBankCountry: yup.string().required('Required field'),
    reference: yup.string().required('Required field').max(100, 'max 100 symbols'),
    purpose: yup.string().required('Required field').max(100, 'max 100 symbols'),
    amount: yup.string()
        .matches(/^[0-9/,]+$/, "Must be only digits")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    if (item === '0') {
                        return true
                    }
                })

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols'),
})*/

// Common fields that appear in multiple schemas
const commonFields = {
    payerAccount: yup.string().required('Required field'),
    paymentRegions: yup.string().required('Required field'),
    paymentTypeByRegionForm: yup.string().required('Required field'),
    firstName: yup.string().required('Required field').max(90, 'max 90 symbols'),
    lastName: yup.string().required('Required field').max(90, 'max 90 symbols'),
    reference: yup.string().test({message: 'One of the fields is required',
        test: function (value) {
            const { purpose } = this.parent;
            if (!purpose) return value !== undefined && value !== null && value !== '';
            return true
        },
    }).max(100, 'max 100 symbols'),
    purpose: yup.string().test({
        message: 'One of the fields is required',
        test: function (value) {
            const {reference} = this.parent;
            if (!reference) return value !== undefined && value !== null && value !== '';
            return true
        },
    }).max(100, 'max 100 symbols'),
    email: yup.string().email("invalid format"),
    paymentDate: yup.string().required('Required field'),
    templateName: yup.string().max(100, 'max 100 symbols'),
};

export const validationSchemaAza  = yup.object({
    ...commonFields,
});

export const validationSchemaNGNBank = yup.object({
    ...commonFields,
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
});

export const validationSchemaNGNMobile = yup.object({
    ...commonFields,
    phoneNumber: yup.string().required('Required field')
});

export const validationSchemaNGNUSDBank = yup.object({
    ...commonFields,
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    phoneNumber: yup.string().required('Required field').max(90, 'max 90 symbols'),
});

export const validationSchemaGHSBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
});

export const validationSchemaMobile = yup.object({
    ...commonFields,
    phoneNumber: yup.string().required('Required field'),
    mobileProvider:  yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaCash = yup.object({
    ...commonFields,
    phoneNumber: yup.string().required('Required field'),
});

export const validationSchemaUGXBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    street: yup.string().required('Required field').max(90, 'max 90 symbols'),
    city: yup.string().required('Required field').max(90, 'max 90 symbols'),
    postalCode: yup.string().max(90, 'max 90 symbols'),
    identityCardId: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    branchCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaUGXMobile = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    phoneNumber: yup.string().required('Required field'),
    mobileProvider:  yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaEUR = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    recipientsIban: yup.string().required('Required field'),
    recipientsBank: yup.string().required('Required field'),
    recipientsSwiftBic: yup.string().required('Required field')
});

export const validationSchemaUSDBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    bankAccount: yup.string().required('Required field'),
    bankAccountType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    recipientsBank: yup.string().required('Required field'),
    routingNumber: yup.string().required('Required field').max(9, 'max 9 symbols'),
    recipientsSwiftBic: yup.string().required('Required field')
});

export const validationSchemaMADCash = yup.object({
    ...commonFields,
    phoneNumber: yup.string().required('Required field'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    identityCardType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    identityCardId: yup.string().required('Required field').max(90, 'max 90 symbols'),
});

export const validationSchemaXOFCash = yup.object({
    ...commonFields,
    recipientCountry: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    phoneNumber: yup.string().required('Required field'),
    cashProvider: yup.string().required('Required field').max(90, 'max 90 symbols'),
});

export const validationSchemaXOFMobile = yup.object({
    ...commonFields,
    recipientCountry: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    mobileProvider: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    phoneNumber: yup.string().required('Required field'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaXBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    recipientsIban: yup.string().required('Required field'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaZARBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    street: yup.string().max(90, 'max 90 symbols'),
    city: yup.string().max(90, 'max 90 symbols'),
    postalCode: yup.string().max(90, 'max 90 symbols'),
    email: yup.string().required('Required field').email("invalid format"),
    phoneNumber: yup.string().required('Required field'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    transferReason: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
});

export const validationSchemaBWPBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    recipientsAccountNumber: yup.string().required('Required field'),
    recipientsBank: yup.string().required('Required field'),
    branchCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaPix = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    city: yup.string().required('Required field').max(90, 'max 90 symbols'),
    postalCode: yup.string().required('Required field').max(90, 'max 90 symbols'),
    pixKeyType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    pixKeyValue: yup.string().required('Required field'),
    identityCardId: yup.string().required('Required field'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaTed = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    city: yup.string().required('Required field').max(90, 'max 90 symbols'),
    postalCode: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    branchCodeTED: yup.string().required('Required field'),
    bankAccount: yup.string().required('Required field'),
    bankAccountType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    identityCardId: yup.string().required('Required field'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaZMWBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    branchCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaKESBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    street: yup.string().required('Required field').max(90, 'max 90 symbols'),
    city: yup.string().max(90, 'max 90 symbols'),
    identityCardType: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
    identityCardId: yup.string(),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    transferReason: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
    relationshipToSender: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
});

export const validationSchemaKESMobile = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    street: yup.string().required('Required field').max(90, 'max 90 symbols'),
    city: yup.string().max(90, 'max 90 symbols'),
    identityCardType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    identityCardId: yup.string().required('Required field'),
    phoneNumber: yup.string().required('Required field').max(90, 'max 90 symbols'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    mobileProvider: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    relationshipToSender: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
});

export const validationSchemaEGPBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    street: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field'),
    phoneNumber: yup.string().required('Required field').max(90, 'max 90 symbols'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

import s from "./ConfirmModal.module.css";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import CustomModal from "../../../../components/ui/CustomModal/CustomModal";
import TransparentButton from "../../../../components/ui/TransparentButton/TransparentButton";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {deleteTemplateThunk, getTemplateListThunk} from "../../../../store/reducers/ActionCreators";



interface ConfirmModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>,
  showModal: boolean,
  TemplateForDelete: {
    id: number,
    name: string
  }
}

export const ConfirmModal = ({setShowModal, showModal, TemplateForDelete}: ConfirmModalProps) => {

  const token = useAppSelector(state => state.userReducer.user.userToken)
  const dispatch = useAppDispatch()

  return (
    <CustomModal type={'error'} setIsModalOpen={setShowModal} isModalOpen={showModal} children={
      <div className={s.modalContent}>

        <h3 className={s.modalTitle}>Are you sure?</h3>
        <h4 className={s.modalSubtitle}>Your template <b>{TemplateForDelete.name}</b> will be deleted.</h4>

        <div className={s.buttonsWrapper}>
          <div className={s.buttonBlock}>
            <TransparentButton buttonType={'error'} medium onClick={() => {
              dispatch(deleteTemplateThunk(token, TemplateForDelete.id))
              setShowModal(false)
            }} title={'Yes'}/>
          </div>
          <div className={s.buttonBlock}>
            <MyBtn buttonType={'error'} medium title={'No'} onClick={() => {
              setShowModal(false)
            }}/>
          </div>

        </div>
      </div>
    }/>
  )
}


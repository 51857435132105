import React from 'react';
import styles from './KYCAMLPolicy.module.css'
import s from "../../../styles/styles.module.css"
import classnames from "classnames";
import {motion} from 'framer-motion';

const KYCAMLPolicy = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={classnames(s.wrapper, s.main_block)}>
                <div className={s.container}>
                    <div className={s.second_title}>
                        Mondial Private Bank (IBU) LTD AML Policies
                    </div>
                    <div className={classnames(s.policy_block, styles.policy_block)}>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                TURKEY – LAW AND REGULATIONS
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    Since 1991, Turkey is one of the 33 members of Financial Action Task Force (FATF) – an intergovernmental body 
                                    for development and promotion of national and international policies to combat money laundering and terrorist 
                                    financing - and has established all necessary legal arrangements in accordance with the recommendations of FATF.
                                </p>
                                <br/>
                                <p>
                                    "Prevention of Money Laundering" law no. 4208 has been enacted and issued in 1995 to ensure that financial 
                                    institutions are subject to adequate regulation and supervision and are effectively implementing FATF 
                                    recommendations. Under the subject law, “Financial Crimes Investigation Board” (MASAK) (www.masak.gov.tr) 
                                    operating under the Ministry of Finance was established in 1997 as the supervisory authority for money-laundering 
                                    crimes and matters.
                                </p>
                                <br/>
                                <p>
                                    In 1998, MASAK joined Egmont Group – established in 1995 in Brussels with the participation of 24 countries and 8 
                                    international organizations -. The Group now consists of 94 countries, all having financial intelligence units to 
                                    combat money laundering.
                                </p>
                                <br/>
                                <p>
                                    Current Law on Prevention of Laundering Proceeds of Crime Dated 11/10/2006 and No.5549 and its regulations. 
                                    The purpose of this law is to determine the principles and procedures for prevention of laundering proceeds of crime.
                                </p>
                                <br/>
                                <p>
                                    MASAK also serves as a central national intelligence unit. It collects, analyses and reports to the relevant 
                                    authorities all financial data and information about suspicious activities.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                SUMMARY OF MONDIAL PRIVATE BANK (IBU) LTD ANTI-MONEY LAUNDERING AND SUSPICIOUS ACTIVITIES POLICY
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    Activities of MONDIAL PRIVATE BANK (IBU) LTD and its branches strictly comply with the Prevention of Laundering 
                                    Proceeds of Crime Law dated 11/10/2006 Law no: 5549. Mondial Private Bank (IBU) Ltd fully complies with reporting 
                                    requirements in accordance with the related laws and regulations.
                                </p>
                                <br/>
                                <p>
                                    In line with the subject legislation, MONDIAL PRIVATE BANK (IBU) LTD extended its internal regulations to ensure 
                                    full compliance with anti-money laundering. Our policy also covers relationships with politically exposed persons.
                                </p>
                                <br/>
                                <p>
                                    MONDIAL PRIVATE BANK (IBU) LTD, under its written Anti-Money Laundering and Suspicious Activities Policy, 
                                    conducts its business incorporating the following principles:
                                </p>
                                <br/>
                                <ul>
                                    <li>developing institutional policy and procedures;</li>
                                    <li>carrying out risk management activities;</li>
                                    <li>carrying out monitoring and controlling;</li>
                                    <li>activities;</li>
                                    <li>carrying out training activities; Mondial Private Bank (IBU) Ltd has an established employee training program about money laundering and terrorist financing prevention Furthermore, eLearning program is available on Bank’s intranet, and staff is regularly informed on suspicious transactions through circular letters;</li>
                                    <li>Customer Identification/due diligence;</li>
                                    <li>Maintaining and updating information;</li>
                                    <li>Suspicious transaction reporting;</li>
                                    <li>Compliance Officer in charge of AML policies;</li>
                                    <li>The Bank’s compliance with anti-money laundering program is tested through periodic internal Audits.</li>
                                </ul>
                                <br/>
                                <p>
                                    MONDIAL PRIVATE BANK (IBU) LTD does not keep anonymous accounts and takes necessary measures to prevent account 
                                    openings under fictitious names. MONDIAL PRIVATE BANK (IBU) LTD does not provide services to shell banks. The 
                                    Bank conducts constant customer due diligence to verify customer identification.
                                </p>
                                <p>Accounts can only be opened by authorized staff.</p>
                                <br/>
                                <p>
                                    MONDIAL PRIVATE BANK (IBU) LTD checks existing customer transactions against lists of terrorists provided by authorities.
                                </p>
                                <br/>
                                <p>
                                    If any suspicious activity is noticed, such as high volume money transfers, or any information that may lead to money 
                                    laundering activities is obtained, the identification of the customer and the related transactions are immediately 
                                    reported to the Financial Crimes Investigation Board. Regarding funds transfers, both senders’ and receivers’ names 
                                    & addresses, and account numbers are required for all incoming and outgoing transactions.
                                </p>
                                <br/>
                                <p>
                                    MONDIAL PRIVATE BANK (IBU) LTD maintains transaction records and related documents for 10 years.
                                </p>
                                <br/>
                                <p>
                                    MONDIAL PRIVATE BANK (IBU) LTD pays special attention to all complex, unusual high volume transactions and as a policy 
                                    does not open accounts for foreign banks having no physical presence in any country.
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default KYCAMLPolicy;

import { ABOUT, 
         PERSONAL, 
         CORPORATE, 
         INVESTMENTS, 
         CONTACT_US } from "../../Constants/RoutesConstants";
import React, { useEffect, useState } from 'react';
import style from './header.module.css'
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import MyBtn from "../ui/MyBtn/MyBtn";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from '../../assets/newImg/publicPages/logo.svg';
import logo1 from '../../assets/newImg/Logo.svg';
import { useAppSelector } from "../../hooks/redux";
import s from "../../pages/PersonalAccount/PersonalAccount.module.css";

const Header = () => {

    const navigate = useNavigate()
    const isLogin = useAppSelector(state => state.userReducer.user.userToken)
    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)
    const firstName: string | null = useAppSelector(state => state.userReducer.user.userData.firstName)
    const lastName: string | null = useAppSelector(state => state.userReducer.user.userData.lastName)
    const companyName: string | null = useAppSelector(state => state.userReducer.user.userData.companyName)

    const location = useLocation();

    const [isHeaderShow, setIsHeaderShow] = useState(true)

    const kycIsValid = () => {
        if (!kycStatus) {
            return false
        } else if (kycStatus === "New") {
            return false
        } else if (kycStatus === "Rejected") {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {

        if (
            location.pathname.includes("sign-in") ||
            location.pathname.includes("sign_up") ||
            location.pathname.includes("personal_account")
            ) {
            setIsHeaderShow(false)
        } else {
            setIsHeaderShow(true)
        }
    },[location.pathname])

    return (
        <>
            {isHeaderShow && <header className={style.container}>
            <div className={style.container_body} >
                <div className={style.container_body_content}>
                <div className="">
                    <Link className={style.header_home_link} to="/">
                        <img src={logo1} alt="" className={"logo"} width={111} height={64}/>
                    </Link>
                </div>
                <div className={style.block}>
                    <Link className={location.pathname === '/' ? style.header_link_active : style.header_link} to='/'>Home</Link>
                    <Link className={location.pathname.includes("about") ? style.header_link_active : style.header_link} to={ABOUT}>About</Link>
                    <Link className={location.pathname.includes("personal") ? style.header_link_active : style.header_link} to={PERSONAL}>Personal</Link>
                    <Link className={location.pathname.includes("corporate") ? style.header_link_active : style.header_link} to={CORPORATE}>Corporate</Link>
                    <Link className={location.pathname.includes("investments") ? style.header_link_active : style.header_link} to={INVESTMENTS}>Investments</Link>
                    <Link className={location.pathname.includes("contact-us") ? style.header_link_active : style.header_link} to={CONTACT_US}>Contact us</Link>
                </div>
                <div className={style.buttons}>
                    {
                        !!isLogin
                            ? kycIsValid() ? <div className={s.user_round} onClick={() => navigate('/personal_account/accounts')}>
                                                { firstName?.slice(0,1)}{lastName?.slice(0,1)}{companyName?.slice(0,1)}
                                            </div> 
                                           : <div className={s.user_round} onClick={() => navigate('/personal_account/personal/settings')}>
                                                { firstName?.slice(0,1)}{lastName?.slice(0,1)}{companyName?.slice(0,1)}
                                            </div>
                            : <div className={style.buttons_group}>
                                <TransparentButton title={"Sign In"} onClick={() => navigate('/sign-in')} large />
                                <MyBtn title={"Open account"} onClick={() => navigate('/sign_up')} large />

                                {/* <TransparentButton title={"Sign In"} onClick={() => navigate('/')} large />
                                <MyBtn title={"Open account"} onClick={() => navigate('/')} large /> */}
                              </div> 
                    }
                </div>
                </div>
                </div>
            </header>}
        </>

    );
};

export default Header;

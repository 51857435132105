import React, {useEffect, useState} from 'react';
import styles from './Footer.module.css'
import {Link, useLocation } from "react-router-dom";
import logo from '../../assets/newImg/Logo.svg'
import facebook from '../../assets/newImg/publicPages/facebook.svg'
import instagram from '../../assets/newImg/publicPages/instagram.svg'
import linkedin from '../../assets/newImg/publicPages/linkedin.svg'
import whatsapp from '../../assets/newImg/publicPages/whatsapp.svg'
import telegram from '../../assets/newImg/publicPages/telegram.svg'
import mail from '../../assets/newImg/publicPages/mail_footer.svg'
import {
    ABOUT,
    BANKS_HISTORY,
    PERSONAL,
    CORPORATE,
    PRIVACY_POLICY,
    KYC_AML_POLICY,
    TERMS_AND_CONDITIONS,
} from "../../Constants/RoutesConstants";
import classnames from "classnames";

const Footer = () => {

    const location = useLocation()
    // const navigate = useNavigate()
    const [isFooterShow, setIsFooterShow] = useState(true)

    useEffect(() => {
        if (
            location.pathname.includes("sign-in") ||
            location.pathname.includes("sign_up") ||
            location.pathname.includes("personal_account")
        ) {
            setIsFooterShow(false)
        } else {
            setIsFooterShow(true)
        }
    },[location.pathname])

    return (
        <>
            {
                isFooterShow && <footer className={styles.footer_wrapper}>
                    <Link to={'/'}>
                        <div className={styles.logo_block}>
                            <img src={logo} alt={""} width={161} height={96}/>
                        </div>
                    </Link>

                    <div className={classnames(styles.links_block, styles.desktop_links_block)}>
                        <div className={styles.links_column}>
                            <span className={styles.links_title}>Company</span>
                            <Link className={classnames(styles.link, styles.link_hover)} to={ABOUT}>About Us</Link>
                            <Link className={classnames(styles.link, styles.link_hover)} to={BANKS_HISTORY}>Bank's History</Link>
                        </div>

                        <div className={styles.links_column}>
                            <span className={styles.links_title}>Services</span>
                            <Link className={classnames(styles.link, styles.link_hover)} to={PERSONAL}>Personal</Link>
                            <Link className={classnames(styles.link, styles.link_hover)} to={CORPORATE}>Corporate</Link>
                        </div>

                        <div className={styles.links_column}>
                            <span className={styles.links_title}>Legal</span>
                            <Link className={classnames(styles.link, styles.link_hover)} to={PRIVACY_POLICY}>Privacy Policy</Link>
                            <Link className={classnames(styles.link, styles.link_hover)} to={KYC_AML_POLICY}>KYC/AML Policy</Link>
                            <Link className={classnames(styles.link, styles.link_hover)} to={TERMS_AND_CONDITIONS}>Terms and Conditions</Link>
                        </div>

                        <div className={styles.links_column}>
                            <span className={styles.links_title}>Contact Us</span>
                            <div className={styles.link_contact}>
                                <img src={whatsapp} alt=""  />
                                <a href="https://api.whatsapp.com/send?phone=35796783056" target={"_blank"}>+35796783056</a>
                            </div>
                            <div className={styles.link_contact}>
                                <img src={telegram} alt="" />
                                <a href="https://t.me/mondialibu" target={"_blank"}>@mondialibu</a>
                            </div>
                            <div className={styles.link_contact}>
                                <img src={mail} alt="" />
                                <a href="mailto:info@mondialprivatemail.com" target={"_blank"}>
                                    info@mondialprivatemail.com
                                </a>
                            </div>
                        </div>

                        <div className={styles.links_column}>
                            <span className={styles.links_title}>Follow Us</span>
                            <div className={styles.icons_block}>
                                <div className={styles.icons}>
                                    <a href="https://www.facebook.com/mondialoffshorebank" target={"_blank"}>
                                        <img src={facebook} alt={""} width={40} height={40}/>
                                    </a>
                                    <a href="https://www.linkedin.com/company/mondial-offshore-bank/?viewAsMember=true" target={"_blank"}>
                                        <img src={linkedin} alt={""} width={40} height={40}/>
                                    </a>
                                    <a href="https://www.instagram.com/mondial_offshore_bank/" target={"_blank"}>
                                        <img src={instagram} alt={""} width={40} height={40}/>
                                    </a>
                                </div>
                                <div className={styles.copyright}>
                                    © 2023 Mondial Offshore Bank. <br />
                                    All rights reserved.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classnames(styles.links_block, styles.mobile_footer)}>
                        <div className={styles.links_row}>
                            <div className={styles.links_column}>
                                <span className={styles.links_title}>Company</span>
                                <Link className={classnames(styles.link, styles.link_hover)} to={ABOUT}>About Us</Link>
                                <Link className={classnames(styles.link, styles.link_hover)} to={BANKS_HISTORY}>Bank's History</Link>
                            </div>

                            <div className={styles.links_column}>
                                <span className={styles.links_title}>Services</span>
                                <Link className={classnames(styles.link, styles.link_hover)} to={PERSONAL}>Personal</Link>
                                <Link className={classnames(styles.link, styles.link_hover)} to={CORPORATE}>Corporate</Link>
                            </div>

                            <div className={styles.links_column}>
                                <span className={styles.links_title}>Legal</span>
                                <Link className={classnames(styles.link, styles.link_hover)} to={PRIVACY_POLICY}>Privacy Policy</Link>
                                <Link className={classnames(styles.link, styles.link_hover)} to={KYC_AML_POLICY}>KYC/AML Policy</Link>
                                <Link className={classnames(styles.link, styles.link_hover)} to={TERMS_AND_CONDITIONS}>Terms and Conditions</Link>
                            </div>
                        </div>

                        <div className={styles.links_row}>
                            <div className={styles.links_column}>
                                <span className={styles.links_title}>Contact Us</span>
                                <div className={styles.link_contact}>
                                    <img src={whatsapp} alt="" />
                                    <a href="https://api.whatsapp.com/send?phone=35796783056" target={"_blank"}>+35796783056</a>
                                </div>
                                <div className={styles.link_contact}>
                                    <img src={telegram} alt="" />
                                    <a href="https://t.me/mondialibu" target={"_blank"}>
                                        @mondialibu
                                    </a>
                                </div>
                                <div className={styles.link_contact}>
                                    <img src={mail} alt="" />
                                    <a href="mailto:info@mondialprivatemail.com" target={"_blank"}>
                                        info@mondialprivatemail.com
                                    </a>
                                </div>
                            </div>

                            <div className={styles.links_column}>
                                <span className={styles.links_title}>Follow Us </span>
                                <div className={styles.icons_block}>
                                    <div className={styles.icons}>
                                        <a href="#" target={"_blank"}>
                                            <img src={facebook} alt={""} width={40} height={40}/>
                                        </a>
                                        <a href="#" target={"_blank"}>
                                            <img src={linkedin} alt={""} width={40} height={40}/>
                                        </a>
                                        <a href="#" target={"_blank"}>
                                            <img src={instagram} alt={""} width={40} height={40}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.links_row}>
                            <div className={styles.copyright}>
                                © 2023 Mondial Offshore Bank. <br />
                                All rights reserved.
                            </div>
                        </div>
                    </div>



                </footer>
            }
        </>

    );
};

export default Footer;

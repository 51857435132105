import React, {useEffect, useState} from 'react';
import s from "./EmailAuntification.module.css";
import MyBtn from "../../ui/MyBtn/MyBtn";
import {useFormik} from "formik";
import {motion} from 'framer-motion';
import {loginVereficationThunk} from "../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import CustomModal from "../../ui/CustomModal/CustomModal";
import MyInput from "../../ui/MyInput/MyInput";
import {useNavigate} from "react-router-dom";
import {zeroFill} from "../../../helpers/ZeroFill";
import {setDisabled} from "../../../store/reducers/UserSlice";
import attention from "../../../assets/newImg/attention.svg";

export type EmailVereficationDataType = {
    email: string | null,
    code: string
}

const EmailAuntification = (props: any) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const userEmail = useAppSelector(state => state.userReducer.user.userData.email)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPinValid, setIsPinValid] = useState(true)

    let timer: string | number | NodeJS.Timeout | undefined
    const startTimer = () => {
        timer = setTimeout(() => navigate('/sign-in'), 6000);
    }

    useEffect(() => {
        return clearTimeout(timer)
    }, [])

    const formikLogin = useFormik({
        initialValues: {
            email: userEmail,
            code: '',
        },
        onSubmit: values => {
            dispatch(loginVereficationThunk(values))
                .then(() => {
                    setIsModalOpen(true)
                    startTimer()
                })
                .catch(() => {
                setIsPinValid(false)
                })
                .finally(() => {
                    dispatch(setDisabled(false))
                })
        }
    })

    const [time, setTime] = useState(120)
    const [timeIsOver, setTimeIsOver] = useState(false)

    const tick = () => {
        if (time === 0) {
            setTimeIsOver(true)
            navigate("/sign-in")
            return
        }
        setTime(prevState => prevState - 1)
    }
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;

    React.useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });

    const closeAndRedirect = () => {
        setIsModalOpen(false)
        navigate("/sign-in")
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                <div className={s.flex}>
                    <div className={s.modal_title}>
                        Success!
                    </div>
                    <div className={s.modal_subtitle}>
                        Registration completed successfully. In a few seconds you will be redirected to the sign up page.
                    </div>
                    <div className={s.button_block}>
                        <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.sign_in_wrapper}>
                <form onSubmit={formikLogin.handleSubmit}>
                    {
                        !isPinValid &&
                        <div className={s.error_block}>
                            <img src={attention} alt=""/>
                            <div>
                                Invalid email code
                            </div>
                        </div>
                    }
                    <div className={s.sign_in_content}>
                        <div className={s.title}>
                            Email confirmation
                        </div>
                        <div className={s.subtitle}>
                            The password was sent to your email address
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                confirmation Code
                            </div>
                            <MyInput
                                id="code"
                                name="code"
                                isError={!isPinValid}
                                value={formikLogin.values.code}
                                onChange={formikLogin.handleChange}
                                placeholder={"6 digits"}
                            />
                        </div>
                        <div className={s.timer_wrapper}>
                            {timeIsOver
                                ? <div></div>
                                : <div> {zeroFill(minutes, 2)} : {zeroFill(seconds, 2)}</div>
                            }
                        </div>
                        <div className={s.btn_block}>
                            <MyBtn title={"Authentication"} type={"submit"}/>
                        </div>
                    </div>
                    {/*<div className={s.dont_have_account}>*/}
                    {/*    <div>*/}
                    {/*        Back to*/}
                    {/*    </div>*/}
                    {/*    <Link className={s.link} to={"#"}>Sign up</Link>*/}
                    {/*</div>*/}
                </form>
            </div>
        </motion.div>
    );
};

export default EmailAuntification;

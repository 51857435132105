import React from 'react';
import styles from './About.module.css'
import s  from "../../../styles/styles.module.css"
import classnames from "classnames";
import { motion } from 'framer-motion';

const About = () => {

    const values = [
        {
            id: 1,
            value: 'Client Focus',
        },
        {
            id: 2,
            value: 'Integrity and Ethics',
        },
        {
            id: 3,
            value: 'Innovation',
        },
        {
            id: 4,
            value: 'Global Expertise',
        },
    ]

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={classnames(s.wrapper, s.main_block)}>
                <div className={s.title}>
                    <div className={s.main_title}>
                        Mondial Private Bank
                    </div>
                    <div className={s.back_title}>
                        Mondial Private Bank
                    </div>
                </div>
                <div className={s.container}>
                    <div className={styles.about_text}>
                        <div className={styles.about_text_body}>
                            Established with a vision to redefine banking, Mondial Private Bank has grown into a leading institution 
                            known for its commitment to excellence and client satisfaction. With a global presence and a keen 
                            understanding of the nuances of offshore banking, we strive to maintain the highest standards of 
                            integrity, discretion, and innovation.
                        </div>
                    </div>
                    <div className={styles.about_content}>
                        <div className={styles.about_content_img}>
                            Our Mission
                        </div>
                        <div className={styles.about_content_text}>
                            <div>To empower our clients by offering state-of-the-art financial services with a personal touch.</div>
                        </div>
                    </div>
                    <div className={styles.about_values}>
                        <div className={s.title}>
                            <div className={s.main_title}>
                                our values
                            </div>
                            <div className={s.back_title}>
                                our values
                            </div>
                        </div>
                        <div className={styles.about_values_content}>
                            {values.map(item => {
                                return <div key={item.id} className={styles.about_values_item}>
                                            <div className={styles.about_values_number}>
                                                0{item.id}
                                            </div>
                                            <div className={styles.about_values_text}>
                                                {item.value}
                                            </div>
                                       </div>
                            })}
                        </div>
                    </div>
                    <div className={styles.about_picture}>
                        Learn more about our journey, values, and how we can assist you
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default About;

import React, {ReactNode} from 'react';
import s from './TransparentButton.module.css'
import {useAppSelector} from "../../../hooks/redux";
import {Button, ConfigProvider} from 'antd';
import classnames from "classnames";

type PropsType = {
    title: string,
    onClick?: (e?: any) => void;
    type?: "button" | "submit" | "reset" | undefined;
    style?: React.CSSProperties;
    icon?: ReactNode;
    large?: boolean;
    medium?: boolean;
    small?: boolean;
    buttonType?: 'confirm' | 'error';
    personalAccount?: boolean
}

const TransparentButton: React.FC<PropsType> = ({type, title, icon, large, medium, personalAccount, small, buttonType, ...props}) => {
    const {disabled} = useAppSelector(store => store.userReducer)
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: 'transparent',
                // colorBorder: '#2046A1',
                // colorPrimaryHover: '#89C0E9',
                motionEaseInOut: '0.3s'
            }
        }}>
            <Button
                htmlType={type}
                disabled={disabled}
                icon={icon}
                className={classnames(s.button, {
                    [s.large]: large,
                    [s.medium]: medium,
                    [s.small]: small,
                    [s.confirm]: buttonType === 'confirm',
                    [s.error]: buttonType === 'error',
                    [s.disabled]: disabled,
                    [s.personalAccount]: personalAccount,
                })}
                {...props}
            >
                {title}
            </Button>
        </ConfigProvider>
    );
};

export default TransparentButton;

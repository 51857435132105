import React from 'react';
import styles from './TermsAndConditions.module.css'
import s from "../../../styles/styles.module.css"
import classnames from "classnames";
import {motion} from 'framer-motion';

const TermsAndConditions = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={classnames(s.wrapper, s.main_block)}>
                <div className={s.container}>
                    <div className={s.second_title}>
                        Terms and Conditions
                    </div>
                    <div className={classnames(s.policy_block, styles.policy_block)}>
                        <div className={s.policy_item}>
                            <span className={s.main_text}>
                                <p>
                                    The Client (“Client” or “you” / “You”) on one hand and, Mondial Private IBU Ltd. ("Mondial Private IBU”), Clients
                                    are kindly requested to read this Agreement thoroughly and carefully and to contact Mondial Private IBU if anything
                                    is unclear, either by email at <a className={s.link} href='mailto:info@mondialprivatemail.com' target={"_blank"}>info@mondialprivatemail.com</a>
                                    &nbsp;or by phone calling Mondial Private IBU’s customer
                                    support phone number, available during office hours.
                                </p>
                                <br/>
                                <p>
                                    In case Mondial Private IBU needs to contact the Clients, the contact details provided upon registration will be used. It is the
                                    Clients responsibility to keep details up to date and to inform Mondial Private IBU as soon as possible if anything changes
                                    with regards to details.
                                </p>
                                <br/>
                                <p>
                                    In 1998, MASAK joined Egmont Group – established in 1995 in Brussels with the participation of 24 countries and 8
                                    international organizations -. The Group now consists of 94 countries, all having financial intelligence units to
                                    combat money laundering.
                                </p>
                                <br/>
                                <p>
                                    <strong>By using Mondial Private IBU’s Services and/or Website, you confirm that you accept and agree to this Agreement
                                    (including with the documents referred to herein). If you do not agree, please do not use Mondial Private IBU’s Services.</strong>
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                1. INTRODUCTION AND PURPOSE
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    The Services Framework Agreement (the “Agreement”) sets out the general terms and conditions applicable to the
                                    relationship between the Client and Mondial Private IBU; they are supplemented by the pricing which can be found on
                                    Mondial Private IBU’s Website.
                                </p>
                                <br/>
                                <p>
                                    Any term agreed individually between the Client and Mondial Private IBU shall prevail over the terms of this
                                    Agreement, unless stated otherwise or contrary to any applicable law.
                                </p>
                                <br/>
                                <p>
                                    The present general terms and conditions govern the way in which Mondial Private IBU provides Services to its
                                    Clients. Clients should read these conditions carefully before accepting them.
                                </p>
                                <br/>
                                <p>
                                    This Agreement will be available at all times to the Client on the website, as amended, supplemented, restated
                                    or replaced from time to time in accordance with the terms of this Agreement and specifically as stated in clause 19.
                                </p>
                                <br/>
                                <p>
                                    A signed copy of this Agreement can be sent upon request against payment of a fee and shipping costs. The most
                                    recent and current version will be updated on Mondial Private IBU’s Website as mentioned above.
                                </p>
                                <p>The terms and conditions set out in this Agreement apply as between Clients and Mondial Private IBU with respect
                                    to the Services provided by Mondial Private IBU to Clients.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                2. DEFINITIONS
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    <strong>Applicant:</strong> a person or legal entity who is requesting to be onboarded with Mondial Private IBU and therefore to
                                    become a Client. An Applicant shall become a Client upon successful processing of his request.
                                </p>
                                <br/>
                                <p>
                                    <strong>Client:</strong> person or legal entity with a Profile issued by Mondial Private IBU that enables
                                    them to send and/or receive payments.
                                </p>
                                <br/>
                                <p>
                                    <strong>Framework Agreement:</strong> a Services agreement concluded between Mondial Private IBU and the Client,
                                    comprising the general terms and conditions set herein as well as the pricing conditions which can be found on Mondial
                                    Private IBU’s Website.
                                </p>
                                <br/>
                                <p>
                                    <strong>Mondial Private IBU’s Website:</strong> refers to the all pages of the websites through which Mondial
                                    Private IBU provides its Services.
                                </p>
                                <br/>
                                <p>
                                    <strong>Payment Order:</strong> approval from the Client granted in accordance with the terms of this Agreement
                                    unless customised plan and procedures have been agreed between the Client and Mondial Private IBU, in order to authorise
                                    a Payment Transaction.
                                </p>
                                <br/>
                                <p>
                                    <strong>Payment Transaction:</strong> an action involving the wiring, transfer, or withdrawal of funds through
                                    a IBAN, regardless of any underlying obligation between the payer and the beneficiary.
                                </p>
                                <br/>
                                <p>
                                    <strong>Profile:</strong> A profile opened with Mondial Private IBU under which IBANs and/or reference
                                    numbers are linked. The Profile will record:
                                </p>
                                <br/>
                                <ul>
                                    <li>incoming and outgoing Payment Transactions;</li>
                                    <li>the fees owed by the Client to Mondial Private IBU; and</li>
                                    <li>any chargebacks related to the said Payment Transactions.</li>
                                </ul>
                                <br/>
                                <p>
                                    The Profile also allows for the recording of settlements received into or sent from the safeguarding account,
                                    of the amounts mentioned above.
                                </p>
                                <br/>
                                <p>
                                    <strong>Provision:</strong> safeguarded amount that may be allocated to the execution of future Payment
                                    Transactions, as determined by Mondial Private IBU.
                                </p>
                                <br/>
                                <p>
                                    <strong>Services:</strong> services provided by Mondial Private IBU under the present Agreement, including but
                                    not limited to the execution of Payment Transactions and the acquisition of Payment Orders by transfer.
                                </p>
                                <br/>
                                <p>
                                    <strong>IBAN:</strong> an IBAN, or reference number, used for the purpose of reconciliation of incoming payments.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                3. OPENING PROFILE
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    The Applicant must comply with the conditions of opening a Profile, as described below.
                                </p>
                                <br/>
                                <p>
                                    The individual making the application, declares and undertakes that they are doing so freely and of their
                                    own free will and that the profile is to be used exclusively by that individual.
                                </p>
                                <br/>
                                <p>
                                    The individual, if making the application on behalf of a legal entity, declares and undertakes that they
                                    are fully authorised by the board of directors of that entity to make the application on its behalf.
                                </p>
                                <br/>
                                <p>
                                    The Client must use the services provided by Mondial Private IBU in good faith, solely for legal purposes and
                                    in accordance with the provisions of this Agreement.
                                </p>
                                <br/>
                                <p>
                                    Upon receipt of the required information and documents from the Applicant, Mondial Private IBU will review the
                                    application and reserves its right to request additional information and/or documents as deemed necessary.
                                </p>
                                <br/>
                                <p>
                                    Once Mondial Private IBU considers that all relevant information and documentation has been collected, Mondial
                                    Private IBU will have 3 business days from which to make a decision on the provision of services to an applicant.
                                </p>
                                <br/>
                                <p>
                                    <strong>3.1 Acceptance/refusal to open a Profile</strong>
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU, at its own discretion, with no right or compensation to the client, may reject an application
                                    to provide services to an applicant, for any reason, including but not limited to:
                                </p>
                                <br/>
                                <ul>
                                    <li>
                                        breach of a term or condition of this agreement, which may result in Mondial Private IBU terminating
                                        the agreement with the client in accordance with clause 18 of the same
                                    </li>
                                    <li>
                                        an event related to the risk of fraud, money laundering, terrorist financing, bribery corruption or any
                                        other financial crime;
                                    </li>
                                    <li>an event having the possibility to affect the client security; and</li>
                                    <li>
                                        the client being found to be or being accused of being engaged in any activity that could harm the
                                        reputation of Mondial Private IBU.
                                    </li>
                                </ul>
                                <br/>
                                <p>
                                    Acceptance and refusals shall be notified to the Applicant by Mondial Private IBU using the email address provided by the Applicant upon registration.
                                </p>
                                <br/>
                                <p>
                                    Refusals shall not result in the payment of damages from Mondial Private IBU to the Client.
                                </p>
                                <br/>
                                <p>
                                    <strong>3.2 Suspension/Termination of Services</strong>
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU, retains the right to suspend or terminate services throughout the duration of the business
                                    relationship for any reason, including but not limited to:
                                </p>
                                <br/>
                                <ul>
                                    <li>
                                    a breach of a term or condition set forth in this agreement, which may result in Mondial Private IBU terminating the agreement
                                    with the client in accordance with clause 18 of the same
                                    </li>
                                    <li>
                                    an event related to the risk of fraud, money laundering, terrorist financing, bribery corruption or any other financial crime;
                                    </li>
                                    <li>an event having the possibility to affect the client security; and</li>
                                    <li>
                                    the client being found to be or being accused of being engaged in any activity that could harm the reputation of Mondial Private IBU.
                                    </li>
                                </ul>
                                <br/>
                                <p>
                                    Termination and suspension shall be notified to the application by Mondial Private IBU using the email address provided
                                    by the applicant upon registration.
                                </p>
                                <br/>
                                <p>
                                    Termination shall not result in the payment of damages from Mondial Private IBU to the Client.
                                </p>
                                <br/>
                                <p>
                                    In instances where the decision to suspend services were not made in error and were based on legitimate grounds of
                                    inquiry and/or procedural adherence, this shall not result in the payment of damages from Mondial Private IBU to the client.
                                </p>
                            </span>
                        </div>

                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                4. USING MONDIAL PRIVATE IBU’S SERVICES
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    <strong>4.1 General rules</strong>
                                </p>
                                <br/>
                                <p>
                                    As soon as the Profile has been opened the Applicant automatically becomes a Client and shall be provided with
                                    personal login credentials to access their Profile’s information by the following link&nbsp;
                                    <a href='https://mondialprivatebank.finance/' className={s.link}  target={"_blank"}>Mondial Private Bank (IBU) Ltd. (mondialprivatebank.finance)</a>&nbsp;
                                    or using any other webpage as otherwise advised by Mondial Private IBU.
                                </p>
                                <br/>
                                <p>
                                    It is the Client’s responsibility to take all reasonable steps to retain said login credentials and keep them safe.
                                </p>
                                <br/>
                                <p>
                                    An individual applicant undertakes to not share login credentials with any third party. A legal entity applicant
                                    undertakes to only share the login credentials with individuals within the legal entity that have been given
                                    authority to make payments on its behalf.
                                </p>
                                <br/>
                                <p>
                                    Clients shall use their Profiles for lawful and honest purposes and shall not use their Profiles:
                                </p>
                                <br/>
                                <ul>
                                    <li>
                                        contrary to any restriction stated in this Agreement, or otherwise in a way that is not expressly
                                        permitted by this Agreement;
                                    </li>
                                    <li>
                                        in connection with a criminal offence under applicable laws or regulations or against public order
                                        or applicable ethical standards and codes; and
                                    </li>
                                    <li>
                                        or any unlawful purpose whatsoever, including but not limited to fraud or terrorism.
                                    </li>
                                </ul>
                                <br/>
                                <p>
                                    <strong>4.2 Processing by Mondial Private IBU</strong>
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU might ask Clients for additional information and/or documentation before processing a
                                    Payment Transaction. Clients will be given 24 hours to answer such requests, further to which Mondial
                                    Private IBU shall send an additional request granting an additional 24 hours to Clients to provide
                                    the requested information and/or documentation.
                                </p>
                                <br/>
                                <p>
                                    Payment Transactions shall be executed upon receipt of satisfactory information and/or
                                    documentation from the Clients.
                                </p>
                                <br/>
                                <p>
                                    Failure to answer 48 hours after the first request has been made will result in the Payment Transaction
                                    being rejected by Mondial Private IBU.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU reserves its rights to reject any Payment Transaction further to its review.
                                </p>
                                <br/>
                                <p>
                                    Clients acknowledge and agree that Mondial Private IBU has restricted the use of countries that certain
                                    payment rails will be available for. The list may change from time to time and the latest version will
                                    be available online at&nbsp;<a href='https://mondialprivatebank.finance/' className={s.link} target={"_blank"}>Mondial Private Bank (IBU) Ltd. (mondialprivatebank.finance)</a>.
                                    Any incoming or outgoing
                                    Payment Transaction from or to a country listed as restricted shall be automatically rejected.
                                </p>
                                <br/>
                                <p>
                                    In any case, Mondial Private IBU shall not be held liable by the Client, or any other party connected to it,
                                    for any expense, damage, claim, costs, loss, or equivalent resulting from the rejection of the Payment Transaction.
                                </p>
                                <br/>
                                <p>
                                    <strong>4.3 Inactive Profiles</strong>
                                </p>
                                <br/>
                                <p>
                                    A Profile will be deemed to be inactive or dormant if no activity happens during a consecutive period of 90 days.
                                    Such inactive Profiles shall be closed by Mondial Private IBU further to giving the Client 60 days’ notice by
                                    email at the address provided by the Client upon registration.
                                </p>
                                <br/>
                                <p>
                                    The notice shall inform the Client of the safeguarded amount available and of the method of refund of the said
                                    amount. The Client shall be entitled to advise Mondial Private IBU of an earlier date of closure of the Profile
                                    until the end of the notice period.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                5. RECONCILIATION OF INCOMING PAYMENTS
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    The Client shall be able to:
                                </p>
                                <br/>
                                <p>
                                    reconcile incoming payments using their virtual IBAN or the respective reference. Mondial Private IBU will
                                    reconcile the funds as soon as possible depending on the payment rails that have been used and any requests
                                    for additional information and/or documentation as detailed in clause 4.2, and no later than by the end of
                                    the working day the funds were received by Mondial Private IBU.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                6. PROCESSING OF OUTGOING PAYMENTS
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    Mondial Private IBU provides Services that enables Clients to instruct Mondial Private IBU to carry out
                                    Payment Transfers from the safeguarded balance to a third party.
                                </p>
                                <br/>
                                <p>
                                    The Clients shall login to their Profile using the credentials provided by Mondial Private IBU to initiate
                                    and authorize any outgoing Payment Transaction.
                                </p>
                                <br/>
                                <p>
                                    The request for authorization will be prompted by the receipt of a One Time Passcode (“OTP”) issued to the Client’s
                                    mobile phone number as provided to MEP by the Client at the time of submitting their application, or by MEP’s
                                    third-party authentication tool issued to the mobile phone number as provided to MEP by the Client at the time
                                    of submitting their application. The OTP will need to be entered into the payment page to initiate and authorise
                                    the Payment Transaction.
                                </p>
                                <br/>
                                <p>
                                    The registered mobile phone number can be amended at the Client’s request as applicable, and evidence of
                                    the ownership of the replacement mobile number will need to be provided to Mondial Private IBU.
                                </p>
                                <br/>
                                <p>
                                    Payment Transfers shall only be accepted and processed by Mondial Private IBU, when the Provision of the Profile is
                                    greater than the total transfer amount (fees included). In the event that the Provision is insufficient, the Payment
                                    Order will automatically be rejected.
                                </p>
                                <br/>
                                <p>
                                    The available Provision corresponds to the safeguarded balance recorded on the Profile, excluding the blocked
                                    Provision and outstanding Payment Transactions.
                                </p>
                                <br/>
                                <p>
                                    The Payment Order must include the following information, constituting the Payment Details:
                                </p>
                                <br/>
                                <ul>
                                    <li>the amount;</li>
                                    <li>the surname and forename of the beneficiary;</li>
                                    <li>the beneficiary’s address;</li>
                                    <li>the beneficiary’s IBAN.</li>
                                </ul>
                                <br/>
                                <p>
                                    Mondial Private IBU shall not be held liable when the Payment Details included in the Payment Order requests by
                                    the Client are incorrect or not up to date.
                                </p>
                                <br/>
                                <p>
                                    With all payments MEP will include the name of the remitting party and the virtual IBAN/reference to allow for
                                    the reconciliation on the recipient’s end.
                                </p>
                                <br/>
                                <p>
                                    <strong>6.1 Cancellation of a Payment Order</strong>
                                </p>
                                <br/>
                                <p>
                                    Clients are permitted to cancel any authorised Payment Transaction within a reasonable time from its execution, but
                                    Mondial Private IBU does not guarantee that the cancellation will take effect. Each cancellation shall be
                                    individually assessed.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                7. REPORTING
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    All Payment Transactions are displayed within the Profile and the statements are available. The statement is
                                    updated in real time.
                                </p>
                                <br/>
                                <p>
                                    Clients may use their personal login credentials to consult their Profile statements on Mondial Private IBU’s
                                    Website by following link
                                    &nbsp;<a href='https://mondialprivatebank.finance/' className={s.link} target={"_blank"}>Mondial Private Bank (IBU) Ltd. (mondialprivatebank.finance)</a>&nbsp;
                                    or using any other webpage as otherwise advised by Mondial Private IBU.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU shall not be held liable when the Payment Details included in the Payment Order requests by
                                    the Client are incorrect or not up to date.
                                </p>
                                <br/>
                                <p>
                                    <ul>
                                        <li>for at least 5 years after the end of the business relationship;</li>
                                        <li>for at least 5 years from the date of settlement of the last transaction.</li>
                                    </ul>
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                8. CONTRACT DURATION AND DATE OF EFFECT
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    This Agreement comes into effect for an indefinite period of time and as soon as the Client has accepted it.
                                </p>
                                <br/>
                                <p>
                                    Acceptance happens as soon as the Client has been onboarded and started using the Services. Any use of the
                                    Services implies acceptance of the terms of this Agreement.
                                </p>
                                <br/>
                                <p>
                                    Clients being individuals acting mainly as well as wholly outside their trade, business, craft, or profession
                                    when using the Profiles are classified as Consumers and benefit from the protections attached to it in accordance
                                    with the Consumer Rights Act 2015, as amended from time to time. Clients being legal entities and individuals
                                    acting on a professional capacity are excluded from the definition of Consumers.
                                </p>
                                <br/>
                                <p>
                                    Consumers benefit from a cooling-off period of fourteen (14) calendar days starting from the day of acceptance
                                    of this Agreement, to withdraw from this Agreement.
                                </p>
                                <br/>
                                <p>
                                    All withdrawals shall be free of charge, fees, penalty, or any other payment due by Consumers Clients to Mondial
                                    Private IBU. The right to withdraw can be exercised without providing any justification to Mondial Private IBU.
                                </p>
                                <br/>
                                <p>
                                    A notice of withdrawal shall be made by email to Mondial Private IBU by the Client at the following address:&nbsp;
                                    <a href="mailto:info@mondialprivatemail.com" className={s.link} target={"_blank"}>info@mondialprivatemail.com</a>.
                                    Any notice sent to the address email above shall be deemed to have been duly given on the date of transmission.
                                </p>
                                <br/>
                                <p>
                                    For the avoidance of doubt, it is noted that the Services to be provided by Mondial Private IBU under this Agreement
                                    shall not start until the end of the cool-off period or express request received from the Consumers Clients.
                                </p>
                                <br/>
                                <p>
                                    The Client expressly acknowledges and accepts that any Payment Order sent to Mondial Private IBU prior to the
                                    expiration of the cooling-off period, constitutes an express request of opting out the cooling-off period and
                                    therefore terminating the said period and any right and protection attached.
                                </p>
                                <br/>
                                <p>
                                    Further to such request to provide Services sent by the Client to Mondial Private IBU, the Client shall not be entitled to
                                    any right of withdrawal during the remaining days of the cooling-off period.
                                </p>
                                <br/>
                                <p>
                                    Absent any request of withdrawal during the cooling-off period, this Agreement shall continue and be governed by the
                                    provisions set out in the present Agreement until terminated by the Client or Mondial Private IBU contrary to clause 18 below.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                9. FEES
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    In return for providing the Client with Services, Mondial Private IBU shall receive remuneration, the amount and
                                    conditions of which can be found on Mondial Private IBU’s Website by following this link: &nbsp;
                                    <a href='https://mondialprivatebank.finance/' className={s.link} target={"_blank"}>Mondial Private Bank (IBU) Ltd. (mondialprivatebank.finance)</a>.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU shall deduct its charges and transfer fees prior to each payment being sent to the
                                    recipient or being reconciled through the IBAN to the safeguarding account.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU may amend the prices which can be found on Mondial Private IBU’s Website by following
                                    this link:&nbsp;<a href='https://mondialprivatebank.finance/' className={s.link} target={"_blank"}>Mondial Private Bank (IBU) Ltd. (mondialprivatebank.finance)</a>.
                                    Such amendment shall take effect two
                                    months further to the notification of the new pricing being sent to the Client.
                                </p>
                                <br/>
                                <p>
                                    Any agreement in the form of a fee letter or similar regarding the fees accepted between Mondial Private
                                    IBU and the Client shall prevail the pricing set out on Mondial Private IBU’s Website.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                                10. SECURITY
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    <strong>10.1 Notification obligation</strong>
                                </p>
                                <br/>
                                <p>
                                    It is the Client's duty to immediately inform Mondial Private IBU of any suspected fraudulent access or use of
                                    their Profile or of any event that is likely to result in such a use, including but not limited to: loss,
                                    accidental disclosure or hacking of their Profile login details or a non-authorised transaction.
                                </p>
                                <br/>
                                <p>
                                    Any notice or information sent by email to
                                    &nbsp;<a href='https://mondialprivatebank.finance/' className={s.link} target={"_blank"}>Mondial Private Bank (IBU) Ltd. (mondialprivatebank.finance)</a>&nbsp;
                                    shall be deemed to have been duly given on the date of the transmission.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU requires two factor authentications to be completed upon logging into the profile
                                    and executing transactions on the profile.
                                </p>
                                <br/>
                                <p>
                                    <strong>10.2 Prevention</strong>
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU shall use all reasonable effort to prevent any fraudulent use of the Profiles. Mondial Private
                                    IBU requires two factor authentications to be completed upon logging into the profile and executing transactions
                                    on the profile. This is designed to prevent any illegitimate profile access.
                                </p>
                                <br/>
                                <p>
                                    <strong>10.3 Disruption to Payment Services</strong>
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU shall endeavor to provide a reliable, and continuous service. However, Mondial Private IBU does
                                    not guarantee such continuous, uninterrupted access to the Services and shall not be held liable for any delay,
                                    total or partial inaccessibility or other inconvenience related to the Services if such disruptions were caused
                                    by factors beyond Mondial Private IBU’s reasonable control.
                                </p>
                                <br/>
                                <p>
                                    Clients are hereby informed that Mondial Private IBU may occasionally interrupt access to all or part of the
                                    Services in order to carry out repairs, maintenance, or improvements, in the event of a suspected hacking attempt,
                                    embezzlement or any other security risk, upon request or instructions from competent, qualified individuals or authorities.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU shall not be liable for consequential, direct, indirect, or special losses, damages, or
                                    equivalent whether directly or indirectly caused by the interruption of the Services.
                                </p>
                                <br/>
                                <p>
                                    As soon as the Services will be restored, Mondial Private IBU shall use all reasonable efforts to process all pending
                                    Payment Transactions as quickly as possible.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU shall not, under any circumstances, be held liable for indirect damages, such as commercial
                                    harm, loss of customers, commercial disruption, loss of profit or damage to brand image suffered by a Client or
                                    third party, that may have resulted from the Services it provides. Any action brought against a client by a third
                                    party will be treated as indirect damage and therefore will not entitle the former to compensation.
                                </p>
                                <br/>
                                <p>
                                    Unless otherwise stipulated in this Agreement or mandatory laws, and without causing prejudice to other grounds
                                    for excluding or limiting responsibility defined by this contract, Mondial Private IBU shall not, under any circumstances,
                                    be held responsible for any damage caused by a force majeure event or event beyond its control.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            11. PROTECTION OF CUSTOMER FUNDS
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    In compliance with the Payment Services Directive 2 (“PSD 2”) Mondial Private IBU will forward all payments to the
                                    safeguarding account the moment funds are received. In cases where MEP is processing payments through other EMIs
                                    that cannot safeguard funds, the process of getting the money from a credit institution to the EMI and back
                                    again could be time consuming.
                                </p>
                                <br/>
                                <p>
                                    MEP speeds up this process by depositing money with the credit institution directly, and topping up and debiting
                                    the segregated client account as required to ensure that the money in the segregated client account matches
                                    the amount of “relevant funds”.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU’s safeguarding policy and details of the ring-fencing mechanism of the said accounts shall
                                    be provided by Mondial Private IBU to Clients upon request.
                                </p>
                                <br/>
                                <p>
                                    All IBANs are issued on top of the safeguarding account. All funds sent through IBANs are automatically sent
                                    to the safeguarding account.
                                </p>
                                <br/>
                                <p>
                                    Clients’ funds are safeguarded and protected against any claims from creditors of Mondial Private IBU,
                                    including enforcement and insolvency proceedings.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            12. CONFIDENTIALITYS
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    The Client shall respect the strictest confidentiality regarding all technical, commercial, or other
                                    information to which he may become privy to through the receipt of the Services.
                                </p>
                                <br/>
                                <p>
                                    Each of Mondial Private IBU and the Client, agrees that it will keep confidential and not disclose,
                                    whether directly or indirectly, any confidential information belonging to the other.
                                </p>
                                <br/>
                                <p>
                                    Unless otherwise required under applicable laws this duty of confidentiality will remain in effect
                                    for the duration of the Client's subscription to the Payment Service and for five (5) years
                                    following the termination of this Agreement.
                                </p>
                                <br/>
                                <p>
                                    This duty of confidentiality does not apply to information that:
                                </p>
                                <br/>
                                <ul>
                                    <li>is or may become available to the public;</li>
                                    <li>obtained from a third party who is free to disclose it;</li>
                                    <li>a party is required by law to disclose to any governmental or regulatory authority or
                                        other party as required.</li>
                                </ul>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            13. COLLECTION AND PROCESSING OF PERSONAL DATA
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    Mondial Private IBU’s Privacy Policy is available at
                                    &nbsp;<a href='https://mondialprivatebank.finance/' className={s.link} target={"_blank"}>Mondial Private Bank (IBU) Ltd. (mondialprivatebank.finance)</a>.
                                </p>
                                <br/>
                                <p>
                                    Any personal information that Clients provide to us will be dealt with in line with Mondial Private IBU’s
                                    Privacy Policy which explains what information is collected from Clients, how and why Mondial Private IBU
                                    collects, stores, uses and shares such information.
                                </p>
                                <br/>
                                <p>
                                    The Privacy Policy also provide for Client’s rights in relation to their personal information as well
                                    as information on how to contact Mondial Private IBU and supervisory authorities in case of query or
                                    complaint about the use of their personal information.
                                </p>
                                <br/>
                                <p>
                                    This clause shall have effect as if it incorporates the provisions of the Privacy Policy
                                    which Clients acknowledge and accept.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            14. PROBATIVE VALUE
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    All information saved in Mondial Private IBU's computer databases regarding Payment Orders and Payment
                                    Transactions have, until proven otherwise, the same probative value as a hand-signed paper copy, both
                                    in terms of their content and the date and time they were produced and/or received. These unalterable,
                                    secure, and reliable traces are embedded and saved within Mondial Private IBU's computer systems.
                                </p>
                                <br/>
                                <p>
                                    Documents held by Mondial Private IBU that replicate this information, as well as copies or reproductions
                                    of documents produced by Mondial Private IBU, have the same probative value as the originals,
                                    unless proven otherwise.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            15. FRAUD PREVENTION
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    Mondial Private IBU takes fraud very seriously and will always endeavor to mitigate the risk of fraud to the
                                    best of its capabilities.
                                </p>
                                <br/>
                                <p>
                                    Clients are made aware that criminals are experts at impersonating people, organizations, and various
                                    authorities, including the police. This could be done online, via email or through telephone calls. Mondial
                                    Private IBU would like to highlight some of the ways Clients can avoid becoming victims of fraud.
                                </p>
                                <br/>
                                <p>
                                    <strong>15.1 What Mondial Private IBU will do to protect Clients</strong>
                                </p>
                                <br/>
                                <p>
                                    Checks on Payments Transactions through IBANs is part of Mondial Private IBU’s fraud prevention measures.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU may take whatever action is considered appropriate to meet any obligations, either in the UK
                                    or elsewhere in the world relating to the prevention of fraud, crime, money laundering or terrorist activity
                                    and the provision of financial and other services to persons who may be subject to sanctions.
                                </p>
                                <br/>
                                <p>
                                    This may include, but is not limited to, investigating and intercepting Payment Transactions sent through
                                    IBANs and investigating the intended payer or payee or source of funds. Checks to identify and verify identities
                                    of Clients and connected persons, as well as screening for financial and embargoes or other sanctions are also
                                    part of Mondial Private IBU’s fraud prevention process.
                                </p>
                                <br/>
                                <p>
                                    Carrying out the verifications mentioned above may delay the processing of payment instructions or the receipt
                                    of cleared funds, but where practicable and permitted, Clients shall be advised of the reasons for any delay
                                    and shall be kept updated.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU may decline to process a Payment Transaction through a IBAN where it is reasonably believed
                                    that such Payment Transaction is unlawful.
                                </p>
                                <br/>
                                <p>
                                    In circumstances where a payment is made through a IBAN and Mondial Private IBU, the paying Payment Service Provider
                                    or our banking partners know or suspect that it was made fraudulently or as a result of fraud, Mondial Private IBU may:
                                </p>
                                <br/>
                                <ol>
                                    <li>refuse to process any Payment Transaction relating to the recipient, including an instruction by the Client to pay
                                        the money away from the safeguarded balance; and/or</li>
                                    <li>take the money back out of the safeguarded balance without asking the Client and pay it back to sender, paying
                                        bank or relevant banking partner.</li>
                                </ol>
                                <br/>
                                <p>
                                    Without prejudice to the generality of clause 16, Mondial Private IBU shall not be responsible to the Clients or any
                                    third party for any loss, expense, costs, damage or other liability suffered as a result of any delay to or rejection
                                    of the processing of a Payment Transaction further to Mondial Private IBU’s fraud prevention measures set out above,
                                    or as a result of the payment back of any money known or suspected to have been paid through a IBAN fraudulently or
                                    as a result of a fraud.
                                </p>
                                <br/>
                                <p>
                                    <strong>15.2 What Clients can do to protect themselves</strong>
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU advises its clients to:
                                </p>
                                <br/>
                                <ul>
                                    <li>not transfer money to a bank account whose details don’t match the ones provided by Mondial Private IBU;</li>
                                    <li>make sure that correct telephone number and email address are updated on their profile as and when necessary;</li>
                                    <li>avoid sending emails on public Wi-Fi;</li>
                                    <li>keep devices updated with current anti-virus software, latest browser versions and relevant security patches and updates;</li>
                                    <li>never install any software, or grant remote access to a computer as the result of a cold call;</li>
                                    <li>stay alert – fraudsters may make contact pretending to be from Mondial Private IBU, a bank, the police or
                                        another trusted organization and ask to transfer money to another account or to disclose personal confidential
                                        information. A genuine organization will never make contact to make such requests;</li>
                                    <li>be aware of phishing emails or texts, and specifically not to open any links or attachments within them;
                                        and of emails or text messages claiming to be from a person at Mondial Private IBU asking for private information or
                                        asking to make a payment. If such unexpected email or text message is received from Mondial Private IBU, notification
                                        of the same should be made either by email at
                                        &nbsp;<a href='https://mondialprivatebank.finance/' className={s.link} target={"_blank"}>Mondial Private Bank (IBU) Ltd. (mondialprivatebank.finance)</a>&nbsp;
                                        or by phone calling Mondial Private IBU’s customer
                                        support phone number.</li>
                                    <li>contact their bank and Mondial Private IBU in case of suspicion of fraudulent activity on their bank accounts.</li>
                                </ul>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            16. LIABILITY
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    The extent of Mondial Private IBU’s liability under or in connection with this Agreement (regardless of whether such
                                    liability arises in tort, contract or in any other way and whether or not caused by negligence or misrepresentation)
                                    shall be set out in this clause 16.
                                </p>
                                <br/>
                                <p>
                                    <strong>16.1 Limitation of liability</strong>
                                </p>
                                <br/>
                                <p>
                                    Subject to sub clause 16.2, Mondial Private IBU shall not be held liable for any consequential, indirect or
                                    special losses, nor for any of the following (whether direct or indirect) loss of profit, use of data that
                                    is not protected under data protection regulations as explained under the terms of the Privacy Policy,
                                    production, contract, opportunity, savings, discounts or rebate (whether actual or anticipated), or of
                                    goodwill and other harm to reputation.
                                </p>
                                <br/>
                                <p>
                                    Except as expressly stated in this Agreement and subject to subclause 16.2, all warranties and conditions
                                    whether express or implied by statute, common law or otherwise are excluded to the extent permitted by law.
                                </p>
                                <br/>
                                <p>
                                    <strong>16.2 Liability</strong>
                                </p>
                                <br/>
                                <p>
                                    Notwithstanding any other provision of this Agreement, Mondial Private IBU’s liability shall not be limited in
                                    any way in respect of death or personal injury caused by negligence; fraud or fraudulent misrepresentation; any
                                    other losses which cannot be excluded or limited by applicable law.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            17. RISK WARNING
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    <strong>17.1 General</strong>
                                </p>
                                <br/>
                                <p>
                                    Any and all of the Services, software, confidential information and any other technology or materials provided
                                    by Mondial Private IBU to the Clients are provided “as is” and without warranty of any kind. Except as otherwise
                                    expressly stated in section 16, Mondial Private IBU does not warrant or represent that its Services will be uninterrupted,
                                    fit for a particular purpose, error-free, reliable, or secure at all times, despite using reasonable efforts to provide
                                    secured Services.
                                </p>
                                <br/>
                                <p>
                                    Clients acknowledge that there are risks inherent in internet connectivity that could result in the loss of personal
                                    data, confidential information, and property.
                                </p>
                                <br/>
                                <p>
                                    Clients accept to use the Payments Services at their own risks and without holding Mondial Private IBU responsible
                                    for any damage resulting thereby.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU does not provide any investment, trading, financial, tax, legal or any other kind of advice and
                                    disclaim all responsibility resulting from a loss occurring further to any kind of investment incurred by Clients.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU shall never be a deemed to be a third party to any investment, brokerage, or other payment agreement made
                                    between the Clients and their beneficiaries.
                                </p>
                                <br/>
                                <p>
                                    No information whether oral or written obtained from Mondial Private IBU or through the use of the Services shall create any
                                    warranty or representation.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            18. TERMINATION OF THE FRAMEWORK AGREEMENT
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    <strong>18.1 Notice of termination</strong>
                                </p>
                                <br/>
                                <p>
                                    The client may automatically terminate this Agreement, which will result in the closure of their Profiles,
                                    by sending a notification by email to the following email address:
                                    &nbsp;<a href='mailto:info@mondialprivatemail.com' className={s.link} target={"_blank"}>info@mondialprivatemail.com</a> with a
                                    month's notice.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU may decide to terminate this Agreement and close the Virtual Account as a result, by
                                    sending a notification by email to the Virtual Account Holder's email address with a two months’ notice.
                                </p>
                                <br/>
                                <p>
                                    In the event of a modification to applicable regulations and their interpretation by the relevant
                                    regulatory authority that may affect the ability of Mondial Private IBU or its authorised
                                    representatives to carry out Payment Transactions, this Agreement will automatically be
                                    terminated without notice.
                                </p>
                                <br/>
                                <p>
                                    <strong>18.2 Gross negligence</strong>
                                </p>
                                <br/>
                                <p>
                                    In the event of gross negligence by either Mondial Private IBU or the Client, this Agreement may be
                                    terminated with immediate effect by written notification sent by email from the prevailing party.
                                </p>
                                <br/>
                                <p>
                                    Gross negligence by the Client is understood to mean: communication of false information; engaging
                                    in illegal activity, contravening standards of public decency; money laundering or financing of
                                    terrorism; threats to agents of Mondial Private IBU; defaulted payment; failure to comply with
                                    an obligation of this contact; excessive debt or, for legal entities, the nomination of a
                                    special mediator and insolvency administrator to initiate rehabilitation or liquidation proceedings.
                                </p>
                                <br/>
                                <p>
                                    Gross negligence by Mondial Private IBU is understood to mean: communication of fraudulent
                                    representation; failure to comply with an obligation of the terms and conditions set out in
                                    this Agreement; the nomination of a special mediator and insolvency administrator to initiate
                                    rehabilitation or liquidation proceedings.
                                </p>
                                <br/>
                                <p>
                                    <strong>18.3 Consequence of termination</strong>
                                </p>
                                <br/>
                                <p>
                                    Clients may no longer send Payment Orders after the Termination Notice has been issued. Profiles
                                    may be maintained for a period of 15 months for the purpose of dealing with possible subsequent
                                    disputes and complaints. Payment Transactions initiated before the Termination Notice has been
                                    issued will not be affected by the termination request and shall be fulfilled as far as possible
                                    under the terms of this Agreement.
                                </p>
                                <br/>
                                <p>
                                    Termination of this Agreement will result in the permanent closure of the Profile. The closure
                                    of a Profile will not give rise to any compensation, regardless of any possible damage caused
                                    by said closure.
                                </p>
                                <br/>
                                <p>
                                    Any Client whose Profile has been closed by Mondial Private IBU, shall not be authorised,
                                    unless explicitly authorised by the latter, to open another Profile. Any Profile opened in
                                    violation of this provision may be immediately closed by Mondial Private IBU, without notice.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            19. MODIFICATION OF THE FRAMEWORK AGREEMENT
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    Any amendment to the Framework Agreement shall be communicated by email to the existing Clients at
                                    the time of the amendment and no later than two (2) months before the date proposed for its entry
                                    into force.
                                </p>
                                <br/>
                                <p>
                                    If the Clients fail to communicate an objection to Mondial Private IBU by the end of this two
                                    (2) month deadline, the former is deemed to have accepted said amendments. If the Clients
                                    reject the proposed amendment, they may freely terminate this Agreement, by written request,
                                    before said amendment comes into effect. Such termination shall come into force 1 month after
                                    the notification is sent to Mondial Private IBU as per sub clause 18.1.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            20. GENERAL INFORMATION
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    Should administrative formalities be necessary to comply with the terms and conditions of this Agreement,
                                    Mondial Private IBU and the Client shall provide each other with mutual assistance to regulate such formalities.
                                </p>
                                <br/>
                                <p>
                                    If one of the non-substantive stipulations of this Agreement is rendered null and void with regard to
                                    an effective rule of law, it will be deemed as not written, but will not invalidate the rest of this
                                    Agreement.
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            21. GOVERNING LAW AND JURISDICTION; REDRESS
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    This Agreement and all matters arising from or connected with this Agreement are governed by English law.
                                </p>
                                <br/>
                                <p>
                                    The courts of England have exclusive jurisdiction to settle any dispute arising from or connected with
                                    this Agreement and (including a dispute regarding their existence, validity or termination or relating to
                                    any non-contractual or other obligation arising out of or in connection with them) or the consequences
                                    of their nullity.
                                </p>
                                <br/>
                                <p>
                                    Clients may also be eligible for redress for any dispute and matter arising out of or in connection
                                    with this Agreement through the dispute resolution mechanism provided by the Financial Ombudsman Service
                                    (please see clause 22 below for further details).
                                </p>
                            </span>
                        </div>
                        <div className={s.policy_item}>
                            <div className={s.item_title}>
                            22. HELP, INFORMATION, AND COMPLAINTS
                            </div>
                            <span className={s.main_text}>
                                <p>
                                    If you have any queries or require a copy of this Agreement (or any document comprised in them), please
                                    contact Mondial Private IBU either by email or by phone calling Mondial Private IBU’s customer support
                                    phone number available during office hours.
                                </p>
                                <br/>
                                <p>
                                    Mondial Private IBU will not accept complaints pertaining to relations between Clients or between a
                                    Client and a third party. This article of the Services Framework Agreement only covers complaints
                                    pertaining to the lack of or poor execution of a Services carried out by Mondial Private IBU.
                                </p>
                                <br/>
                                <p>
                                    If you do experience a problem, we will always seek to resolve this as quickly and efficiently as
                                    possible. However, if you are unhappy and would like to make a complaint, please refer to our Complaints
                                    Policy which can be found on our website at&nbsp;
                                    <a href='https://mondialprivatebank.finance/' className={s.link} target={"_blank"}>Mondial Private Bank (IBU) Ltd. (mondialprivatebank.finance)</a>.
                                    The Complaints Policy contains details of our internal process for dealing with complaints promptly and
                                    fairly.
                                </p>
                                <br/>
                                <p>
                                    We will then investigate the matter and try to reach a satisfactory conclusion. Complaints may be
                                    recorded and monitored for our internal use; we may submit an anonymized summary of complaints made
                                    to us during a particular period of time to our regulator.
                                </p>
                                <br/>
                                <p>
                                    If you are not happy with our final response or, where you are eligible to refer your complaint to
                                    the Financial Ombudsman Service, if we have not concluded our investigation in accordance with the
                                    timescales required by law, you may be able to refer your complaint to The Financial Ombudsman
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default TermsAndConditions;

import React, {useState} from 'react';
import s from "./PhoneCodesInput.module.css";
import selectArrow from '../../../assets/newImg/select_arrow.svg'

const PhoneCodesInput = ({initCode, phonecodes, value, onChange, setCode, error, id, name, touched, selectable = true}) => {

    const [isDropdownVisible, setIsDropdownVisible] = useState(false)

    const onSelectPhonecode = async (phoneItem) => {
        setCode(phoneItem)
    }

    return (
        <div className={s.input_name_block}>

            <div className={s.phoneInputWrapper}>
                <div className={s.mySelect} onClick={() => {
                    if (selectable) {
                        setIsDropdownVisible((prevState) => !prevState);
                    }
                }}>
                    {initCode
                        ? <div className={s.selectedBlock}>

                            <img src={require(`../../../assets/newImg/flags/28/${initCode?.co}.png`)} alt=""
                                 className={s.flag}/>
                           {selectable && (
                                <img
                                    className={isDropdownVisible ? s.arrowReverse : s.arrow}
                                    width={9}
                                    height={5}
                                    src={selectArrow}
                                    alt=""
                                />
                            )}
                            <span className={s.selectedPhonecode}>+ {initCode.ph}</span>
                        </div>
                        : <div></div>}

                    <div className={isDropdownVisible ? s.optionsWrapper : s.none}>
                        {
                            phonecodes.map((phoneItem) => <div className={s.option}
                                                               key={phoneItem.co}
                                                               onClick={() => {
                                                                   onSelectPhonecode(phoneItem)
                                                               }}>
                                <div className={s.countryName}>
                                    <img src={require(`../../../assets/newImg/flags/28/${phoneItem.co}.png`)} alt=""
                                         className={s.flag}/>
                                    <div>
                                        {phoneItem.na}
                                    </div>
                                </div>
                                <div> +{phoneItem.ph}</div>
                            </div>)
                        }
                    </div>

                </div>
                <input className={error && touched ? `${s.my_input} ${s.error}` : `${s.my_input}`}
                       id={id}
                       name={name}
                       type="text"
                       value={value}
                       onChange={(e) => {
                           onChange(e)
                       }}
                />
                {error && <div className={s.error_message}></div>}
            </div>
        </div>
    );
};

export default PhoneCodesInput;

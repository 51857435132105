import React, {useEffect, useState} from 'react';
import s from './NavBar.module.css'
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import classnames from "classnames";
import closePopup from "../../../assets/newImg/mobile/close_popup.svg"
import logo from "../../../assets/newImg/publicPages/logo.svg"
import arrow_dropdown from "../../../assets/newImg/publicPages/arrow_dropdown.svg";
import { ABOUT, 
    PERSONAL, 
    CORPORATE, 
    INVESTMENTS, 
    CONTACT_US } from "../../../Constants/RoutesConstants";
import Icon from "@ant-design/icons";
import {ReactComponent as Bitcoin} from '../../../assets/newImg/publicPages/bitcoin-refresh.svg'
import {ReactComponent as CardCoin} from '../../../assets/newImg/publicPages/card-coin.svg'
import {ReactComponent as WalletMoney} from '../../../assets/newImg/publicPages/wallet-money.svg'
import {ReactComponent as Transfer} from '../../../assets/newImg/publicPages/transfer.svg'
import {ReactComponent as Medal} from '../../../assets/newImg/publicPages/medal-star.svg'
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import SideMenu from "../../SideMenu/SideMenu";

type propsType = {
    isActive: boolean
    setIsActive: (boolean: boolean) => void
}
const NavBar = ({isActive, setIsActive}: propsType) => {

    const [submenuIsOpen, setSubmenuIsOpen] = useState(false)
    const [IsPersonalSideMenu, setIsPersonalSideMenu] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (
            location.pathname.includes("login") ||
            location.pathname.includes("sign_up") ||
            location.pathname.includes("personal_account")
        ) {
            setIsPersonalSideMenu(true)
        } else {
            setIsPersonalSideMenu(false)
        }
    }, [location.pathname])

    const handleNavigate = () => {
        setIsActive(false)
        navigate("/login")
    }

    return (
        <>
            {
                IsPersonalSideMenu
                    ? <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
                        <SideMenu isSide setIsActive={setIsActive}/>
                    </div>
                    : <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
                        <div className={s.links_block}>
                            <NavLink className={location.pathname === '/' ? s.header_link_active : s.header_link} to='/'>
                                Home
                            </NavLink>
                            <NavLink className={location.pathname.includes("about") ? s.header_link_active : s.header_link} to={ABOUT}>
                                About
                            </NavLink>
                            <NavLink className={location.pathname.includes("personal") ? s.header_link_active : s.header_link} to={PERSONAL}>
                                Personal
                            </NavLink>
                            <NavLink className={location.pathname.includes("corporate") ? s.header_link_active : s.header_link} to={CORPORATE}>
                                Corporate
                            </NavLink>
                            <NavLink className={location.pathname.includes("investments") ? s.header_link_active : s.header_link} to={INVESTMENTS}>
                                Investments
                            </NavLink>
                            <NavLink className={location.pathname.includes("contact-us") ? s.header_link_active : s.header_link} to={CONTACT_US}>
                                Contact us
                            </NavLink>
                        </div>
                    </div>
            }
        </>
    );
};

export default NavBar;

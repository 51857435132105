import React, {useEffect, useState} from 'react';
import SignIn from "./SignIn/SignIn";
import {motion} from 'framer-motion';
import s from "./LoginizationSteps.module.css"
import AutenticationStep from "./AutenticationStep/AutenticationStep";
import company_name from "../../assets/newImg/company_name.svg";
import {NavLink} from "react-router-dom";
import logo from "../../assets/newImg/Logo.svg";
import quest from "../../assets/newImg/quest.svg";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import TwoFa from "../../components/Autentications/TwoFa/TwoFa";
import CustomModalContactContainer from "../../components/ui/CustomModalContactContainer/CustomModalContactContainer";
const LoginizationSteps = () => {

    useEffect(() => {
        localStorage.clear()
    }, [])


    const [current, setCurrent] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [additionalSettings, setAdditionalSettings] = useState({})
    const [dataForSend, setDataForSend] = useState({
        email: '',
        password: '',
        emailCode: '',
        twoFaCode: '',
        smsCode: ''
    })

    const steps = [
        {
            content: <SignIn setCurrent={setCurrent}
                             current={current}
                             setLoginData={setDataForSend}
                             dataForSend={dataForSend}
                             setAdditionalSettings={setAdditionalSettings}
            />
        },
        {
            content: <AutenticationStep setCurrent={setCurrent} dataForSend={dataForSend}
                                        setDataForSend={setDataForSend} additionalSettings={additionalSettings}/>
        },
        {
            content: <ForgotPassword setCurrent={setCurrent}/>
        },
        {
            content: <TwoFa
                setDataForSend={setDataForSend}
                dataForSend={dataForSend}
            />
        },
    ];

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModalContactContainer isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>

            <div className={s.wrapper}>
                <div className={s.login_wrapper_content_header}>
                    <div className={s.header_body}>
                    <div className={s.logo_block}>
                        <NavLink to={"/"}>
                            <img src={logo} alt=""/>
                        </NavLink>
                    </div>
                    <div className={s.quest_block}>
                        <img src={quest} alt="" onClick={() => setIsModalOpen(true)}/>
                    </div>
                    </div>
                </div>
                <div className={s.login_wrapper_content_body}>
                    {/* <div className={s.content_body}> */}
                        <div>{steps[current].content}</div>
                    {/* </div> */}
                </div>
            </div>
        </motion.div>
    );
};

export default LoginizationSteps;

import React from 'react';
import s from './MyBtn.module.css'
import classNames from "classnames";
import {useAppSelector} from "../../../hooks/redux";

type PropsType = {
  title: string,
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
  style?: React.CSSProperties;
  icon?: string;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  buttonType?: 'confirm' | 'success' | 'error';
  localDisable?: boolean,
  personalAccount?: boolean
}

const MyBtn: React.FC<PropsType> = ({type, title, icon, large, medium, small, buttonType, personalAccount, localDisable, ...props}) => {

    const {disabled} = useAppSelector(store => store.userReducer)

  return (
    <button className={classNames(s.my_btn, {
      [s.large]: large,
      [s.medium]: medium,
      [s.small]: small,
      [s.disabled]: (disabled ? disabled : localDisable),
      [s.confirm]: buttonType === 'confirm',
      [s.success]: buttonType === 'success',
      [s.error]: buttonType === 'error',
      [s.personalAccount]: personalAccount,
    })}
            disabled={disabled ? disabled : localDisable}
            {...props} type={type}>
      {
        icon && <img className={s.img} src={icon} alt=""/>
      }
      {title}
    </button>
  );
};

export default MyBtn;

import React, {useEffect, useState, useRef, RefObject } from 'react';
import s from './MobileHeader.module.css'
import logo from '../../assets/newImg/Logo.svg'
import logo_login from '../../assets/newImg/mobile/logo_login.svg'
import company_name from '../../assets/newImg/company_name.svg'
import burger from '../../assets/newImg/mobile/burger.svg'
import close from '../../assets/newImg/mobile/close.svg'
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import NavBar from "../Header/NavBar/NavBar";
import {useLocation, useNavigate} from "react-router-dom";
import bg from '../../assets/newImg/mobile/login_header_bg.svg'

const MobileHeader = () => {

    const [navIsShown, setNavIsShown] = useState(false)
    const navigate = useNavigate()
    const [isHeaderLogin, setIsHeaderLogin] = useState(true)
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === "/sign-in" || location.pathname === "/sign_up") {
            setIsHeaderLogin(true)
        } else {
            setIsHeaderLogin(false)
        }
    }, [location.pathname])

    // const handleShown = () => {
    //     setNavIsShown(prev => !prev);
    // }

    function useOutsideAlerter(ref: RefObject<HTMLElement>) {
        useEffect(() => {
          function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setNavIsShown(false)
            }
          }
          document.addEventListener("click", handleClickOutside);
          return () => {
            document.removeEventListener("click", handleClickOutside);
          };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <>
            { isHeaderLogin
                ? <div className={s.login_wrapper}>
                    {/* <img src={logo_login} className={s.logo_login} alt="" onClick={() => navigate("/")}/>
                    <img src={company_name} className={s.company_name} alt="" onClick={() => navigate("/")}/>
                    <img src={bg} className={s.login_header} alt=""/> */}
                </div>
                : <div className={s.wrapper} style={{
                                        minHeight: navIsShown ? '' : '88px', 
                                        borderRadius: navIsShown ? '44px' : '88px',
                                        background: navIsShown ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.90)',

                    }}>
                    <div className={s.container}>
                        <div className={s.logo_block}>
                            <img src={logo} alt="" onClick={() => navigate("/")}/>
                        </div>
                        <div>
                            <TransparentButton title={"Sign In"} onClick={() => navigate("/sign-in")}/>
                            {/* <TransparentButton title={"Sign In"} onClick={() => navigate("/")}/> */}
                        </div>
                        <div className={s.menu_block} ref={wrapperRef}>
                            <img src={navIsShown ? close : burger} className='close-open-item' alt="" onClick={() => setNavIsShown(prev => !prev)} />
                        </div>
                    </div>
                    <NavBar isActive={navIsShown} setIsActive={setNavIsShown}/>
                </div>
            }
        </>
    );
};

export default MobileHeader;

import React, {Dispatch, SetStateAction, useState} from 'react';
import {useAppDispatch} from "../../../hooks/redux";
import {Link} from "react-router-dom";
import {useFormik} from "formik";
import {RegistrationThunk} from "../../../store/reducers/ActionCreators";
import s from "./SignUp.module.css";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import PhoneCodesInput from "../../../components/ui/PhoneCodesInput/PhoneCodesInput";
import * as yup from 'yup';
import CustomCheckbox from "../../../components/ui/customCheckbox/CustomCheckbox";
import MyInput from "../../../components/ui/MyInput/MyInput";
import {motion} from 'framer-motion';
import attention from "../../../assets/newImg/attention.svg";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {phonecodes} from "../../../Constants/StaticData";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
}

const validationSchema = yup.object({
    phone: yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(6, "min 6")
        .max(12, "max 12")
        .required('Required field'),
    email: yup.string().required('Required field').email('invalid email address'),
    password: yup.string()
        .min(8, "min 8")
        .matches(/[1-9]+/, "Password must contain at least one number")
        .matches(/[a-z]+/, "Password must contain at least one lowercase")
        .matches(/[A-Z]+/, "Password must contain at least one uppercase")
        .required('Required field')
})

const SignUp = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const [code, setCode] = useState({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    })

    const [accountType, setAccountType] = useState("personal")
    const [error, setError] = useState("")

    const formikSignUp = useFormik({
        initialValues: {
            email: '',
            password: '',
            phone: '',
            type: ''
        },
        validationSchema,
        onSubmit: async values => {
            const tempData = {
                ...values,
                phone: `${code.ph} ${values.phone}`,
                type: accountType
            }
            await dispatch(RegistrationThunk(tempData))
                .then(() => {
                    props.setCurrent(1)
                }).catch((e) => {
                    if (e.response.data === "Phone already exist") {
                        setError("Phone number already exists")
                    }
                    if (e.response.data === "Email already exist") {
                        setError("Email already exists")
                    }
                }).finally(() => {
                    dispatch(setDisabled(false))
                })

        }
    })

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <div className={s.sign_in_wrapper}>
                <form onSubmit={formikSignUp.handleSubmit}>
                    <div className={s.sign_in_content}>
                        <div className={s.title}>
                            Open account
                        </div>
                        {
                            error !== '' &&
                            <div className={s.error_block}>
                                <img src={attention} alt=""/>
                                <div>
                                    {error}
                                </div>
                            </div>
                        }
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                Phone number
                            </div>
                            <PhoneCodesInput
                                initCode={code}
                                setCode={setCode}
                                id="phone"
                                name="phone"
                                value={formikSignUp.values.phone}
                                onChange={formikSignUp.handleChange}
                                error={formikSignUp.errors.phone}
                                touched={formikSignUp.touched}
                                phonecodes={phonecodes}
                            />
                            <div className={s.error_message}>
                                {formikSignUp.errors.phone}
                            </div>
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                Email address
                            </div>
                            <MyInput id="email"
                                     name="email"
                                     isError={formikSignUp.errors.email}
                                     value={formikSignUp.values.email}
                                     onChange={formikSignUp.handleChange}
                                     touched={formikSignUp.touched.email}
                            />
                            {formikSignUp.errors.email &&
                                <div className={s.error_message}>{formikSignUp.errors.email}</div>}
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                Create password
                            </div>
                            <MyInput id="password"
                                     name="password"
                                     password
                                     isError={formikSignUp.errors.password}
                                     value={formikSignUp.values.password}
                                     onChange={formikSignUp.handleChange}
                                     touched={formikSignUp.touched.password}
                            />
                            <div className={s.error_message}>
                                {formikSignUp.errors.password}
                            </div>
                        </div>
                        <div className={s.password_rules}>
                            The new password must contain a minimum of 8 characters with at least: 1 lowercase,
                            1 uppercase and 1 number.
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                account type
                            </div>
                            <div className={s.flex_label}>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={() => setAccountType("personal")}
                                        id="Personal"
                                        name="Personal"
                                        isChecked={accountType === "personal"}
                                    />
                                    <span className={s.type_of_account}>Personal</span>
                                </label>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={() => setAccountType("business")}
                                        id="Business"
                                        name="Business"
                                        isChecked={accountType === "business"}
                                    />
                                    <span className={s.type_of_account}>Business</span>
                                </label>
                            </div>
                        </div>
                        <div className={s.btn_block}>
                            <MyBtn
                                title={"Sign Up"}
                                type={"submit"}
                            />
                        </div>
                    </div>
                    <div className={s.dont_have_account}>
                        {/*<div>*/}
                        {/*    Do you have an account?*/}
                        {/*</div>*/}
                        {/*<Link className={s.link} to={"/login"}>Sign In</Link>*/}
                    </div>
                </form>
            </div>
        </motion.div>

    );
};

export default SignUp;

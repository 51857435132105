export const ABOUT = "/about"
export const PERSONAL = "/personal"
export const CORPORATE = "/corporate"
export const INVESTMENTS = "/investments"
export const CONTACT_US = "/contact-us"
export const BANKS_HISTORY = "/banks-history"
export const  PRIVACY_POLICY= "/privacy-policy"
export const KYC_AML_POLICY = "/kyc-aml-policy"
export const TERMS_AND_CONDITIONS = "/terms-and-conditions"

export const CONTACTS = "/contact-us"
export const WHY_US = "/why-us"
export const FEES = "/fees"
export const FAQ = "/faq"
export const AGENT_PROGRAM = "/agent_program"
export const LEGAL_AND_PRIVACY = "/legal_and_privacy"
export const VIRTUAL_CURRENCIES = "services/virtual_currencies"
export const FOREIGN_EXCHANGE = "services/foreign_exchange"
export const MONEY_ORDERS = "services/money_orders"
export const MONEY_TRANSFERS = "services/money_transfers"
export const PAYMENT_SERVICE_PROVIDER = "services/payment_service_provider"


import {
    validationSchema,
    validationSchemaAza,
    validationSchemaBank,
    validationSchemaEGPBank,
    validationSchemaGHSBank,
    validationSchemaKESBank,
    validationSchemaNigeriaUSDBank,
    validationSchemaUSDBank,
    validationSchemaXBank,
    validationSchemaZARBank,
} from "./ValidationSchemas";
import {validationSchemaACH, validationSchemaBACS} from "../../BankTransferFormPersonal/FormHelpers/ValidationSchemas";


export const getValidationSchema = (paymentTypeByRegion: string, paymentRegion: string) => {

    if (paymentRegion && paymentRegion !== "PAN AFRICA") {
        switch (paymentRegion) {
            case "ACH (only for USD)":
                return validationSchemaACH;
            case "BACS (only for GBP)":
                return validationSchemaBACS;
            default:
                return validationSchema;
        }
    }

    if (paymentRegion === "PAN AFRICA") {
        switch (paymentTypeByRegion) {
            case "GHS::Bank":
                return validationSchemaGHSBank;

            case "EUR::Bank":
            case "GBP::Bank":
                return validationSchemaBank;

            case "XOF::Bank":
            case "XAF::Bank":
                return validationSchemaXBank;

            case "ZAR::Bank":
                return validationSchemaZARBank;

            case "USD::Bank":
                return validationSchemaUSDBank;

            case "KES::Bank":
                return validationSchemaKESBank;

            case "EGP::Bank":
                return validationSchemaEGPBank;

            case "NigeriaUSD::Bank":
                return validationSchemaNigeriaUSDBank;

            default:
                return validationSchemaAza;
        }
    }

    return validationSchema;

}

import React from 'react';
import s from "../../../styles/styles.module.css";
import styles from "./Contacts.module.css";
import ContactUsForm from "../../../components/ContactUsForm/ContactUsForm";
import classnames from "classnames";
import { motion } from 'framer-motion';

const Contacts = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={classnames(s.wrapper, s.main_block)}>
                <div className={s.container}>
                    <div className={s.title}>
                        <div className={s.main_title}>
                            contact us
                        </div>
                        <div className={s.back_title}>
                            contact us
                        </div>
                    </div>
                    <div className={styles.contact_text}>
                        <div className={styles.contact_text_body}>
                            Your questions and comments are important to us. Whether you're considering Mondial Private Bank for your 
                            personal or business needs, or if you require support, our dedicated team is ready to assist you.
                        </div>
                    </div>
                    <div className={styles.contact_us_form_wrapper}>
                        <ContactUsForm />
                    </div>

                </div>
            </div>
        </motion.div>
    );
};

export default Contacts;

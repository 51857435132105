import React from 'react';
import styles from './Home.module.css'
import s from "../../../styles/styles.module.css"
import classnames from "classnames";
import ContactUsForm from '../../../components/ContactUsForm/ContactUsForm';
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import security from '../../../assets/newImg/publicPages/security-safe.svg';
import important from '../../../assets/newImg/publicPages/important.svg';
import global from '../../../assets/newImg/publicPages/global-search.svg';
import support from '../../../assets/newImg/publicPages/24-support.svg';
import star from '../../../assets/newImg/publicPages/star.png'
import img1 from '../../../assets/newImg/publicPages/image1.svg'
import img2 from '../../../assets/newImg/publicPages/image2.svg'
import img3 from '../../../assets/newImg/publicPages/image3.svg'
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {

    const navigate = useNavigate();

    const benefits = [
        {
            icon: security,
            title: 'Security and Privacy:',
            subtitle: 'Our offshore banking services provide maximum security and privacy for your funds.',
        },
        {
            icon: important,
            title: 'Personalized approach:',
            subtitle: 'Every client is important to us. Your personal account manager is always ready to help and offer the best solutions.',
        },
        {
            icon: global,
            title: 'Global expertise:',
            subtitle: 'Our specialists constantly monitor global trends in finance, allowing us to offer you relevant and innovative solutions.',
        },
        {
            icon: support,
            title: 'Ease of use: ',
            subtitle: 'Our digital platforms provide quick and easy access to your accounts 24/7, wherever you are.',
        },
    ]

    const reviews = [
        {
            id: 'rev1',
            image: img1,
            review: "My partnership with Mondial is built on trust, transparency and a shared vision of success. They are passionate about redefining the " +
            "finance industry. Their team of seasoned professionals combined with a dedication to personalized  service, sets them apart. I can't express enough " +
            "gratitude for the outstanding service. From the very first interaction, I knew I was in capable hands.",
        },
        {
            id: 'rev2',
            image: img2,
            review: 'I always feel safe and confident with Mondial. Their transparent approach to offshore banking and commitment to new standards in the industry make ' +
            'them truly outstanding. Their team of professionals is always ready to offer customized solutions and their experience and knowledge of offshore finance ' +
            'inspires confidence. Thank you Mondial for their consistently high level of service',
        },
        {
            id: 'rev3',
            image: img3,
            review: 'When it comes to offshore banking services, Mondial is a benchmark of quality. Their customer care, commitment to transparency and long-term ' +
            'mutual benefit make them a leader in their segment. From the very first contact I realized that I had found the perfect partner in Mondial. Thank you ' +
            'very much for your professionalism and dedication!',
        },
    ]

    const options = {
        loop: false,
        margin:10,
        nav: false,
        dots: false,
        responsive:{
            0:{
                items: 1.56,
                loop: true,
            },
            636: {
                items: 2.56,
                loop: true,
            },
            1055: {
                items: 3
            }
        }
    }

    const stars = () => {
        const starElements = [];
        for (let i = 0; i < 5; i++) {
            starElements.push(
              <div key={i} className={styles.star}>
                <img src={star} alt='star-rating' />
              </div>
            );
        }

        return starElements;
    }

    const handleButtonClick = () => {
        const contactsElement = document.getElementById("contacts");
        if (contactsElement) {
          const offset = 150; 
          const position = contactsElement.offsetTop - offset;
          window.scrollTo({ top: position, behavior: "smooth" });
        }
      };

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
          <div className={s.wrapper}>
            <div className={styles.start_block}>
              <div className={styles.gradient}>
                <div className={s.container}>
                    <div className={styles.container}>
                        <div className={styles.title_block}>
                            <h1 className={styles.title}>
                                Welcome to<br className={styles.mobile_break}/> 
                                <span className={styles.title_color}>
                                    Mondial Offshore Bank!
                                </span>
                            </h1>
                        </div>
                        <span className={styles.description}>
                            Your trusted partner in the world of offshore banking. Looking after your finances overseas, 
                            we offer top class service and unrivaled expertise.
                        </span>
                        <div className={styles.btn_block}>
                            <MyBtn title={"Get Started"} onClick={handleButtonClick} />
                        </div>
                    </div>

                    <div className={classnames(styles.block, styles.flex)}>
                        <div className={styles.benefits_text}>
                            <div className={styles.description_wrapper}>
                                <span className={styles.text_align}>
                                    We at Mondial Offshore Bank provide a full range of banking services, from personal accounts and investment management to 
                                    corporate finance and payments. 
                                </span>
                                <span className={styles.text_align}>
                                    With our in-depth understanding of offshore banking and the international business climate, we provide you with the tools 
                                    that enable you to manage your finances effectively.
                                </span>
                            </div>
                        </div>
                        <div className={styles.benefits}>
                            <div className={classnames(styles.benefits_title, s.title)}>
                                <div className={classnames(styles.benefits_main_title, s.main_title)}>
                                    Benefits
                                </div>
                                <div className={classnames(styles.benefits_back_title, s.back_title)}>
                                    Benefits
                                </div>
                            </div>
                            <div className={styles.benefit_items}>
                                {benefits.map(item => {
                                    return <div key={item.title} className={styles.benefit_item}>
                                                <div className={styles.benefit_item_content}>
                                                    <div className={styles.benefit_item_info}>
                                                        <div className={styles.benefit_item_icon}>
                                                            <img src={item.icon} />
                                                        </div>
                                                        <div className={styles.benefit_item_text}>
                                                            <div className={styles.benefit_item_title}>
                                                                {item.title}
                                                            </div>
                                                            <div className={styles.benefit_item_subtitle}>
                                                                {item.subtitle}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div className={classnames(s.container, styles.reviews_block)}>
                <div className={styles.reviews_section}>
                    <div className={classnames(styles.reviews_title, s.title)}>
                        <div className={classnames(s.reviews_main_title, s.main_title)}>
                            Reviews
                        </div>
                        <div className={classnames(styles.reviews_back_title, s.back_title)}>
                            Reviews
                        </div>
                    </div>
                    <div className={styles.reviews_body}>
                        <div className={styles.reviews_container}>
                            <OwlCarousel className="slider-items owl-carousel" {...options} >
                                {reviews.map(item => {
                                    return <div className="item" key={item.id} style={{height: '100%'}}>
                                                <div className={styles.review_item_body}>
                                                    <div className={styles.review_item}>
                                                        <div className={styles.review_image}>
                                                            <img src={item.image} alt='reviews'/>
                                                        </div>
                                                        <div className={styles.review_rating}>
                                                            { stars() }
                                                        </div>
                                                        <div className={styles.review_text}>
                                                            { item.review }
                                                        </div>
                                                    </div>
                                                </div>
                                           </div>
                                })}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.container} id='contacts'>
                <div className={styles.contact_form_wrapper}>
                    <div className={styles.contact_form_content}>
                      <div className={styles.contact_form_section}>
                        <div className={classnames(styles.contact_title, s.title)}>
                            <div className={classnames(styles.reviews_main_title, s.main_title)}>
                                Make a Request
                            </div>
                            <div className={classnames(styles.contact_back_title, s.back_title)}>
                                Make a Request
                            </div>
                        </div>
                      </div>
                        <ContactUsForm />
                    </div>
                </div>
            </div>
        </div>
        </motion.div>
    );
};

export default Home;

import * as yup from "yup";

export const validationSchema = yup.object().shape({
        payerAccount: yup.string().required('Required field'),
        paymentRegions: yup.string().required('Required field'),
        recipientsAccountNumber: yup.string().required('Required field').max(40, 'max 40 symbols'),
        name: yup.string().required('Required field').max(90, 'max 90 symbols'),
        recipientsBank: yup.string().required('Required field').max(60, 'max 60 symbols'),
        recipientsBankCountry: yup.string().required('Required field'),
        recipientsBankAddress: yup.string().required('Required field').max(100, 'max 100 symbols'),
        reference: yup.string().test({message: 'One of the fields is required',
            test: function (value) {
                const { purpose } = this.parent;
                if (!purpose) return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
        purpose: yup.string().test({
            message: 'One of the fields is required',
            test: function (value) {
                const {reference} = this.parent;
                if (!reference) return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
        recipientsSwiftBic: yup.string().required('Required field').max(12, 'max 12 symbols'),
        recipientsAddress: yup.string().required('Required field').max(100, 'max 100 symbols'),
        email: yup.string().email("invalid format"),
        amount: yup.string()
            .matches(/^[0-9/,]+$/, "Must be only digits")
            .required('Required field')
            .max(10, 'max 10 digits')
            .test(
                'no-leading-zero',
                'invalid format',
                (value, context) => {

                    const tempArr = context.originalValue.split('')

                    const onlyZero = tempArr.every((item: string) => {
                        if (item === '0') {
                            return true
                        }
                    })

                    if (context.originalValue && onlyZero) {
                        return false
                    } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                        return false
                    }
                    return true
                }
            ),
        templateName: yup.string().max(100, 'max 100 symbols'),
    },
    [ [ 'reference', 'purpose' ] ]);

// Common fields that appear in multiple schemas
const commonFields = {
    payerAccount: yup.string().required('Required field'),
    paymentRegions: yup.string().required('Required field'),
    name: yup.string().required('Required field'),
    reference: yup.string().test({message: 'One of the fields is required',
        test: function (value) {
            const { purpose } = this.parent;
            if (!purpose) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    purpose: yup.string().test({
        message: 'One of the fields is required',
        test: function (value) {
            const {reference} = this.parent;
            if (!reference) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    email: yup.string().email("invalid format"),
    paymentDate: yup.string().required('Required field'),
    amount: yup.string()
        .matches(/^[0-9/,]+$/, "Must be only digits")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    if (item === '0') {
                        return true
                    }
                })

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols'),
};

export const validationSchemaAza  = yup.object({
    ...commonFields,
});

export const validationSchemaGHSBank = yup.object({
    ...commonFields,
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
});

export const validationSchemaBank = yup.object({
    ...commonFields,
    recipientsIban: yup.string().required('Required field').max(90, 'max 90 symbols'),
    recipientsBank: yup.string().required('Required field').max(90, 'max 90 symbols'),
    recipientsSwiftBic: yup.string().required('Required field').max(90, 'max 90 symbols')
});

export const validationSchemaXBank = yup.object({
    ...commonFields,
    recipientsIban: yup.string().required('Required field').max(90, 'max 90 symbols'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaZARBank = yup.object({
    ...commonFields,
    street: yup.string().max(90, 'max 90 symbols'),
    postalCode: yup.string().max(90, 'max 90 symbols'),
    city: yup.string().max(90, 'max 90 symbols'),
    email: yup.string().required('Required field').email("invalid format"),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    recipientsAccountNumber: yup.string().required('Required field'),
    phoneNumber: yup.string().required('Required field'),
    transferReason: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
    legalEntityType: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
    firstName: yup.string().max(90, 'max 40 symbols'),
    lastName: yup.string().max(90, 'max 40 symbols'),
    registrationNumber: yup.string().max(90, 'max 90 symbols'),
    natureOfBusiness: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
});

export const validationSchemaUSDBank = yup.object({
    ...commonFields,
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankAccountType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    recipientsBank: yup.string().required('Required field').max(90, 'max 90 symbols'),
    routingNumber: yup.string().required('Required field').matches(/^\d{9}$/, 'Must be 9 digits'),
    recipientsSwiftBic: yup.string().required('Required field').max(90, 'max 90 symbols'),

});


export const validationSchemaKESBank = yup.object({
    ...commonFields,
    street: yup.string().required('Required field').max(90, 'max 90 symbols'),
    city: yup.string().max(90, 'max 90 symbols'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field'),
    relationshipToSender: yup.string().max(90, 'max 40 symbols'),
});

export const validationSchemaEGPBank = yup.object({
    ...commonFields,
    street: yup.string().required('Required field').max(90, 'max 90 symbols'),
    phoneNumber: yup.string().required('Required field'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaNigeriaUSDBank = yup.object({
    ...commonFields,
    phoneNumber: yup.string().required('Required field'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field'),

});
import {useNavigate} from "react-router-dom";
import BankingTemplate from '../../../components/BankingTemplate/BankingTemplate';
import personal from '../../../assets/newImg/publicPages/personal.jpeg';
import card from '../../../assets/newImg/publicPages/card.svg';
import monitor from '../../../assets/newImg/publicPages/monitor-mobbile.svg';
import like from '../../../assets/newImg/publicPages/like.svg';

const Personal = () => {

    const navigate = useNavigate();

    const text = "At Mondial Private Bank, we understand that personal banking should be just that – personal. Our comprehensive " +
                    "suite of personal banking services is designed to fit your unique needs and lifestyle. From current and savings " +
                    "accounts in multiple currencies and personal wealth management, we provide you with the tools to manage your " +
                    "finances effectively, wherever life takes you.";

    const services = [
        {
            icon: card,
            description: 'Multi-currency Accounts',
        },
        {
            icon: monitor,
            description: 'Online and Mobile Banking',
        },
        {
            icon: like,
            description: 'Wealth Management and Advisory Services',
        },
    ]

    return (
        <BankingTemplate title='Personal Banking' 
                         backColor='#816838' 
                         textColor='#FFF'
                         text={text}
                         image={personal}
                        //  subTitle='Discover how our corporate banking expertise can support your business ambitions' 
                         servicesInfo={services}
        />
    );
};

export default Personal;
